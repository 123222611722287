import stepReducer from './step.reducer'
import loaderReducer from './loader.reducer'
import formSubmittingReducer from './formSubmitting.reducer'
import carReducer from './car.reducer'
import globalReducer from './global.reducer'
import carStep from './carStep.reducer'
import cantonListReducer from './cantonList.reducer'
import laboursReducer from './labours.reducer'
import userReducer from './user.reducer'
import garageReducer from './garage.reducer'
import languageReducer from './language.settings.reducer'
import recipientsReducer from './recipients.settings.reducer'
import requestReducer from './request.reducer'
import requestSettingsReducer from './requestSettings.reducer'
import countryReducer from './country.reducer'
import officialServicesReducer from './officialServices.reducer'
import laboursPredefinedReducer from './laboursPredefined.reducer'
import requestsList from './requestsList.reducer'
import recommendedOilReducer from './recommendedOil.reducer'
import tiresReducer from './tires.reducer'
import tireLaboursReducer from './tireLabours.reducer'
import tireLaboursPredefinedReducer from './tireLaboursPredefined.reducer'
import tiresNewReducer from "./tiresNew.reducer";
import tiresProcessReducer from "./tiresProcess.reducer";

export default {
    car: carReducer,
    step: stepReducer,
    loader: loaderReducer,
    formSubmitting: formSubmittingReducer,
    global: globalReducer,
    carStep: carStep,
    cantonList: cantonListReducer,
    labours: laboursReducer,
    tireLabours: tireLaboursReducer,
    tiresNew: tiresNewReducer,
    user: userReducer,
    garage: garageReducer,
    languages: languageReducer,
    recipients: recipientsReducer,
    requestSettings: requestSettingsReducer,
    request: requestReducer,
    country: countryReducer,
    officialServices: officialServicesReducer,
    laboursPredefined: laboursPredefinedReducer,
    requestsList: requestsList,
    recommendedOil: recommendedOilReducer,
    tires: tiresReducer,
    tiresProcess: tiresProcessReducer,
    tireLaboursPredefined: tireLaboursPredefinedReducer,
}
