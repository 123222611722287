import React, {Component} from 'react';
import {connect} from 'react-redux';
import {translate} from 'react-i18next';
import Header from "components/layout/Header/Header";
import ChangePassword from "./ChangePassword";
import ChangeUserDetails from "./ChangeUserDetails/ChangeUserDetails";
import {settingsFetch} from "../../store/actions/settings.actions";
import {USER_ROLE_MECHANIC} from "../../store/consts/user.constants";
import Language from "../SettingsContainer/Language";
import {LOADER_SETTINGS_PAGE} from "../../store/consts/loader.constants";
import ListLoader from "../../components/shared/ListLoader/ListLoader";
import GarageDetailsForm from "../SettingsContainer/GarageDetails";

class UserProfileContainer extends Component {
    componentDidMount(error, errorInfo) {
        const {dispatch} = this.props;

        dispatch(settingsFetch());
    }

    render() {
        const {t, title, user, loader} = this.props;

        return (
            <React.Fragment>

                <Header title={t(title)}/>

                {!loader[LOADER_SETTINGS_PAGE] ? <ListLoader/> :
                    <div className="container">
                        <h2 className="licence-plate-form-group">{t('pages.settings.title')}</h2>
                        <div className="row">
                            <div className="col-sm-6">
                                <ChangeUserDetails/>
                                <GarageDetailsForm/>
                            </div>
                            <div className="col-sm-6">
                                <ChangePassword/>
                                {user.type !== USER_ROLE_MECHANIC &&
                                <Language/>
                                }
                            </div>
                        </div>
                    </div>
                }

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        loader: state.loader,
    };
};

export default connect(mapStateToProps)(translate('translations')(UserProfileContainer));
