import {API_SUCCESS, apiRequest} from 'store/actions/api.actions'
import {FETCH_REQUESTS, setRequests, setRequestsLoadingStatus} from 'store/actions/requestsList.actions'

export const GET_REQUESTS = 'garage/requests/get-list'

export const requestsMiddleware = ({ dispatch }) => next => {
    return action => {
        next(action)

        switch (action.type) {

            case FETCH_REQUESTS:
                dispatch(
                    apiRequest({
                        method: 'GET',
                        url: GET_REQUESTS + '?page=' + action.page + '&status=' + action.status,
                        signature: FETCH_REQUESTS,
                    })
                )
                break

            case `${FETCH_REQUESTS} ${API_SUCCESS}`:
                dispatch(setRequests(action.payload.data))
                dispatch(setRequestsLoadingStatus(true))
                break

            default:
                break
        }
    }
}
