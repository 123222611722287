import axios from "../../app/config/axios";
import {
    OFFICIAL_SERVICE_NEXT,
    OFFICIAL_SERVICE_PREV,
    OFFICIAL_SERVICE_SELECTED
} from "../consts/officialServices.constants";
import {LABOUR_TYPE_OFFICIAL} from "../consts/labours/labours.constants";
import {toBool} from "../../utils/common";

export const OFFICIAL_SERVICES = '[OFFICIAL_SERVICES]'

export const OFFICIAL_SERVICES_SET = `${OFFICIAL_SERVICES} SET`
export const OFFICIAL_SERVICES_UPDATE = `${OFFICIAL_SERVICES} UPDATE`
export const OFFICIAL_SERVICES_RESET = `${OFFICIAL_SERVICES} RESET`

const API_OFFICIAL_SERVICE = 'garage/form/official-service'

export const officialServicesSet = (recommended, main, additional, mileage, exist) => ({
    type: OFFICIAL_SERVICES_SET,
    payload: {
        recommended,
        main,
        additional,
        mileage,
        exist,
    },
})

export const officialServicesUpdate = (labour, type) => ({
    type: OFFICIAL_SERVICES_UPDATE,
    payload: {
        labour,
        type
    },
})

export const officialServicesFetch = (mileage) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            axios.post(API_OFFICIAL_SERVICE, { mileage: mileage })
                .then(response => {

                    response.data.recommended.next = response.data.recommended.next.map(labour => {
                        return {
                            ...labour,
                            checked: response.data.recommended.selected === OFFICIAL_SERVICE_NEXT,
                            type: LABOUR_TYPE_OFFICIAL,
                        }
                    });

                    response.data.recommended.prev = response.data.recommended.prev.map(labour => {
                        return {
                            ...labour,
                            checked: response.data.recommended.selected === OFFICIAL_SERVICE_PREV,
                            type: LABOUR_TYPE_OFFICIAL,
                        }
                    });

                    let selectedRecommended = response.data.recommended[OFFICIAL_SERVICE_SELECTED[response.data.recommended.selected]]

                    response.data.main = response.data.main.map(labour => {
                        return {...labour,
                            checked: toBool(selectedRecommended.find(l => l.itemMpId === labour.itemMpId)),
                            inOfficial: toBool(selectedRecommended.find(l => l.itemMpId === labour.itemMpId)),
                            type: LABOUR_TYPE_OFFICIAL,
                        }
                    });

                    response.data.additional = response.data.additional.map(labour => {
                        return {...labour,
                            checked: toBool(selectedRecommended.find(l => l.itemMpId === labour.itemMpId)),
                            inOfficial: toBool(selectedRecommended.find(l => l.itemMpId === labour.itemMpId)),
                            type: LABOUR_TYPE_OFFICIAL,
                        }
                    });

                    dispatch(officialServicesSet(response.data.recommended, response.data.main, response.data.additional, response.data.mileage, response.data.exist));

                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    };
}

export const officialServicesReset = () => ({
    type: OFFICIAL_SERVICES_RESET,
})
