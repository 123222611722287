import React, {Component} from 'react';
import {translate} from "react-i18next";
import classNames from "classnames";
import Loader, {LOADER_LOADING} from "../Loader/Loader";

class ListLoader extends Component {
    render() {
        const {t, text, stat, removeText} = this.props;

        return (
            <React.Fragment>
                <div className={classNames('centered-icon', {
                    'centered-icon--static': stat
                })}>
                    <div><Loader type={LOADER_LOADING} isLoading={true}/> {removeText !== true && (text ? text : t('global.loading'))}</div>
                </div>
            </React.Fragment>
        );
    }
}

export default translate('translations')(ListLoader);
