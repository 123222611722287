import axios from 'axios'
import { STORAGE_OFFER_TOKEN, STORAGE_USER_TOKEN } from 'app/consts/storage.consts'
import persistSingleUrls from 'routes/persistSingleUrls'
import { language } from 'utils/language'
import { setCountryCode } from '../../utils/common'

const instance = axios.create({
    baseURL: setCountryCode(process.env.REACT_APP_BASE_URL_API),
    persistSingleUrls: [],
})

instance.interceptors.request.use(config => {
    const OfferToken = sessionStorage.getItem(STORAGE_OFFER_TOKEN)

    if (OfferToken) {
        config.headers.common.OfferToken = OfferToken
    }
    const UserToken = localStorage.getItem(STORAGE_USER_TOKEN)
    if (UserToken) {
        config.headers.common.Authorization = `Bearer ${UserToken}`
    }

    config.headers.common.Language = language()

    if (persistSingleUrls.indexOf(config.url) !== -1) {
        config.cancelTokenSource = axios.CancelToken.source()
        config.cancelToken = config.cancelTokenSource.token

        config.persistSingleUrls.map((data, i) => {
            if (data.url === config.url) {
                data.cancel()
                config.persistSingleUrls.splice(i, 1)
            }

            return data
        })

        config.persistSingleUrls.push({
            cancel: config.cancelTokenSource.cancel,
            url: config.url,
        })
    }

    return config
})

export default instance
