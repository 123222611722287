import step from './step'
import carStep from './carStep'
import formSubmitting from './formSubmitting'
import loader from './loader'
import car from './car'
import global from './global'
// import notifications from './notifications'

export default {
    step,
    carStep,
    formSubmitting,
    loader,
    car,
    global,
    // notifications,
}
