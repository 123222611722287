const pages = {
    form_steps: {
        car: '/',
        official_service: '/form/official-services',
        tires: '/form/tires',
        labours: '/form/labours',
        overview: '/form/overview',
        review: '/form/review',
    },

    settings: '/settings',
    user_profile: '/user-profile',

    login: '/login',
    pickGarage: '/pick-garage',
    forgetPassword: '/reset',
    forgetPasswordNew: '/reset-new',

    requests: '/requests',
    request_details: '/request/',
}

export default pages
