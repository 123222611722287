import axios from "../../app/config/axios";

export const TIRE_LABOURS_PREDEFINED = '[Tire Labours Predefined]'

export const TIRE_LABOURS_PREDEFINED_SET = `${TIRE_LABOURS_PREDEFINED} SET`;
export const TIRE_LABOURS_PREDEFINED_RIM_SIZE_UPDATE = `${TIRE_LABOURS_PREDEFINED} RIM SIZE UPDATE`;
export const TIRE_LABOURS_PREDEFINED_SELECT = `${TIRE_LABOURS_PREDEFINED} SELECT`;
export const TIRE_LABOURS_PREDEFINED_RESET = `${TIRE_LABOURS_PREDEFINED} RESET`;

const TIRE_LABOURS_PREDEFINED_GET_URL = 'garage/form/tire-labours-predefined'

export const tireLaboursPredefinedSet = (labours) => ({
    type: TIRE_LABOURS_PREDEFINED_SET,
    payload: {
        labours
    }
});

export const tireLaboursPredefinedSelect = (labourGroupId, checked) => ({
    type: TIRE_LABOURS_PREDEFINED_SELECT,
    payload: {
        labourGroupId, checked
    }
});

export const tireLaboursPredefinedRimSizeUpdate = (labourGroupId, labourId, axels) => ({
    type: TIRE_LABOURS_PREDEFINED_RIM_SIZE_UPDATE,
    payload: {
        labourGroupId, labourId, axels
    }
});

export const tireLaboursPredefinedReset = () => ({
    type: TIRE_LABOURS_PREDEFINED_RESET,
});

export const tireLaboursPredefinedGet = () => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            axios.post(TIRE_LABOURS_PREDEFINED_GET_URL)
                .then(response => {

                    dispatch(tireLaboursPredefinedSet(response.data.data))

                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}
