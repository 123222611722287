import React, {Component} from 'react'
import {translate} from 'react-i18next'
import Info from "../Info/Info";
import InputCheckbox from "components/shared/InputCheckbox";
import {sumBy} from "lodash";
import {toFixed} from "../../../../utils/common";

class ItemAdditional extends Component {

    parts = (service) => {
        if (service.works.find(work => work.name === service.name)) {
            return service.works.find(work => work.name === service.name).parts.filter(part => part.selected).map(part => part.name);
        }

        return [];
    }

    render() {
        const {t, service, change} = this.props;

        return (
            <>
                <div className="official-services-item">
                    <div className="official-services-item__check">
                        <InputCheckbox
                            id={`official-services-${service.itemMpId}`}
                            name={`official-services-${service.itemMpId}`}
                            value={service.itemMpId}
                            checked={service.checked}
                            onChange={change}
                            disabled={service.inOfficial}
                            label={
                                <div className="official-services-item__name">
                                    <div className="official-services-item__name__left">
                                        <span className="official-services-item__name__left__name">{service.name}</span>
                                        <span
                                            className="official-services-item__name__left__duration"> - {toFixed(sumBy(service.works.filter(work => work.selected), work => work.duration), 2)}h</span>
                                    </div>
                                    <div className="float-right">
                                        {service.inOfficial &&
                                        <span className="service-in-official">
                                                <i className="icon-success"/>
                                                <span>{t('pages.form_steps.official_service.included_in_official')}</span>
                                            </span>
                                        }
                                    </div>
                                    {service.addText &&
                                    <div className="official-services-item__name__add-text">
                                        {service.addText}
                                        {service.description &&
                                            <div className="official-services-item__name__description">{service.description}</div>
                                        }
                                    </div>
                                    }
                                </div>
                            }
                        />
                    </div>
                </div>
            </>
        )
    }
}

export default translate('translations')(ItemAdditional);
