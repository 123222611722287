import laboursPredefined from 'store/models/laboursPredefined'
import {
    LABOURS_PREDEFINED_APPEND,
    LABOURS_PREDEFINED_REMOVE,
    LABOURS_PREDEFINED_SET, LABOURS_PREDEFINED_UPDATE
} from "../actions/laboursPredefined.actions";

export default function(state = laboursPredefined, action) {
    switch (action.type) {
        case LABOURS_PREDEFINED_SET:
            return [...action.payload.list]

        case LABOURS_PREDEFINED_UPDATE:
            return state.map(l => {
                if(l.itemMpId === action.payload.labour.itemMpId && l.korId === action.payload.labour.korId){
                    return action.payload.labour;
                }

                return l;
            })

        case LABOURS_PREDEFINED_APPEND:
            return [...state, action.payload.labour]

        case LABOURS_PREDEFINED_REMOVE:
            return state.filter(l => `${l.itemMpId}${l.korId}` !== `${action.payload.labour.itemMpId}${action.payload.labour.korId}`)

        default:
            return state
    }
}
