import apiMiddleware from './core/api.middleware'
import { stepMiddleware } from './feature/step.middleware'
import { carMiddleware } from './feature/car.middleware'
import { carStepMiddleware } from './feature/carStep.middleware'
import { garageMiddleware } from 'store/middleware/feature/garage.middleware'
import { authMiddleware } from 'store/middleware/feature/auth.middleware'
import { userMiddleware } from 'store/middleware/feature/user.middleware'
import { languageSettingMiddleware } from 'store/middleware/feature/language.setting.middleware'
import { changePasswordSettingMiddleware } from 'store/middleware/feature/change-password.setting.middleware'
import { changeUserDetailsSettingMiddleware } from 'store/middleware/feature/change-user-details.setting.middleware'
import { recipientsSettingMiddleware } from 'store/middleware/feature/recipients.setting.middleware'
import { settingMiddleware } from 'store/middleware/feature/setting.middleware'
import { changeGarageDetailsSettingMiddleware } from './feature/change-garage-details.setting.middleware'
import { requestsMiddleware } from './feature/requests.middleware'
import { tiresMiddleware } from './feature/tires.middleware'

const app = [
    stepMiddleware,
    carMiddleware,
    carStepMiddleware,
    garageMiddleware,
    authMiddleware,
    userMiddleware,
    languageSettingMiddleware,
    changePasswordSettingMiddleware,
    changeUserDetailsSettingMiddleware,
    recipientsSettingMiddleware,
    settingMiddleware,
    changeGarageDetailsSettingMiddleware,
    requestsMiddleware,
    tiresMiddleware,
]

const core = [apiMiddleware]

export default {
    core,
    feature: app,
}
