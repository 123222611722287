import React, { Component } from 'react';
import './Button.scss';
import Loader, {LOADER_BUTTON} from "../Loader/Loader";
import {Link} from "react-router-dom";
import DropDown from "../DropDown/DropDown";
import onClickOutside from 'react-onclickoutside';
import classNames from 'classnames';

class Button extends Component {

    state = {
        showDropDown: false,
    }

    handleClickOutside = () => {
        this.setState({
            showDropDown: false,
        });
    };

    render() {
        const { children, size, type, icon, extraClass, onClick, disabled, id, button, loading, to, ddList, onDDSelect} = this.props;
        const { showDropDown } = this.state;

        if(to){
            return (
                <Link
                    to={to}
                    id={id}
                    className={`btn btn-${size} btn-${type} ${extraClass}`}
                >
                    {icon && (
                        <div className="btn__icon">
                            <span className={icon} />
                        </div>
                    )}
                    {children}
                </Link>
            );
        }

        if(button === false){
            return (
                <div
                    id={id}
                    className={`btn btn-${size} btn-${type} ${extraClass}`}
                    onClick={e => {
                        if (onClick) onClick(e);
                    }}
                >
                    {icon && (
                        <div className="btn__icon">
                            <span className={icon} />
                        </div>
                    )}
                    {children}
                </div>
            );
        }

        return (
            <div className={classNames('button-container', {
                'button-container--block': size === 'lg',
            })}>
                <button
                    id={id}
                    type="submit"
                    className={`btn btn-${size} btn-${type} ${extraClass}`}
                    onClick={e => {
                        if (onClick) onClick(e);
                        else if (ddList) this.setState({showDropDown: true});
                    }}
                    disabled={disabled}
                >
                    {(size === 'sm' && loading) ?
                        <Loader isLoading={loading} type={LOADER_BUTTON}/>
                        :
                        <div>
                            {children}

                            {icon && (
                                <i className={`${icon} btn__icon`} />
                            )}

                            <Loader isLoading={loading} type={LOADER_BUTTON}/>
                        </div>
                    }
                </button>

                {showDropDown &&
                    <DropDown
                        list={ddList}
                        onSelect={(option) => {
                            onDDSelect(option)
                            this.setState({showDropDown: false})
                        }}
                    />
                }
            </div>
        );
    }
}

export default onClickOutside(Button);
