import React from 'react';
import {translate} from "react-i18next";
import * as ReactDOM from "react-dom";
import classnames from "classnames";

export const Popup = props => {
    const {children, title, subtitle, visible, hideCancel, contentScrollable, responsive} = props;

    let onClose = () => {
        props.onClose();
    };

    if (!visible)
        return null;

    let renderPopUp = () => (
        <div className="popup-overlay add-new-price show">
            <div className={classnames("popup", {
                'popup__responsive': responsive
            })}>
                <div className="popup__header">
                    <span className="popup__header__title">{title}</span>
                    {!hideCancel &&
                        <span className="popup__header__close" onClick={onClose}><i className="icon-close"/></span>
                    }
                    {subtitle &&
                        <div className="popup__header__subtitle">{subtitle}</div>
                    }
                </div>
                <div className={`popup__content ${contentScrollable ? 'popup__content--overflow-auto' : ''}`}>
                    {children}
                </div>
            </div>
        </div>
    );

    return ReactDOM.createPortal(renderPopUp(), document.getElementById('modal-root'));
};

export default translate('translations')(Popup);
