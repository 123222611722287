import formSubmitting from 'store/models/formSubmitting'
import {
    FORM_SUBMITTING_VEHICLE,
    FORM_SUBMITTING_LOGIN,
} from 'store/actions/formSubmitting.actions'
import {
    FORM_SUBMITTING_SETTINGS,
} from '../actions/formSubmitting.actions'

export default function(state = formSubmitting, action) {
    switch (action.type) {
        case FORM_SUBMITTING_VEHICLE:
            return {
                ...state,
                vehicle: action.payload.submitted,
            }

        case FORM_SUBMITTING_LOGIN:
            return {
                ...state,
                login: action.payload.submitted,
            }

        case FORM_SUBMITTING_SETTINGS:
            return {
                ...state,
                settings: action.payload.submitted,
            }

        default:
            return state
    }
}
