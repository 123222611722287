import {API_ERROR, API_SUCCESS, apiRequest} from 'store/actions/api.actions'
import {USER_FETCH} from 'store/actions/user.actions'
import {formSubmittingLogin} from 'store/actions/formSubmitting.actions'
import {userSet} from 'store/actions/user.actions'
import {setGarage} from 'store/actions/garage.actions'
import {setLoader} from 'store/actions/loader.actions'
import {LOADER_INIT} from 'store/consts/loader.constants'
import {countrySet} from '../../actions/country.actions'
import insertHotjar from "../../../utils/insertHotjar";

const USER_FETCH_URL = 'garage/me-user'

export const userMiddleware = ({dispatch}) => next => {
    return action => {
        next(action)

        switch (action.type) {
            case USER_FETCH:
                dispatch(
                    apiRequest({
                        method: 'GET',
                        url: USER_FETCH_URL,
                        signature: USER_FETCH,
                    }),
                )
                break

            case `${USER_FETCH} ${API_SUCCESS}`:
                dispatch(formSubmittingLogin(false))

                dispatch(countrySet(action.payload.data.country))

                if (action.payload.data.garage) {
                    dispatch(setGarage(action.payload.data.garage))
                }

                dispatch(userSet(action.payload.data))

                insertHotjar(action.payload.data.user.track_user);

                dispatch(setLoader(LOADER_INIT, true))

                break

            case `${USER_FETCH} ${API_ERROR}`:
                dispatch(formSubmittingLogin(false))
                dispatch(setLoader(LOADER_INIT, true))

                break

            default:
                break
        }
    }
}
