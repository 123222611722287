import { USER_LOGIN, USER_LOGOUT, USER_RESET_PASSWORD_EMAIL, USER_RESET_PASSWORD_NEW } from './auth.constants'
import { CHANGE_PASSWORD_SETTING_UPDATE } from '../actions/change-password.settings.actions'
import { CHANGE_USER_DETAILS_SETTING_UPDATE } from '../actions/change-user-details.settings.actions'
import { CHANGE_GARAGE_DETAILS_SETTING_UPDATE } from '../actions/change-garage-details.settings.actions'

export default [
    USER_LOGIN,
    USER_LOGOUT,
    USER_RESET_PASSWORD_EMAIL,
    USER_RESET_PASSWORD_NEW,
    CHANGE_PASSWORD_SETTING_UPDATE,
    CHANGE_USER_DETAILS_SETTING_UPDATE,
    CHANGE_GARAGE_DETAILS_SETTING_UPDATE,
]
