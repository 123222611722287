import React, {Component} from 'react'
import {translate} from 'react-i18next'
import Input from "../../../../components/shared/Input/Input";
import axiosDefault from 'axios'
import axios from "../../../../app/config/axios";
import {debounce, uniqBy} from "lodash";

export const LABOURS_SEARCH_URL = 'garage/form/labours-search';

class LabourSearch extends Component {

    constructor(props) {
        super(props);

        this.state = {
            searchText: '',
        }

        this.cancelToken = null;
        this.onTextChangeDebounced = debounce((value) => this.onTextChange(value), 300)
    }

    onTextChange = (value) => {
        const {onResolve, onSearch, onError} = this.props;

        onSearch(value);

        if (!value || value.trim().length < 2) return;

        if(this.cancelToken) this.cancelToken.cancel();

        this.cancelToken = axiosDefault.CancelToken.source();

        axios
            .post(LABOURS_SEARCH_URL, {search: value}, {cancelToken: this.cancelToken.token})
            .then(response => {

                onResolve(uniqBy(response.data, labour => [labour.itemMpId, labour.korId].join()));

            })
            .catch(error => {
                onError(error)
            })
            .finally(() => this.cancelToken = null)
    }

    onTextClear = (e) => {
        const {onClear} = this.props;

        if(this.cancelToken) this.cancelToken.cancel();

        onClear();
    }

    render() {
        const {t} = this.props;
        const {searchText} = this.state;

        return (
            <div className="labour-search">
                <Input
                    type="text"
                    placeholder={t('pages.form_steps.labours.search.placeholder')}
                    name="labours-search"
                    value={searchText}
                    onChange={(e) => this.onTextChangeDebounced(e.target.value)}
                    onTextClear={this.onTextClear}
                    preventOnEnter={true}
                    iconRightClassName={'icon-search'}
                />
            </div>
        )
    }
}

export default translate('translations')(LabourSearch);
