import { SET_REQUESTS, SET_REQUESTS_LOADING_STATUS } from '../actions/requestsList.actions'
import requestsList from '../models/requestsList'

export default function(state = requestsList, action) {
    switch (action.type) {

        case SET_REQUESTS:
            return {
                ...state, requestsList: action.payload.data,
            }

        case SET_REQUESTS_LOADING_STATUS:
            return {
                ...state, requestsLoadingStatus: action.payload.status,
            }

        default:
            return state
    }
}
