import tireLaboursPredefinedModel from '../models/tireLaboursPredefined'
import {
    TIRE_LABOURS_PREDEFINED_RESET, TIRE_LABOURS_PREDEFINED_RIM_SIZE_UPDATE,
    TIRE_LABOURS_PREDEFINED_SELECT,
    TIRE_LABOURS_PREDEFINED_SET
} from "../actions/tireLaboursPredefined.actions";

export default function (state = tireLaboursPredefinedModel, action) {
    switch (action.type) {

        case TIRE_LABOURS_PREDEFINED_SET:
            return action.payload.labours;

        case TIRE_LABOURS_PREDEFINED_RIM_SIZE_UPDATE:
            return state.map(labourGroup => {
                if(labourGroup.id === action.payload.labourGroupId){
                    return {
                        ...labourGroup,
                        checked: true,
                        selected: labourGroup.selected ? labourGroup.selected : labourGroup.labours[0].id,

                        labours: labourGroup.labours.map(labour => {
                            if(labour.id === action.payload.labourId){
                                return {
                                    ...labour,
                                    axel: {
                                        front: {
                                            selected: action.payload.axels.front.selected,
                                            rim_size: action.payload.axels.front.selected ? action.payload.axels.front.rim_size : null,
                                        },
                                        rear: {
                                            selected: action.payload.axels.rear.selected,
                                            rim_size: action.payload.axels.rear.selected ? action.payload.axels.rear.rim_size : null,
                                        },
                                    },
                                }
                            }

                            return labour;
                        })
                    };
                }

                return labourGroup;
            })

        case TIRE_LABOURS_PREDEFINED_SELECT:
            return state.map(labourGroup => {
                if(labourGroup.id === action.payload.labourGroupId){
                    return {
                        ...labourGroup,
                        checked: action.payload.checked,
                        selected: labourGroup.selected ? labourGroup.selected : labourGroup.labours[0].id,
                    };
                }

                return labourGroup;
            })

        case TIRE_LABOURS_PREDEFINED_RESET:
            return tireLaboursPredefinedModel;

        default:
            return state
    }
}
