/* eslint-disable */
import React from 'react'
import {
    TIRES_AXEL_FRONT,
    TIRES_AXEL_REAR, TIRES_SEASON_ALL_SEASON, TIRES_SEASON_NORDIC,
    TIRES_SEASON_SUMMER,
    TIRES_SEASON_WINTER
} from "../../../store/consts/tires.constants";

export default {
    translations: {
        links: {
            home: 'Page d\'accueil',
            services: 'Services',
            garage: 'Garage',
        },
        titles: {
            login: 'Se connecter',
            password_reset: 'Réinitialiser le mot de passe',
            password_reset_new: 'Activer le nouveau mot de passe',
            pick_garage: 'Choisissez un garage',

            home: 'Fleet Access',

            settings: 'Réglages',
            user_profile: 'Profil',

            requests: 'Ordres',
            request_details: 'Request details',//TODO: Translate

            form_steps: {
                car: 'Vehicle',
                official_service: 'Service selon les spécifications du constructeur',
                labours: 'Additional services', //TODO: Translate
                tires: 'Tires', //TODO: Translate
                overview: 'Overview', //TODO: Translate
                review: 'Your request is under review!', //TODO: Translate
            }

        },
        global: {
            alt: {
                'request-send-success-icon': 'Succès',
            },
            tabs: {
                pending: 'En attente',
                other: 'Autre',
            },
            or: 'ou',
            cancel: 'Annuler ',
            back: 'Retour',
            more: 'plus',
            currency: 'EUR',
            tax: 'T.V.A.',
            total: 'Total',
            total_with_vat: 'Total TTC',
            total_with_discount: 'Total Rabais',
            discount: 'Rabais',
            files: 'Photos',
            service: 'Service',
            part_number: 'Numéro d\'article:',
            to: 'à',
            km: 'km',
            loading: 'Chargement',
            garage_reinitialize: 'Changement de garage',
            save: 'Enregistrer',
            update: 'Mettre à jour',
            edit: 'Éditer',
            remove: 'Supprimer',
            yes: 'Yes',//TODO: Translate
            no: 'No',//TODO: Translate
            vat: 'VAT', //TODO: Translate
            upload: 'Upload', //TODO:Translate
            car: {
                make: 'Marque',
                range: 'Modèle',
                type: 'Version',
                go_back: 'Retour',
                year: 'Année de construction',
                licence_plate: 'Plaque d\'immatriculation',
                update_info: 'Mettre à jour les informations sur la voiture',
                edit: 'Éditer',
                mileage: 'Kilométrage',
            },
            official_service: {
                title: 'RECOMMANDATION POUR VOTRE VOITURE',
                description:
                    'Grâce aux données que vous nous avez fournies, ainsi qu\'aux informations du constructeur de {{carBrand}}, nous pouvons vous recommander un service personnalisé pour votre véhicule.',
                includes: 'INCLUS',
            },
            preselected_service: {
                title: 'Services sélectionnés: ',
            },
            additional_service: {
                open: 'Des services supplémentaires',
                title: 'Réparation',
                description: 'Sélectionnez d\'autres services pour affiner votre recherche.',
                uncheck_all: 'tout déselectionner',
                save: 'Enregistrer',
            },
            msg_title: {
                error: 'Message d\'erreur',
                info: 'Message d\'information',
            },
            undo: 'Annuler',
            undo_deleted: '{{num}} supprimé',
            you_sure: 'Êtes-vous sûr?',
            months: {
                0: 'Janvier',
                1: 'Février',
                2: 'Mars',
                3: 'Avril',
                4: 'Mai',
                5: 'Juin',
                6: 'Juillet',
                7: 'Août',
                8: 'Septembre',
                9: 'Octobre',
                10: 'Novembre',
                11: 'Décembre',
            },
            agreement: 'J\'accepte les',
            terms_and_conditions: 'Termes et conditions',
            privacy_policy: 'Politique de confidentialité',
            no_data: 'Pas de données',
            optional: 'optional',
            dont_know_lp: 'Don\'t know your plate number?', // TODO Translate
            form_leave_prompt: 'Are you sure you wont to leave, you can lose your data', //TODO: Translate
        },
        form: {
            placeholder: {
                contract_number: 'Contract number', //TODO: Translation
                make: 'Marque',
                range: 'Modèle',
                type: 'Version',
                gear: 'Type de boîte de vitesse',
                first_registration: 'Mise en circulation',
                qual_md_list: 'Propriétés du véhicule',
                qual_lt_list: 'Carrosserie du véhicule',
                username: 'Numéro de portable. / Entrez votre email / nom d\'utilisateur',
                username2: 'Nom d\'utilisateur',
                year: 'Mise en circulation',
                km_stand: 'Kilométrage',
                first_name: 'Prénom',
                last_name: 'Nom de famille',
                email: 'Email',
                phone: 'Numéro de téléphone',
                password: 'Mot de passe',
                password_confirmation: 'Confirmez le mot de passe',
                city: 'Ville',
                color: 'Couleur',
                canton: 'ZH',
                plate_number: '18 341',
                default: {
                    select: 'Veuillez sélectionner',
                },
                user_activity: 'Status',
                date: 'Sélectionner une date',
                morning: 'Demain',
                afternoon: 'Après midi',
                car_number: {
                    vin_swiss: 'TSN ou VIN',
                    vin_kba: 'Numéro KBA ou VIN',
                    vin: 'VIN',
                    swiss: 'TSN',
                    kba: 'Numéro KBA',
                    default: 'Identification de la voiture',
                },
                user_type: 'Autorisation',
                mileage: 'Kilométrage',
                leasing: 'Leasing', // TODO: Translate
            },
            label: {
                contract_number: 'Contract number', //TODO: Translation
                licence_plate: 'Plaque d\'immatriculation',
                make: 'Marque',
                range: 'Modèle',
                type: 'Version',
                gear: 'Type de boîte de vitesse',
                first_registration: '2019',
                qual_md_list: 'Propriétés du véhicule',
                qual_lt_list: 'Carrosserie du véhicule',
                username: 'Numéro de portable. / Entrez votre email / nom d\'utilisateur',
                username2: 'Nom d\'utilisateur',
                year: 'Année de la 1ère mise en circulation',
                km_stand: 'Kilométrage',
                first_name: 'Prénom',
                last_name: 'Nom de famille',
                phone: 'Numéro de téléphone',
                email: 'Email',
                password: 'Mot de passe',
                password_confirmation: 'Confirmez le mot de passe',
                date: 'Date',
                availability: 'Disponibilité',
                bookFor: 'Réservez pour',
                book: 'Demandes',
                city: 'Ville',
                color: 'Couleur:',
                car_number: {
                    vin_swiss: 'TSN ou VIN',
                    vin_kba: 'Numéro KBA ou VIN',
                    vin: 'VIN',
                    swiss: 'TSN',
                    kba: 'Numéro KBA',
                    default: 'Identification de la voiture',
                },
                user_type: 'Type de compte',
                mileage: 'Kilométrage',
                leasing: 'Leasing', // TODO: Translate
            },
            button: {
                next: 'Continuer',
                confirm: 'Confirmer',
                submit: 'Continuer',
            },
            select: {
                no_result: 'Aucun résultat trouvé',
            },
        },
        validations: {
            label: {
                contract_number: 'Contract number', //TODO: Translate
                make: 'Marque',
                range: 'Modèle',
                type: 'Version',
                gear: 'Type de boîte de vitesse',
                first_registration: 'Mise en circulation',
                qual_md_list: 'Propriétés du véhicule',
                qual_lt_list: 'Carrosserie du véhicule',
                username: 'Numéro de portable. / Entrez votre email / nom d\'utilisateur',
                username2: 'Nom d\'utilisateur',
                year: 'Année de la 1ère mise en circulation',
                km_stand: 'Kilométrage',
                car_number: 'plaque d\'immatriculation',
                avatar: 'Avatar',
                firstname: 'Prénom',
                lastname: 'Nom de famille',
                phone: 'Numéro de téléphone',
                email: 'Email',
                password: 'Mot de passe',
                password_match: 'Les mots de passe doivent correspondre',
                user_type: 'Type de compte',

                zip_not_found: 'Aucun résultat avec NPA {{name}}',
                zip_not_valid: 'Veuillez choisir un code postal valide.',
                zip_required: 'NPA est obligatoire.',
                zip_must_be_number: 'Veuillez saisir que des chiffres.',
                leasing: 'Leasing', // TODO: Translate
                mileage: 'Mileage', // TODO: Translate
            },
            default: {
                mixed: {
                    required: '${label} manquant',
                },
                string: {
                    email: 'Merci de consulter vos emails',
                    min: 'Valeur trop courte',
                    max: 'Valeur trop longue',
                    test: '${label} accepter',
                },
                boolean: {
                    required: '${label} accepter',
                },
            },
            licence_plate: {
                canton: {
                    valid: 'Canton', //Valide
                    nonValid: 'Canton', //Pas valide
                },
                number: {
                    valid: 'Numéro', //Valide
                    nonValid: 'Numéro', //Pas valide
                },
                required: 'Une plaque d\'immatriculation valide est requise',
                cantonAsString: 'Canton ne peut contenir que des lettres',
            },
            car_number: {
                invalid_format: 'Format non valide pour le numéro de certificat de type',
            },
        },
        header: {
            profile_settings: 'Paramètres de profil',
            switch_garage: 'Changer de garage',
            settings: 'Paramètres du garage',
            logout: 'Déconnexion',
            terms_and_conditions: 'Termes et conditions',
            privacy_policy: 'Politique de confidentialité',
            requests: 'Requests',
        },
        confirm_popup: {
            ok: 'OK',
            cancel: 'Annuler ',
        },
        user: {
            activity: {
                active: 'Activé',
                inactive: 'Désactivée',
            },
            roles: {
                manager: 'Conseiller Service',
                mechanic: 'Mécanicien',
                mechanic_advanced: 'Mécanicien avec contact client',
                supervisor: 'Superviseur',
            },
        },
        pages: {
            home: {
                title: 'Fleet Access',
                new_requests: 'Nouvelles commandes:',
            },
            login: {
                title: 'Se connecter',
                submit: 'Se connecter',
                forget_password: 'Mot de passe oublié?',
            },
            password_reset: {
                title: 'Réinitialiser le mot de passe',
                tip: 'Entrez votre numéro de téléphone portable ou votre adresse e-mail pour réinitialiser le mot de passe.',
                submit: 'Réinitialiser le mot de passe',
                cancel: 'Annuler ',
            },
            password_reset_new: {
                title: 'Activation d\'un nouveau mot de passe',
                tip: 'Entrez votre nouveau mot de passe dans les champs ci-dessous.',
                submit: 'Confirmez le mot de passe',
            },
            pick_garage: {
                title: 'Choisissez un garage',
                tip: 'Veuillez sélectionner un atelier auquel vous souhaitez vous inscrire',
            },
            settings: {
                title: 'Réglages',
                section_tabs: {
                    change_details: 'Modifier les détails',
                    phones: 'Numéro de contact (le numéro sera indiqué au client)',
                    communication: 'Notifications',
                },
                section_form: {
                    label: {
                        address: 'Adresse:',
                        web: 'Site Internet:',
                        zip_id: 'Code postal:',
                        logo: 'Logo:',
                        notification_phone: 'Numéro de portable:',
                        notification_email: 'E-mail:',
                    },
                    placeholder: {
                        address: 'Adresse:',
                        web: 'Site Internet:',
                        zip_id: 'Code postal:',
                        logo: 'Logo:',
                        email: 'E-mail',
                        phone: 'Numéro de portable',
                    },
                    logo_change: 'Changer',
                    logo_upload: 'Télécharger le logo',
                    avatar_upload: 'Télécharger un avatar',
                    avatar_change: 'Changer d\'avatar',
                    submit_user_details: 'Enregistrer',
                    notification_detail:
                        'Répertoriez tous les destinataires affectés pour recevoir des notifications.',
                    phones_detail: 'Le numéro est affiché au client.',
                    add_new_phone: 'Ajouter un nouveau numéro',

                    file_size: 'Fichier trop gros',
                    file_format: 'Format non supporté',
                    one_of_required: 'Numéro de téléphone portable ou e-mail requis',

                    communication: {
                        nexmo_multiple: 'SMS',
                        mail: 'E-mail',
                        push_notification: 'Notification push',
                    },
                    communication_required: 'Au moins un canal de notification est requis',
                },
                section_popup: {
                    header_remove: 'Voulez-vous vraiment supprimer le destinataire?',
                    header_add: 'Ajouter un nouveau destinataire',
                    header_update: 'Mettre à jour le destinataire',
                    remove: 'Supprimer',
                },
            },
            user_profile: {
                title: 'Profil de l\'utilisateur',
                section_tabs: {
                    default_language: 'Choisissez la langue par défaut',
                    change_password: 'Changer le mot de passe',
                    change_details: 'Modifier les détails',
                },
                section_form: {
                    label: {
                        current_password: 'Ancien mot de passe',
                        new_password: 'Nouveau mot de passe',
                        repeat_password: 'Confirmer le nouveau mot de passe',
                        notification_phone: 'Numéro de portable:',
                        notification_email: 'E-mail:',
                        first_name: 'Prénom',
                        last_name: 'Nom de famille',
                        email: 'E-mail',
                        phone: 'Numéro de portable',
                        username: 'Nom d\'utilisateur',
                    },
                    placeholder: {
                        current_password: 'Entrez l\'ancien mot de passe',
                        new_password: 'Entrer un nouveau mot de passe',
                        repeat_password: 'Confirmer le nouveau mot de passe',
                        notification_email: 'Entrez un nouveau courriel',
                        notification_phone: 'Entrez un nouveau numéro de mobile',
                        first_name: 'Entrez votre prénom',
                        last_name: 'Entrez le nom',
                        email: 'Entrez l\'email',
                        phone: 'Entrez le numéro de mobile',
                        username: 'Nom d\'utilisateur',
                    },
                    avatar: {
                        change: 'éditer',
                        crop: {
                            select: 'Télécharger une photo',
                            zoom: 'Agrandir',
                            rotate: 'Tourner',
                            save: 'Enregistrer',
                            cancel: 'Annuler',
                            title: 'Recadrer l\'image',
                        },
                    },
                    submit_new_password: 'Changer le mot de passe maintenant',
                    submit_user_details: 'Enregistrer',

                    file_size: 'Fichier trop gros',
                    file_format: 'Format non supporté',
                },
            },

            form_steps: {
                car: {
                    method_title_lp: 'Trouver une voiture par plaque d\'immatriculation',
                    method_title_swiss: 'Trouvez votre voiture en entrant TSN',
                    method_title_multiple: 'Choisissez la couleur de votre voiture',
                    method_title_manual: 'Entrez les détails manuellement',
                    method_title_manual_2: 'We found your car',

                    sourced_success: 'Nous avons obtenu avec succès des informations sur le véhicule.',

                    car_number: {
                        vin_swiss: 'Vous ne connaissez pas le VIN ou TSN?',
                        vin_kba: 'Vous ne connaissez pas le VIN ou KBA?',
                        vin: 'Vous ne connaissez pas le VIN?',
                        swiss: 'Vous ne connaissez pas le TSN?',
                        kba: 'Vous ne connaissez pas le KBA?',
                        default: 'Vous ne connaissez pas le VIN ou TSN?',
                    },

                    swiss: {
                        manual: 'Entrez les détails manuellement',
                    },
                },
                official_service: {
                    title: 'Entretien selon spécifications du constructeur',
                    total_official: 'Total TVA incluse',
                    select_other_services: 'Select other services:',//TODO: Translate
                    submit: 'Suivant',
                    no_recommended: 'Nous n’avons pas pu trouver de prestation pour le véhicule sélectionné.',
                    or_more: 'ou plus',
                    info: {
                        ok: 'OK',
                    },
                    service_interval_title: 'Entretien à ',
                    show_more: 'Plus',
                    show_less: 'Moins',
                    included_in_official: 'Included in service', //TODO: Translate
                },
                labours: {//TODO: Translate
                    title: 'Additional services',
                    search: {
                        placeholder: 'Search services',
                    },
                    submit: 'Save',
                    no_result: 'No services found. Please check Your spelling.',
                    load_more: 'Load more', //TODO: Translate
                    not_available: 'Labour not available',
                },
                tires: {//TODO: Translate
                    title: 'Tires', //TODO: Translate

                    axel: {
                        [TIRES_AXEL_FRONT]: 'Avant',
                        [TIRES_AXEL_REAR]: 'Arrière',
                        front: 'Avant',
                        rear: 'Arrière',
                        both: 'Avant + Arrière',
                    },
                    season: {
                        [TIRES_SEASON_SUMMER]: 'Été',
                        [TIRES_SEASON_WINTER]: 'Hiver',
                        [TIRES_SEASON_ALL_SEASON]: 'All Season',
                        [TIRES_SEASON_NORDIC]: 'Nordic',
                    },

                    filter_title_both: 'Pneus avant + arrière',
                    filter_title_front: 'Pneus avant',
                    filter_title_rear: 'Pneus arrière',

                    filter_index_all: 'Tous les index',

                    package_name: 'Changement de pneus',

                    ean: 'EAN',
                    sap: 'SAP',

                    label: {
                        wide: 'Largeur',
                        depth: 'Taille',
                        diameter: 'Diamètre',

                        season: 'Saison',
                        index: 'Index',
                        brand: 'Marque',

                        front_axel: 'Essieu avant',
                        rear_axel: 'Essieu arrière',

                        same_dimension: 'Mêmes dimensions',
                    },

                    labours: {
                        new_tires: 'New Tires', //TODO: Translate

                        rim_size: {
                            title: 'Select rim size and axels for {{labour}}',// TODO: Translate
                            select: 'Rim size',// TODO: Translate
                        },
                    },

                    process: {
                        title: 'Select tires', // TODO: Translate
                    },

                    header: {
                        brand: 'Marque',
                        index: 'Index',
                        price: 'Prix',
                    },

                    no_tires: 'Pas de pneus pour ce critère',
                },
                overview: {//TODO: Translate
                    title: 'Overview',
                    show_request_spec: 'Show request specification',
                    hide_request_spec: 'Hide request specification',
                    labour_remove_title: 'Are You sure You want to remove following services?',
                    request_specification: {
                        title: 'Request specification',
                        parts_title: 'Parts',
                        serial_number: 'Serial number',
                        part_name: 'Parts',
                        quantity: 'Quantity',
                        oil_title: 'Oil',
                        price: 'Price',
                        parts_discount: 'Discount on parts',
                        brakes_discount: 'Discount on brakes',
                        works_title: 'Working hours',
                        custom_labours: 'Custom labours', //TODO: Translate
                        works_name: 'Service',
                        time: 'Time',
                        calc_time: 'Calc. time',
                        hourly_price: 'Price/hour',
                        recommended_oil: 'Manufacturers recommendation',

                        tire_labours: {
                            front_size: 'Front size', //TODO: Translate
                            front_price: 'Front price', //TODO: Translate
                            rear_size: 'Rear size', //TODO: Translate
                            rear_price: 'Rear price', //TODO: Translate
                            run_flat_add: 'Run flat add', //TODO: Translate
                        },
                    },
                    agree_with_calculation: 'I agree that I checked the prices, and I’m aware of the calculations.',
                },
                review: { //TODO: Translate
                    in_progress: {
                        title: 'Your request is under review!',
                        subtitle: 'Approval may take a few minutes...',
                        desc: 'Alternatively, you may proceed with other requests and we will notify you as soon as this request has been answered to. You will find the notification and may check request status in request list at all times.',
                        cancel: 'Cancel request',
                        all_requests: 'All requests', //TODO: Translate
                        cancel_request_title: 'Are You sure You want to cancel following request?', //TODO: Translate
                    },
                    accepted: {
                        title: 'Request accepted!',
                        subtitle: 'Your request for {{car}} has been accepted.',
                        desc: 'Request accepted description',
                        cancel: 'Cancel request',
                        status: 'Approved',
                        see_details: 'See request details',
                        print: 'Print PDF / Export to...',
                    },
                    rejected: {
                        title: 'Request denied!',
                        subtitle: 'Your request for {{car}} has been denied.',
                        reason: 'Reason:',
                        status: 'Denied',
                        archive: 'Archive request',
                        edit: 'Edit request',
                        edit_confirm: 'Are you sure You want to edit this request? It’s a non-reversible action.',
                        archive_confirm: 'Are you sure You want to archive this request? It’s a non-reversible action.',
                    },
                }
            },
            requests: {
                title: 'All requests', //TODO: Translate
                filter_label: 'Filter by status', //TODO: Translate
                no_result: 'There is no results', //TODO: Translate
                pending_status: 'Pending', //TODO: Translate
                approved_status: 'Approved', //TODO: Translate
                denied_status: 'Denied', //TODO: Translate
                archived_status: 'Archived', //TODO: Translate
                all: 'All', //TODO: Translate
                table: {
                    status: 'Status', //TODO: Translate
                    leasing_provider: 'Leasing provider', //TODO: Translate
                    car_info: 'Car info', //TODO: Translate
                    request_details: 'Request details', //TODO: Translate
                    date: 'Date', //TODO: Translate
                    pdf: 'PDF', //TODO: Translate
                    status_1: 'Pending', //TODO: Translate
                    status_4: 'Canceled', //TODO: Translate
                    status_2: 'Approved', //TODO: Translate
                    status_3: 'Denied', //TODO: Translate
                },
            },

            request_details: { //TODO: Translate
                title: 'Request details',
                upload_invoice_label: 'Upload invoice',
                export_xml: 'Export XML',
                export_pdf: 'Download PDF',
                edit: 'Edit request',
                edit_desc: 'Are you sure You want to edit this request? It’s a non-reversible action.',
                cancel: 'Cancel request',
                cancel_desc: 'Are you sure You want to cancel this request? It’s a non-reversible action.',
                archive: 'Archive request',
                archive_desc: 'Are you sure You want to archive this request? It’s a non-reversible action.',
                statuses: {
                    1: 'In Progress',
                    2: 'Approved',
                    3: 'Rejected',
                    4: 'Canceled',
                },
                request_id: 'Request ID:',
                date: 'Date:',
                status: 'Status:',
                archived: 'Archived',
                denial_reason: 'Reason for denial',
                request_overview: 'Request overview',
                add_new_service: 'Add new service',
                car_details: 'Car details',
                car_model: 'Model',
                car_range: 'Modèle',
                car_type: 'Version',
                car_vin_swiss: 'Vin/Swiss type',
                car_mileage: 'Mileage',
                car_first_reg: 'First reg',
                car_leasing: 'Leasing provider',

                upload_invoice: { // TODO: Translate
                    file_type: 'Allowed file types are {{format}}',
                    file_size: 'Maximum file size {{size}}',
                },
                invoice: { // TODO: Translate
                    title: 'Invoice',
                    download_invoice: 'Facture',
                    pending: ' (en révision)',
                    uploaded: 'Uploaded',
                },
                drag_and_drop: { // TODO: Translate
                    title: 'Drag and drop',
                    or: 'oder',
                    upload_from: 'laden Sie die Datei von Ihrem Gerät hoch.',
                },
            },
        },
        toast: {
            success: 'Merci',
            error: 'Oops!',
            info: 'Info',
            warning: 'Avertissement',
        },
        language: {
            en: 'Anglais',
            de: 'Allemand',
            fr: 'Français',
        },
        form_steps: {//TODO: Translate
            1: 'Car data',
            2: 'Service according to the manufacturers data',
            3: 'Tires', //TODO: Translate
            4: 'Additional works',
            5: 'Overview of the offer',
            6: 'Check the works and validate the price',
        },
        footer: {
            copyright: 'Copyright by Stratos Technologies AG 2021', //TODO: Translate
            terms: 'Terms and conditions', //TODO: Translate
            privacy: 'Privacy policy', //TODO: Translate
        },
    },
}
