import React, {Component} from 'react'
import {translate} from 'react-i18next'
import InputCheckbox from "components/shared/InputCheckbox";
import TiresPickContainer from "components/TiresComponents/TiresPickContainer";
import {TIRES_AXEL_FRONT, TIRES_AXEL_REAR, TIRES_AXELS} from "../../../../store/consts/tires.constants";

class LabourTireProcess extends Component {

    state = {
        tireProcessOpened: false,
    }

    handleChange = (e) => {
        const {tiresProcess, onUpdateTiresProcess} = this.props;

        onUpdateTiresProcess('process_selected', e.target.checked)

        if(!tiresProcess.tires[TIRES_AXEL_FRONT] && !tiresProcess.tires[TIRES_AXEL_REAR]){
            this.setState({
                tireProcessOpened: e.target.checked
            })
        }
    }

    saveNewTires = (tires) => {
        const {saveNewTires} = this.props;

        saveNewTires(tires)

        this.setState({
            tireProcessOpened: false,
        })
    }

    editTires = () => {
        const {tiresEditPrepare} = this.props;

        tiresEditPrepare();

        this.setState({
            tireProcessOpened: true,
        })
    }

    onTiresPickClose = () => {
        const {onUpdateTiresProcess, tiresProcess} = this.props;

        if(!tiresProcess.tires[TIRES_AXEL_FRONT] && !tiresProcess.tires[TIRES_AXEL_REAR]){
            onUpdateTiresProcess('process_selected', false)
        }

        this.setState({tireProcessOpened: false})
    }

    render() {
        const {t, tiresProcess} = this.props;
        const {tireProcessOpened} = this.state;

        return (
            <>
                <div className="labour-item">
                    <div className="row">
                        <div className="col-sm-10">
                            <InputCheckbox
                                id={`tire-labour-process`}
                                name={`tire-labour-process`}
                                checked={tiresProcess.process_selected}
                                onChange={this.handleChange}
                                label={(
                                    <div>
                                        <div className="name">{t('pages.form_steps.tires.labours.new_tires')}</div>

                                        {(tiresProcess.process_selected) &&
                                            <div className="desc">
                                                {tiresProcess.tires[TIRES_AXEL_FRONT] && <div>{t(`pages.form_steps.tires.axel.${TIRES_AXEL_FRONT}`)}: {tiresProcess.tires[TIRES_AXEL_FRONT].full_name} {tiresProcess.tires[TIRES_AXEL_FRONT].full_axel}</div>}
                                                {tiresProcess.tires[TIRES_AXEL_REAR] && <div>{t(`pages.form_steps.tires.axel.${TIRES_AXEL_REAR}`)}: {tiresProcess.tires[TIRES_AXEL_REAR].full_name} {tiresProcess.tires[TIRES_AXEL_REAR].full_axel}</div>}
                                            </div>
                                        }
                                    </div>
                                )}
                            />
                        </div>

                        <div className="col-sm-2">
                            {(tiresProcess.process_selected && tiresProcess.tires) &&
                                <span className="link-style" onClick={this.editTires}>{t('global.edit')}</span>
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            {tireProcessOpened &&
                                <TiresPickContainer
                                    onCreate={this.saveNewTires}
                                    onClose={this.onTiresPickClose}
                                />
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default translate('translations')(LabourTireProcess);
