import React, {Component} from 'react';
import {translate} from "react-i18next";
import {connect} from "react-redux";
import axios from "../../app/config/axios";
import Button from "../shared/Button/Button";
import Popup from "../shared/Popup/Popup";
import {garageTermsAndConditionsAccept} from "../../store/actions/garage.actions";
import language from "../../utils/language";

const API_TERMS_AND_CONDITIONS_ACCEPT_URL = '/garage/terms-and-conditions/accept'

class TermsAndConditions extends Component {

    state = {
        visible: false,
        url: [],
    }

    componentDidMount() {
        const {garage, acceptable} = this.props;

        if(garage.terms_and_conditions.accepted === false || acceptable !== true){
            this.setState({
                title: garage.terms_and_conditions.name,
                url: garage.terms_and_conditions.url,
                version: garage.terms_and_conditions.version,
                visible: true,
            })
        }
    }

    accept = () => {
        const {dispatch} = this.props;

        this.setState({activating: true})

        axios.post(API_TERMS_AND_CONDITIONS_ACCEPT_URL)
            .then(response => {
                if(response.data.accepted){
                    dispatch(garageTermsAndConditionsAccept());
                }
            })
            .finally(() => {
                this.setState({
                    visible: false,
                    activating: false
                })
            })
    }

    render() {
        const {t, acceptable, onClose} = this.props;
        const {title, visible, url, activating} = this.state;

        return (
            <React.Fragment>

                <Popup visible={visible} title={title} onClose={onClose} hideCancel={acceptable} contentScrollable={true}>
                    <iframe title={title} src={url[language()]}/>

                    {acceptable &&
                        <Button size="lg" type="primary" disabled={activating}
                                onClick={this.accept}>{t('global.agreement')} {t('global.terms_and_conditions')}</Button>
                    }

                </Popup>

            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        garage: state.garage,
    };
};

export default connect(mapStateToProps)(translate('translations')(TermsAndConditions));
