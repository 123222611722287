export const TIRES_PROCESS = '[Tires Process]'

export const TIRES_PROCESS_SET = `${TIRES_PROCESS} SET`;
export const TIRES_PROCESS_UPDATE = `${TIRES_PROCESS} UPDATE`;
export const TIRES_PROCESS_RESET = `${TIRES_PROCESS} RESET`;

export const tiresProcessSet = (tires, selected) => ({
    type: TIRES_PROCESS_SET,
    payload: {
        tires, selected
    }
});

export const tiresProcessUpdate = (path, value) => ({
    type: TIRES_PROCESS_UPDATE,
    payload: {
        path, value
    }
});

export const tiresProcessReset = () => ({
    type: TIRES_PROCESS_RESET,
});
