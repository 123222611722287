import React, {Component} from 'react';
import {translate} from "react-i18next";
import {connect} from "react-redux";
import './Footer.scss'
import PrivacyPolicy from "../../PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "../../TermsAndConditions/TermsAndConditions";

class Footer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showTermsAndConditions: false,
            showPrivacyPolicy: false,
        }
    }

    toggleTermsAndConditions = () => {
        const {showTermsAndConditions} = this.state;

        this.setState({
            showTermsAndConditions: !showTermsAndConditions
        });
    };

    togglePrivacyPolicy = () => {
        const {showPrivacyPolicy} = this.state;

        this.setState({
            showPrivacyPolicy: !showPrivacyPolicy
        });
    };

    render() {
        const {showTermsAndConditions, showPrivacyPolicy} = this.state
        const {t, garage} = this.props

        if (!garage.logged) return null;

        return (
            <React.Fragment>

                <div className="content-wrapper">
                    {showPrivacyPolicy &&
                    <PrivacyPolicy
                        onClose={this.togglePrivacyPolicy}
                    />
                    }

                    {showTermsAndConditions &&
                    <TermsAndConditions
                        onClose={this.toggleTermsAndConditions}
                    />
                    }

                    <div className="footer">
                        <div className="container">
                            <div className="footer__left">
                                <span>
                                    {`${t('footer.copyright')}`}
                                </span>
                            </div>
                            <div className="footer__right">
                                <span className="footer__clickable" onClick={this.toggleTermsAndConditions}>
                                    {`${t('footer.terms')}`}
                                </span>
                                <span className="footer__clickable footer__margin-left-24"
                                      onClick={this.togglePrivacyPolicy}>
                                    {`${t('footer.privacy')}`}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        garage: state.garage,
    };
};

export default connect(mapStateToProps)(translate('translations')(Footer));
