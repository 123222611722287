import {
    LOADER_API_GET_CAR,
    LOADER_FORM_VEHICLE,
    LOADER_INIT,
    LOADER_LICENCE_PLATE,
    LOADER_REQUESTS,
} from 'store/consts/loader.constants'

export default {
    [LOADER_INIT]: false,
    [LOADER_FORM_VEHICLE]: {},
    [LOADER_LICENCE_PLATE]: false,
    [LOADER_API_GET_CAR]: false,
    [LOADER_REQUESTS]: false,
}
