import tiresProcessModel from '../models/tiresProcess'
import {
    TIRES_PROCESS_UPDATE,
    TIRES_PROCESS_RESET, TIRES_PROCESS_SET,
} from "../actions/tiresProcess.actions";
import {cloneDeep, set} from "lodash";

export default function (state = tiresProcessModel, action) {
    switch (action.type) {

        case TIRES_PROCESS_SET:
            return {
                process_selected: action.payload.selected,
                tires: action.payload.tires,
            };

        case TIRES_PROCESS_UPDATE:
            return set(cloneDeep(state), action.payload.path, action.payload.value);

        case TIRES_PROCESS_RESET:
            return tiresProcessModel;

        default:
            return state
    }
}
