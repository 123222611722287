import {
    TIRES_AXEL_FRONT, TIRES_AXEL_REAR,
    TIRES_SORT_ASC,
    TIRES_STEP_AXEL,
} from "../consts/tires.constants";

export default {
    axel: {
        [TIRES_AXEL_FRONT]: {
            selected: true,

            wide: null,
            wide_list: [],
            depth: null,
            depth_list: [],
            diameter: null,
            diameter_list: [],

            tire: null,
        },
        [TIRES_AXEL_REAR]: {
            selected: true,

            wide: null,
            wide_list: [],
            depth: null,
            depth_list: [],
            diameter: null,
            diameter_list: [],

            tire: null,
        }
    },

    sameDimensions: true,

    brand: null,
    brand_list: [],

    season: null,
    season_list: [],

    index: 0,
    index_list: [],

    list: [],

    labours: [],

    sort: {
        column: 'brand',
        order: TIRES_SORT_ASC,
    },

    step: TIRES_STEP_AXEL,
    loading: false,
};
