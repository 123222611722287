export const FORM_SUBMITTING = '[Form Submitting]'

export const FORM_SUBMITTING_VEHICLE = `${FORM_SUBMITTING} VEHICLE`
export const FORM_SUBMITTING_LOGIN = `${FORM_SUBMITTING} LOGIN`
export const FORM_SUBMITTING_SETTINGS = `${FORM_SUBMITTING} SETTINGS`

export const formSubmittingVehicle = submitted => ({
    type: FORM_SUBMITTING_VEHICLE,
    payload: {
        submitted,
    },
})

export const formSubmittingLogin = submitted => ({
    type: FORM_SUBMITTING_LOGIN,
    payload: {
        submitted,
    },
})

export const formSubmittingSettings = submitted => ({
    type: FORM_SUBMITTING_SETTINGS,
    payload: {
        submitted,
    },
})
