import React, {Component} from 'react'
import {translate} from 'react-i18next'
import InputCheckbox from "../../../../components/shared/InputCheckbox";
import axios from "../../../../app/config/axios";
import classNames from 'classnames';
import {parseResponse, toBool} from "../../../../utils/common";
import {toast} from "react-toastify";
import Toast from "../../../../components/shared/Toast/Toast";

const API_GET_LABOUR = 'garage/form/get-labour';

class LabourItem extends Component {

    constructor(props) {
        super(props);

        this.cancelToken = null;
    }

    getLabour = (labour) => {
        return axios.post(API_GET_LABOUR, {labours: [labour]})
    }

    handleChange = (e, data) => {
        const {onChange, labour} = this.props;

        let checked = e.target.checked;

        if (checked === false) {
            return onChange(e, {...data, checked: false});
        }

        this.getLabour(labour)
            .then((response) => {

                let exist = toBool(response.data.find((labour) => labour.exist))

                onChange(e, {...data, loading: false, exist: exist, checked: (checked && exist)});
            })
            .catch((error) => {
                toast.error(<Toast text={parseResponse(error.response)} type="error"/>)
                onChange(e, {...data, loading: false});
            })

        onChange(e, {...data, loading: true});
    }

    render() {
        const {t, labour} = this.props;

        return (
            <>
                <div className="labour-item">
                    <div className="row">
                        <div className="col-sm-9">
                            <InputCheckbox
                                id={`labour-${labour.itemMpId}-${labour.korId}`}
                                name={`labour-${labour.itemMpId}-${labour.korId}`}
                                value={labour.itemMpId}
                                checked={labour.checked}
                                onChange={this.handleChange}
                                label={(
                                    <div className={classNames("", {'labour-item--not-available': !labour.exist})}>
                                        <div className="name">{labour.name}</div>

                                        {(labour.checked && labour.siblings.length) > 0 &&
                                        <div className="labour-item__siblings">
                                            {labour.siblings.length > 0 && labour.siblings.map((sibling, key) => {
                                                return (
                                                    <div key={key} className="labour-item__siblings__name">{sibling.name}</div>
                                                )
                                            })}
                                        </div>
                                        }

                                        <span className="desc">{labour.group} / {labour.subGroup}</span>
                                    </div>
                                )}
                                loading={labour.loading}
                                disabled={labour.prechecked || labour.loading || !labour.exist}
                                data={labour}
                            />
                        </div>
                        <div className="col-sm-3 text-right">
                            {labour.prechecked &&
                            <span className="service-in-official">
                                <i className="icon-success"/>
                                <span>{t('pages.form_steps.official_service.included_in_official')}</span>
                            </span>}

                            {!labour.exist && <span className="service-not-available">
                                <i className="icon-fail"/> {t('pages.form_steps.labours.not_available')}</span>}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default translate('translations')(LabourItem);
