export const availableWorks = labours => {
    let works = {}
    labours.map(labour => {
        works[`itemMpId:${labour.itemMpId}`] = []

        return labour.works.forEach(work => {
            if (work.selected === true) {
                works[`itemMpId:${labour.itemMpId}`].push(work)
            }
        })
    })

    return works
}

export const availableParts = labours => {
    let parts = {}
    labours.map(labour =>
        labour.works.map(work =>
            work.selected === true
                ? work.parts.forEach(part => {
                      parts[`itemMpId:${labour.itemMpId}/works/id:${work.id}/parts/id:${part.id}`] = part
                  })
                : null,
        ),
    )

    return parts
}

export const getWorks = labours => {
    let works = [];

    labours.map(labour =>
        labour.works.filter(v => v.selected).forEach(work => {
            works.push(work)
        }),
    )

    return works
}

export const getParts = labours => {
    let parts = [];

    labours.map(labour =>
        labour.works.filter(v => v.selected).forEach(work => {
            work.parts.forEach(part => {
                parts.push(part)
            })
        }),
    )

    return parts
}

export const partQuantity = partSet => {
    if(partSet.quantity === 'litres'){
        return 'L'
    }

    return '';
}
