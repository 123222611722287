import React, {Component} from 'react'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'
import {HasOfferToken} from 'routes/middleware/HasOfferToken'
import Header from "components/layout/Header/Header";
import "./OverviewStepContainer.scss";
import CarStepMiddleware from "../../../routes/middleware/FormSteps/CarStepMiddleware";
import FormStepCounter from "../../../components/FormStepCounter/FormStepCounter";
import FormStepSubmit from "../../../components/FormStepSubmit/FormStepSubmit";
import LaboursMiddleware from "../../../routes/middleware/FormSteps/LaboursMiddleware";
import {changeStepData, setStepNext, setStepPrev} from "../../../store/actions/step.actions";
import LabourDetails from "./components/LabourDetails";
import {laboursCalculate} from "../../../store/actions/labours.actions";
import OfficialServiceDetails from "./components/OfficialServiceDetails";
import {LABOUR_TYPE_OFFICIAL, LABOUR_TYPE_REGULAR} from "../../../store/consts/labours/labours.constants";
import {differenceWith, isEqual} from 'lodash';
import {OFFICIAL_SERVICE_SELECTED} from "../../../store/consts/officialServices.constants";
import {sumPriceTotalVat} from "../../../store/selectors/laboursPrice";
import BaseCountry from "../../../utils/BaseCountry";
import {parseResponse, roundCeil} from "../../../utils/common";
import RequestSpecification from "./components/RequestSpecification";
import LinkIcon from "../../../components/shared/LinkIcon/LinkIcon";
import AgreeWithCalculation from "./components/AgreeWithCalculation";
import axios from "../../../app/config/axios";
import {toast} from "react-toastify";
import Toast from "../../../components/shared/Toast/Toast";
import {setRequestStatus} from "../../../store/actions/request.actions";
import FormBeforeLeavePrompt from "../../../components/FormBeforeLeavePrompt/FormBeforeLeavePrompt";
import {FORM_STEP_OVERVIEW} from "../../../store/consts/step.constants";
import TireLaboursDetails from "./components/TireLaboursDetails";
import TiresDetails from "./components/TiresDetails";
import {offerPriceTotal, offerPriceTotalWithTax} from "../../../store/selectors/offerPrice";
import {tireLaboursUpdate} from "../../../store/actions/tireLabours.actions";
import {selectTireLaboursSelected} from "../../../store/selectors/tireLaboursPredefined";
import {tiresNewReset} from "../../../store/actions/tiresNew.actions";
import {tiresProcessReset} from "../../../store/actions/tiresProcess.actions";

const SEND_REQUEST_URL = 'garage/form/send-request';

class OverviewStepContainer extends Component {

    state = {
        showRequestSpecification: false,
        agreeWithCalculation: false,
        saving: false,
    }

    componentDidMount() {
        const {dispatch} = this.props;

        dispatch(changeStepData(FORM_STEP_OVERVIEW));
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const {dispatch} = this.props;

        if (nextProps.request.status !== this.props.request.status) {
            dispatch(setStepNext())
        }
    }

    back = () => this.props.dispatch(setStepPrev())

    next = () => {
        const {dispatch} = this.props;

        this.setState({saving: true})

        axios.post(SEND_REQUEST_URL)
            .then(response => {

                dispatch(setRequestStatus(response.data.status))

            })
            .catch(error => {
                toast.error(<Toast text={parseResponse(error.response)} type="error"/>)
            })
            .finally(() => this.setState({saving: false}))
    }

    onLaboursRemove = (laboursToRemove) => {
        const {dispatch, labours} = this.props;

        return dispatch(laboursCalculate(differenceWith(labours, laboursToRemove, isEqual)));
    }

    onLabourRemove = (labour) => {
        const {dispatch, labours} = this.props;

        return dispatch(laboursCalculate(
            labours.filter(l => !(l.itemMpId === labour.itemMpId && l.korId === labour.korId))
                .filter(l => l.parentId !== labour.itemMpId)
        ))
    }

    onTireLabourRemove = (labour) => {
        const {dispatch, tiresNew, tireLabours, tireLaboursPredefined} = this.props;

        let list = tireLabours.filter(l => l.tire_labour_id !== labour.tire_labour_id);

        return dispatch(tireLaboursUpdate(
            tiresNew, tireLaboursPredefined.filter(l => list.find(item => l.id === item.tire_labour_id))
        ))
    }

    onTiresRemove = () => {
        const {dispatch, tireLaboursPredefined} = this.props;

        dispatch(tiresNewReset());
        dispatch(tiresProcessReset());

        return dispatch(tireLaboursUpdate(
            null, tireLaboursPredefined
        ))
    }

    onLaboursUpdate = (labour) => {
        const {dispatch, labours} = this.props;

        return dispatch(laboursCalculate(labours.map(l => l.itemMpId === labour.itemMpId ? labour : l), null, false));
    }

    render() {
        const {showRequestSpecification, agreeWithCalculation, saving} = this.state;
        const {t, title, labours, tiresNew, tireLabours, officialServices: {recommended}, requestSettings, recommendedOil} = this.props;

        return (
            <React.Fragment>

                <FormBeforeLeavePrompt />

                <Header/>

                <div className="container container--has-submit-container">

                    <div className="row">
                        <div className="col-sm-1">
                            <FormStepCounter short={true}/>
                        </div>
                        <div className="col-sm-11">

                            <div className="overview-step">

                                <h2>{t(title)}</h2>

                                <div className="labour-list">
                                    {labours.filter(l => l.type === LABOUR_TYPE_OFFICIAL).length > 0 &&
                                        <OfficialServiceDetails
                                            title={`${t('pages.form_steps.official_service.service_interval_title')}  ${recommended[OFFICIAL_SERVICE_SELECTED[recommended.selected] + 'Km']}km`}
                                            labours={labours.filter(l => l.type === LABOUR_TYPE_OFFICIAL)}
                                            onUpdate={this.onLaboursUpdate}
                                            onRemove={this.onLaboursRemove}
                                            remove={labours.length > 1}
                                            edit={true}
                                        />
                                    }

                                    {labours.filter(l => l.type === LABOUR_TYPE_REGULAR).map((labour, key) =>
                                        <LabourDetails key={key} labour={labour}
                                                       siblings={labours.filter(l => l.parentId === labour.itemMpId)}
                                                       remove={labours.length > 1 && labour.parentId === null}
                                                       edit={true}
                                                       onUpdate={this.onLaboursUpdate}
                                                       onRemove={this.onLabourRemove}/>)
                                    }

                                    {tireLabours.map((tireLabour) =>
                                        <TireLaboursDetails key={tireLabour.tire_labour_id} tireLabour={tireLabour}
                                                       remove={true}
                                                       onRemove={this.onTireLabourRemove}/>)
                                    }

                                    {tiresNew &&
                                        <TiresDetails tiresNew={tiresNew}
                                                       remove={true}
                                                       onRemove={this.onTiresRemove}/>
                                    }
                                </div>

                                <div className="total-price smaller-price">
                                    <div className="col-sm-6">{t('global.total')}:</div>
                                    <div className="col-sm-6">{roundCeil(offerPriceTotal(labours, tireLabours, tiresNew), true)} <span className="currency">{BaseCountry().currency}</span></div>
                                </div>
                                <div className="total-price smaller-price">
                                    <div className="col-sm-6">{t('global.vat')}:</div>
                                    <div className="col-sm-6">{roundCeil(sumPriceTotalVat(offerPriceTotal(labours, tireLabours, tiresNew)), true)} <span className="currency">{BaseCountry().currency}</span></div>
                                </div>
                                <div className="total-price">
                                    <div className="col-sm-6">{t('global.total_with_vat')}:</div>
                                    <div className="col-sm-6">{roundCeil(offerPriceTotalWithTax(labours, tireLabours, tiresNew), true)} <span className="currency">{BaseCountry().currency}</span></div>
                                </div>

                                <div className="mt-30 text-right">
                                    <LinkIcon
                                        icon="icon-eye"
                                        text={showRequestSpecification ? t('pages.form_steps.overview.hide_request_spec') : t('pages.form_steps.overview.show_request_spec')}
                                        right={true}
                                        onClick={() => this.setState({showRequestSpecification: !showRequestSpecification})}
                                    />
                                </div>

                                {showRequestSpecification &&
                                    <RequestSpecification
                                        labours={labours}
                                        tires={tiresNew}
                                        tireLabours={tireLabours}
                                        settings={requestSettings}
                                        recommendedOil={recommendedOil}/>
                                }

                                <AgreeWithCalculation
                                    onChange={(e) => this.setState({agreeWithCalculation: e.target.checked})}
                                    agree={agreeWithCalculation}
                                />

                                <FormStepSubmit
                                    onBack={this.back}
                                    onNext={this.next}
                                    disabled={!agreeWithCalculation || saving}
                                    loading={saving}
                                />
                            </div>

                        </div>
                    </div>

                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        request: state.request,
        labours: state.labours,
        tireLabours: state.tireLabours,
        tiresNew: state.tiresNew,
        tireLaboursPredefined: selectTireLaboursSelected(state),
        requestSettings: state.requestSettings,
        officialServices: state.officialServices,
        recommendedOil: state.recommendedOil,
    }
}

export default connect(mapStateToProps)(LaboursMiddleware(CarStepMiddleware(HasOfferToken(translate('translations')(OverviewStepContainer)))))
