import React, {Component} from 'react';
import {connect} from 'react-redux';
import {translate} from 'react-i18next';
import Header from "components/layout/Header/Header";
import "./TiresStepContainer.scss";
import {changeStepData, setStepNext, setStepPrev} from "../../../store/actions/step.actions";
import CarStepMiddleware from "../../../routes/middleware/FormSteps/CarStepMiddleware";
import {HasOfferToken} from "../../../routes/middleware/HasOfferToken";
import ListLoader from "../../../components/shared/ListLoader/ListLoader";
import FormStepCounter from "../../../components/FormStepCounter/FormStepCounter";
import FormStepSubmit from "../../../components/FormStepSubmit/FormStepSubmit";
import FormBeforeLeavePrompt from "../../../components/FormBeforeLeavePrompt/FormBeforeLeavePrompt";
import {FORM_STEP_TIRES} from "../../../store/consts/step.constants";
import LabourGroupItem from "./Components/LabourGroupItem";
import LabourTireProcess from "./Components/LabourTireProcess";
import {
    tireLaboursPredefinedGet, tireLaboursPredefinedRimSizeUpdate,
    tireLaboursPredefinedSelect
} from "../../../store/actions/tireLaboursPredefined.actions";
import {tiresProcessUpdate} from "../../../store/actions/tiresProcess.actions";
import {tireLaboursUpdate} from "../../../store/actions/tireLabours.actions";
import {
    selectTireLaboursList, selectTireLaboursPredefined,
    selectTireLaboursSelected
} from "../../../store/selectors/tireLaboursPredefined";
import {toast} from "react-toastify";
import Toast from "../../../components/shared/Toast/Toast";
import {parseResponse} from "../../../utils/common";
import {tiresEditPrepare} from "../../../store/actions/tires.actions";
import {selectTireProcessSelected} from "../../../store/selectors/tiresProcess";

class TiresStepContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            creating: false,
        }
    }

    componentDidMount() {
        const {dispatch, tireLaboursPredefined} = this.props;

        dispatch(changeStepData(FORM_STEP_TIRES));

        if (tireLaboursPredefined.length === 0) {
            dispatch(tireLaboursPredefinedGet());
        }
    }

    back = () => this.props.dispatch(setStepPrev())

    labourItemChange = (labourGroupId, checked) => {
        const {dispatch} = this.props;

        dispatch(tireLaboursPredefinedSelect(labourGroupId, checked));
    }

    labourItemPriceUpdate = (labourGroupId, labourId, axels) => {
        const {dispatch} = this.props;

        dispatch(tireLaboursPredefinedRimSizeUpdate(labourGroupId, labourId, axels));
    }

    updateTiresProcess = (path, value) => this.props.dispatch(tiresProcessUpdate(path, value))

    saveNewTires = (tires) => {
        const {dispatch} = this.props;

        dispatch(tiresProcessUpdate('tires', tires));
    }

    tiresEditPrepare = () => {
        const {dispatch, tiresProcess} = this.props;

        dispatch(tiresEditPrepare(tiresProcess.tires))
    };

    submit = () => {
        const {dispatch, tireProcessSelected, tireLaboursSelected} = this.props;

        this.setState({creating: true});

        dispatch(tireLaboursUpdate(tireProcessSelected, tireLaboursSelected))
            .then((response) => {

                dispatch(setStepNext())

            })
            .catch(error => {
                toast.error(<Toast text={parseResponse(error.response)} type="error"/>)
            })
            .finally(() => this.setState({creating: false}))

    }

    render() {
        const {t, title, tires, tiresProcess, tireLaboursList} = this.props;
        const {loading, creating} = this.state;

        return (
            <React.Fragment>

                <FormBeforeLeavePrompt/>

                <Header/>

                <div className="container container--has-submit-container">

                    <div className="row">
                        <div className="col-sm-1">
                            <FormStepCounter short={true}/>
                        </div>
                        <div className="col-sm-11">
                            {loading ? <ListLoader/> :
                                <div className="tires-step">
                                    <h2>{t(title)}</h2>

                                    <div className="tires-container">

                                        <div className="tire-labours-list">

                                            <LabourTireProcess
                                                tires={tires}
                                                tiresProcess={tiresProcess}
                                                tiresEditPrepare={this.tiresEditPrepare}
                                                onUpdateTiresProcess={this.updateTiresProcess}
                                                saveNewTires={this.saveNewTires}/>

                                            {tireLaboursList.map((labourGroup) => (
                                                <LabourGroupItem
                                                    key={labourGroup.id}
                                                    labourGroup={labourGroup}
                                                    tiresProcess={tiresProcess}
                                                    onChange={this.labourItemChange}
                                                    onPriceUpdate={this.labourItemPriceUpdate}
                                                />
                                            ))}
                                        </div>

                                    </div>

                                    {!loading &&
                                        <FormStepSubmit
                                            onBack={this.back}
                                            onNext={this.submit}
                                            disabled={creating}
                                            loading={creating}
                                        />
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        carStep: state.carStep,
        tires: state.tires,
        tiresProcess: state.tiresProcess,
        tireProcessSelected: selectTireProcessSelected(state),
        tireLaboursPredefined: selectTireLaboursPredefined(state),
        tireLaboursList: selectTireLaboursList(state),
        tireLaboursSelected: selectTireLaboursSelected(state),
    };
};

export default connect(mapStateToProps)(CarStepMiddleware(HasOfferToken(translate('translations')(TiresStepContainer))))
