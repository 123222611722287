import React, {Component} from 'react'
import {translate} from 'react-i18next'
import Button from "../../../../components/shared/Button/Button";
import {getValueFromArray} from "../../../../utils/car";
import axios from "../../../../app/config/axios";
import {toast} from "react-toastify";
import Toast from "../../../../components/shared/Toast/Toast";
import {parseResponse} from "../../../../utils/common";

const GENERATE_PDF_URL = 'garage/form/generate-file';

class Accepted extends Component {

    constructor(props) {
        super(props);

        this.state = {
            generating: false,
        }

        this.dropDown = [
            {id:1, name:'PDF', callback: () => this.generateFile('pdf')},
            {id:2, name: 'XML', callback: () => this.generateFile('xml')}
        ]
    }

    onSelect = (option) => {

        this.dropDown.find(item => item.id === option.id).callback();

    }

    generateFile = (type) => {
        this.setState({generating: true})

        axios.post(GENERATE_PDF_URL, {type})
            .then(response => {
                window.open(response.data.link);
            })
            .catch(error => {
                toast.error(<Toast text={parseResponse(error.response)} type="error"/>)
            })
            .finally(() => this.setState({generating: false}))
    }

    render() {
        const {t, seeRequestDetails, car, request} = this.props;
        const {generating} = this.state;

        const make = getValueFromArray(car.makes, 'id', car.make_id);
        const range = getValueFromArray(car.ranges, 'id', car.range_id);
        const type = getValueFromArray(car.types, 'id', car.type_id);

        return (
            <React.Fragment>

                <div className="review-status">
                    <h2>{t('pages.form_steps.review.accepted.title')}</h2>

                    <h4>{t('pages.form_steps.review.accepted.subtitle', {car: `${make.name} ${range.name} ${type.nameShort}`})}</h4>
                    <h4>{t('pages.request_details.request_id')} {request.auth_number}</h4>

                    <div className="review-status__icon review-status__icon--accepted">
                        <i className="icon-checkbox" />
                    </div>
                    <div className="review-status__status">{t('pages.form_steps.review.accepted.status')}</div>

                    {/*<p className="review-status__desc">{t('pages.form_steps.review.accepted.desc')}</p>*/}

                    <div className="buttons">
                        <Button size="md" type="primary" ddList={this.dropDown} disabled={generating} loading={generating}
                                extraClass="btn-primary--outline" onDDSelect={this.onSelect}>
                            {t('pages.form_steps.review.accepted.print')}
                        </Button>
                        <Button size="md" type="primary" onClick={seeRequestDetails}
                                disabled={generating} extraClass="btn-primary--outline">
                            {t('pages.form_steps.review.accepted.see_details')}
                        </Button>
                    </div>
                </div>

            </React.Fragment>
        )
    }
}

export default translate('translations')(Accepted)
