/* eslint-disable */
import React from 'react'
import {
    TIRES_AXEL_FRONT,
    TIRES_AXEL_REAR, TIRES_SEASON_ALL_SEASON, TIRES_SEASON_NORDIC,
    TIRES_SEASON_SUMMER,
    TIRES_SEASON_WINTER
} from "../../../store/consts/tires.constants";


export default {
    translations: {
        links: {
            home: 'Home',
            services: 'Services',
            garage: 'Garage',
        },
        titles: {
            login: 'Anmelden',
            password_reset: 'Passwort zurücksetzen',
            password_reset_new: 'Neue Passwortaktivierung',
            pick_garage: 'Garage wählen',

            home: 'Fleet Access',

            settings: 'Einstellungen',
            user_profile: 'Profil',

            requests: 'Aufträge',
            request_details: 'Auftragdetails',

            form_steps: {
                car: 'Fahrzeugdaten',
                official_service: 'Service und Inspektion',
                labours: 'Weitere Wartungsarbeiten',
                tires: 'Reifen',
                overview: 'Auftragsübersicht',
                review: 'Validierung des Auftrags',
            }
        },
        global: {
            alt: {
                'request-send-success-icon': 'Erfolgssymbol',
            },
            tabs: {
                pending: 'Anstehend',
                other: 'Andere',
            },
            or: 'oder',
            cancel: 'Abbrechen',
            back: 'Zurück',
            more: 'mehr',
            currency: 'EUR',
            tax: 'USt.',
            total: 'Total',
            total_with_vat: 'Total inkl USt',
            total_with_discount: 'Total inkl Rabatt',
            discount: 'Rabatt',
            files: 'Bilder',
            service: 'Service',
            part_number: 'Part number:', //TODO: Transalte
            to: 'zu',
            km: 'km',
            loading: 'Wird geladen',
            garage_reinitialize: 'Garage wechseln...',
            save: 'Speichern',
            update: 'Aktualisieren',
            edit: 'Bearbeiten',
            remove: 'Entfernen',
            vat: 'USt',
            yes: 'Ja',
            no: 'Nein',
            upload: 'Hochladen',
            car: {
                make: 'Marke',
                range: 'Modell',
                type: 'Ausführung',
                go_back: 'Bearbeiten',
                year: 'Baujahr',
                licence_plate: 'Nummernschild',
                update_info: 'Fahrzeuginformationen aktualisieren',
                edit: 'Bearbeiten',
                mileage: 'km-Stand',
            },
            official_service: {
                title: 'EMPFEHLUNG FÜR DEIN AUTO',
                description:
                    'Dank der durch dich zur Verfügung gestellten Daten, sowie den Herstellerangaben von {{carBrand}}, können wir dir einen personalisierten Service für dein Fahrzeug empfehlen.',
                includes: 'BEINHALTET',
            },
            preselected_service: {
                title: 'Ausgewählte Services: ',
            },
            additional_service: {
                open: 'Zusätzliche Services',
                title: 'Reparatur',
                description: 'Wähle weitere Services aus um die Suche zu verfeinern.',
                uncheck_all: 'Alle abwählen',
                save: 'Speichern',
            },
            msg_title: {
                error: 'Fehlermeldung',
                info: 'Info Nachricht',
            },
            undo: 'Rückgängig machen',
            undo_deleted: '{{num}} gelöscht',
            you_sure: 'Sind Sie sicher?',
            months: {
                0: 'Januar',
                1: 'Februar',
                2: 'März',
                3: 'April',
                4: 'Mai',
                5: 'Juni',
                6: 'Juli',
                7: 'August',
                8: 'September',
                9: 'Oktober',
                10: 'November',
                11: 'Dezember',
            },
            agreement: 'Ich akzeptiere die',
            terms_and_conditions: 'AGB',
            privacy_policy: 'Datenschutzerklärung',
            no_data: 'Keine Daten',
            optional: 'optional',
            dont_know_lp: 'Nummernschild unbekannt?',
            form_leave_prompt: 'Sind Sie sicher?',
        },
        form: {
            placeholder: {
                contract_number: 'Vertragsnummer',
                make: 'Marke',
                range: 'Modell',
                type: 'Fahrzeugausführung',
                gear: 'Getriebeart',
                first_registration: '2019',
                qual_md_list: 'Fahrzeugeigenschaften',
                qual_lt_list: 'Fahrzeugaufbau',
                username: 'Handynr. / E-Mail eingeben / Benutzername',
                username2: 'Benutzername',
                year: '2019',
                km_stand: 'Kilometerstand',
                first_name: 'Vorname',
                last_name: 'Nachname',
                mechanic_type: 'Type',
                email: 'E-Mail',
                phone: 'Telefonnummer',
                password: 'Passwort',
                password_confirmation: 'Passwort bestätigen',
                city: 'Stadt',
                color: 'Farbe',
                canton: 'ZH',
                plate_number: '18 341',
                default: {
                    select: 'Bitte auswählen',
                },
                user_activity: 'Status',
                date: 'Datum auswählen',
                morning: 'Morgen',
                afternoon: 'Nachmittag',
                car_number: {
                    vin_swiss: 'TSN oder VIN',
                    vin_kba: 'KBA-Nummer oder VIN',
                    vin: 'VIN',
                    swiss: 'TSN',
                    kba: 'KBA-Nummer',
                    default: 'VIN',
                },
                user_type: 'Berechtigung',
                mileage: 'km-Stand',
                leasing: 'Flottenbesitzer',
            },
            label: {
                contract_number: 'Vertragsnummer',
                licence_plate: 'Nummernschild',
                make: 'Marke',
                range: 'Modell',
                type: 'Ausführung',
                gear: 'Getriebeart',
                first_registration: 'Jahr der Erstzulassung',
                qual_md_list: 'Fahrzeugeigenschaften',
                qual_lt_list: 'Fahrzeugaufbau',
                username: 'Handynr. / E-Mail / Benutzername',
                username2: 'Benutzername',
                year: 'Jahr der Erstzulassung',
                km_stand: 'Kilometerstand',
                first_name: 'Vorname',
                last_name: 'Nachname',
                phone: 'Telefonnummer',
                email: 'E-Mail',
                password: 'Passwort',
                password_confirmation: 'Passwort bestätigen',
                date: 'Datum',
                availability: 'Verfügbarkeit',
                bookFor: 'Buchen für',
                book: 'Anfragen',
                city: 'Stadt',
                color: 'Farbe:',
                car_number: {
                    vin_swiss: 'TSN oder VIN',
                    vin_kba: 'KBA-Nummer oder VIN',
                    vin: 'VIN',
                    swiss: 'TSN',
                    kba: 'KBA-Nummer',
                    default: 'VIN',
                },
                user_type: 'Konto Typ',
                mileage: 'km-Stand',
                leasing: 'Flottenbesitzer',
            },
            button: {
                next: 'Weiter',
                confirm: 'Bestätigen',
                submit: 'Weiter',
            },
            select: {
                no_result: 'Kein Ergebnis gefunden',
            },
        },
        validations: {
            label: {
                contract_number: 'Vertragsnummer',
                make: 'Marke',
                range: 'Modell',
                type: 'Version',
                first_registration: 'Jahr der Erstzulassung',
                gear: 'Getriebeart',
                qual_md_list: 'Fahrzeugeigenschaften',
                qual_lt_list: 'Fahrzeugaufbau',
                km_stand: 'Kilometerstand',
                car_number: 'Autokennzeichen',
                avatar: 'Avatar',
                username: 'Handynr. / E-Mail / Benutzername',
                username2: 'Benutzername',
                firstname: 'Vorname',
                lastname: 'Nachname',
                phone: 'Telefonnummer',
                email: 'E-Mail',
                password: 'Passwort',
                password_match: 'Die Passwörter müssen übereinstimmen',
                user_type: 'Konto Typ',

                zip_not_found: 'Keine Ergebnisse mit PLZ {{name}}',
                zip_not_valid: 'Bitte wähle eine gültige PLZ.',
                zip_required: 'PLZ ist obligatorisch.',
                zip_must_be_number: 'Bitte gib nur Zahlen ein.',
                leasing: 'Flottenbesitzer',
                mileage: 'km-Stand',
            },
            default: {
                mixed: {
                    required: '${label} fehlt',
                },
                string: {
                    email: 'Bitte E-Mail prüfen',
                    min: 'Zu kurzer Wert',
                    max: 'Zu langer Wert',
                    test: '${label} zustimmen',
                },
                boolean: {
                    required: '${label} zustimmen',
                },
            },
            licence_plate: {
                canton: {
                    valid: 'Kanton', //Gültiger
                    nonValid: 'Kanton', //Ungültiger
                },
                number: {
                    valid: 'Nummer', //Gültige
                    nonValid: 'Nummer', //Ungültige
                },
                required: 'Gültiges Nummernschild ist erforderlich',
                cantonAsString: 'Kanton darf nur Buchstaben enthalten',
            },
            car_number: {
                invalid_format: 'Ungültiges Format für Typenscheinnummer',
            },
        },
        header: {
            profile_settings: 'Profileinstellungen',
            switch_garage: 'Garage wechseln',
            settings: 'Garageneinstellungen',
            logout: 'Abmelden',
            terms_and_conditions: 'AGB',
            privacy_policy: 'Datenschutzerklärung',
            requests: 'Aufträge',
        },
        confirm_popup: {
            ok: 'OK',
            cancel: 'Abbrechen',
        },
        user: {
            activity: {
                active: 'Aktiviert',
                inactive: 'Deaktiviert',
            },
            roles: {
                manager: 'Manager',
                mechanic: 'Mechaniker',
                mechanic_advanced: 'Mechaniker mit Kundenkontakt',
                supervisor: 'Supervisor',
            },
        },
        pages: {
            home: {
                title: 'Fleet Access',
                new_requests: 'Neue Aufträge:',
            },
            login: {
                title: 'Anmelden',
                submit: 'Anmelden',
                forget_password: 'Passwort vergessen?',
            },
            password_reset: {
                title: 'Passwort zurücksetzen',
                tip: 'Geben Sie Ihre Handynummer oder E-Mail-Adresse ein, um das Passwort zurückzusetzen.',
                submit: 'Passwort zurücksetzen',
                cancel: 'Abbrechen',
            },
            password_reset_new: {
                title: 'Neue Passwortaktivierung',
                tip: 'Geben Sie Ihr neues Passwort in die folgenden Felder ein.',
                submit: 'Passwort bestätigen',
            },
            pick_garage: {
                title: 'Garage wählen',
                tip: 'Bitte wählen Sie eine Werkstatt, als die Sie sich anmelden möchten',
            },
            settings: {
                title: 'Einstellungen',
                section_tabs: {
                    change_details: 'Details ändern',
                    phones: 'Kontaktnummer (Nummer wird dem Kunden angezeigt.)',
                    communication: 'Benachrichtigungen',
                },
                section_form: {
                    label: {
                        address: 'Adresse:',
                        web: 'Webseite:',
                        zip_id: 'PLZ:',
                        logo: 'Logo:',
                        notification_phone: 'Handynummer:',
                        notification_email: 'E-Mail:',
                    },
                    placeholder: {
                        address: 'Adresse:',
                        web: 'Webseite:',
                        zip_id: 'PLZ:',
                        logo: 'Logo:',
                        email: 'E-Mail eingeben',
                        phone: 'Handynummer eingeben',
                    },
                    logo_change: 'Ändern',
                    logo_upload: 'Logo hochladen',
                    avatar_upload: 'Avatar hochladen',
                    avatar_change: 'Avatar wechseln',
                    submit_user_details: 'Speichern',
                    notification_detail:
                        'Listen Sie alle zugeordneten Empfänger auf, um Benachrichtigungen zu erhalten.',
                    phones_detail: 'Nummer wird dem Kunden angezeigt.',
                    add_new_phone: 'Neue Nummer hinzufügen',

                    file_size: 'Datei zu groß',
                    file_format: 'Nicht unterstütztes Format',
                    one_of_required: 'Handynummer oder E-Mail erforderlich',

                    communication: {
                        nexmo_multiple: 'SMS',
                        mail: 'E-Mail',
                        push_notification: 'Push',
                    },
                    communication_required: 'Es ist mindestens ein Benachrichtigungskanal erforderlich',
                },
                section_popup: {
                    header_remove: 'Möchten Sie den Empfänger wirklich entfernen?',
                    header_add: 'Neuen Empfänger hinzufügen',
                    header_update: 'Aktualisieren',
                    remove: 'Entfernen',
                },
            },
            user_profile: {
                title: 'Benutzerprofil',
                section_tabs: {
                    default_language: 'Wählen Sie die Standardsprache',
                    change_password: 'Passwort ändern',
                    change_details: 'Details ändern',
                },
                section_form: {
                    label: {
                        current_password: 'Altes Passwort',
                        new_password: 'Neues Passwort',
                        repeat_password: 'Neues Passwort bestätigen',
                        notification_phone: 'Handynummer:',
                        notification_email: 'E-Mail:',
                        first_name: 'Vorname',
                        last_name: 'Name',
                        email: 'E-Mail',
                        phone: 'Handynummer',
                        username: 'Benutzername',
                    },
                    placeholder: {
                        current_password: 'Altes Passwort eingeben',
                        new_password: 'Neues Passwort eingeben',
                        repeat_password: 'Neues Passwort bestätigen',
                        notification_email: 'Neue E-Mail eingeben',
                        notification_phone: 'Neues Handynummer eingeben',
                        first_name: 'Vorname eingeben',
                        last_name: 'Name eingeben',
                        email: 'E-Mail eingeben',
                        phone: 'Handynummer eingeben',
                        username: 'Benutzername',
                    },
                    avatar: {
                        change: 'bearbeiten',
                        crop: {
                            select: 'Foto hochladen',
                            zoom: 'Zoomieren',
                            rotate: 'Rotieren',
                            save: 'Speichern',
                            cancel: 'Abbrechen',
                            title: 'Bild zuschneiden',
                        },
                    },
                    submit_new_password: 'Speichern',
                    submit_user_details: 'Speichern',

                    file_size: 'Datei zu groß',
                    file_format: 'Nicht unterstütztes Format',
                },
            },

            form_steps: {
                car: {
                    method_title_lp: 'Suche über Nummernschild',
                    method_title_swiss: 'Suche nach Typenscheinnummer',
                    method_title_multiple: 'Wählen Sie die Autofarbe',
                    method_title_manual: 'Zusätzliche Information',
                    method_title_manual_2: 'Fahrzeug gefunden',

                    sourced_success: 'Wir haben erfolgreich Fahrzeuginformationen beschafft.',

                    car_number: {
                        vin_swiss: 'Wissen Sie das Vin oder TSN nicht?',
                        vin_kba: 'Wissen Sie das Vin oder KBA nicht?',
                        vin: 'Wissen Sie das Vin nicht?',
                        swiss: 'Wissen Sie das TSN nicht?',
                        kba: 'Wissen Sie das KBA nicht?',
                        default: 'Wissen Sie das Vin oder TSN nicht?',
                    },

                    swiss: {
                        manual: 'Geben Sie die Details manuell ein',
                    },
                },
                official_service: {
                    title: 'Service gemäss Herstellervorgaben',
                    total_official: 'Total inkl USt',
                    select_other_services: 'Wählen Sie mehr Services aus:',
                    submit: 'Weiter',
                    or_more: 'oder mehr',
                    no_recommended: 'Zum ausgewählten Fahrzeug konnten wir keinen Servicevorschlag finden.',
                    info: {
                        ok: 'OK',
                    },
                    service_interval_title: 'Service bei ',
                    show_more: 'mehr',
                    show_less: 'weniger',
                    included_in_official: 'Im Service enthalten',
                },
                labours: {
                    title: 'Zusätzliche Services',
                    search: {
                        placeholder: 'Zusätzliche Services hinzufügen',
                    },
                    submit: 'Speichern',
                    no_result: 'Keine Arbeit gefunden. Bitte versuchen Sie es erneut oder suchen Sie mit einem anderen Namen.',
                    load_more: 'Mehr laden',
                    not_available: 'Arbeit nicht verfügbar',
                },
                tires: {
                    title: 'Reifen',

                    axel: {
                        [TIRES_AXEL_FRONT]: 'Vorne',
                        [TIRES_AXEL_REAR]: 'Hinten',
                        front: 'Vorne',
                        rear: 'Hinten',
                        both: 'V + H',
                    },

                    season: {
                        [TIRES_SEASON_SUMMER]: 'Sommer',
                        [TIRES_SEASON_WINTER]: 'Winter',
                        [TIRES_SEASON_ALL_SEASON]: 'All Season',
                        [TIRES_SEASON_NORDIC]: 'Nordic',
                    },

                    filter_title_both: 'Reifen vorne + hinten',
                    filter_title_front: 'Vorderreifen',
                    filter_title_rear: 'Hinterreifen',

                    filter_index_all: 'Alle Index',

                    package_name: 'Reifenwechsel inkl. Auswuchten ',

                    ean: 'EAN',
                    sap: 'SAP',

                    label: {
                        axel: 'Achse',
                        wide: 'Breit',
                        depth: 'Tiefe',
                        diameter: 'Durchmesser',

                        season: 'Saison',
                        index: 'Index',
                        brand: 'Marke',
                        profile: 'Reifenprofil',
                        dimensions: 'Grösse',
                        run_flat: 'Run flat',

                        front_axel: 'Vorderachse',
                        rear_axel: 'Hinterachse',

                        same_dimension: 'Gleiche Abmessungen',

                    },

                    labours: {
                        new_tires: 'Neue Reifen',

                        rim_size: {
                            title: 'Auswahl Felgengrösse und Achse für {{labour}}',
                            select: 'Felgengrösse',
                        },
                    },

                    process: {
                        title: 'Reifen auswählen',
                    },

                    header: {
                        brand: 'Marke',
                        index: 'Index',
                        price: 'Preis',
                    },

                    no_tires: 'Keine Reifen für dieses Kriterium',
                },
                overview: {
                    title: 'Überblick',
                    show_request_spec: 'Anfrage Spezifikation anzeigen',
                    hide_request_spec: 'Anfrage Spezifikation ausblenden',
                    labour_remove_title: 'Sind Sie sicher, dass Sie die folgenden Arbeit entfernen möchten?',
                    request_specification: {
                        title: 'Anfrage Spezifikation',
                        parts_title: 'Teile',
                        tires_title: 'Reifen',
                        tire_labours_title: 'Reifen Arbeit',
                        serial_number: 'Teilenummer',
                        part_name: 'Teile',
                        quantity: 'Menge',
                        oil_title: 'Ölspezifikation',
                        price: 'Preis',
                        parts_discount: 'Rabatt auf Teile',
                        brakes_discount: 'Rabatt auf Bremsen',
                        works_title: 'Arbeitsstunden',
                        custom_labours: 'Individuelle Arbeiten',
                        works_name: 'Service',
                        time: 'Zeit',
                        calc_time: 'Berechnete Zeit',
                        hourly_price: 'EUR / h',
                        recommended_oil: 'Herstellerempfehlung',

                        tire_labours: {
                            front_size: 'Grösse Vorne',
                            front_price: 'Preis Vorne',
                            rear_size: 'Grösse Hinten',
                            rear_price: 'Preis Hinten',
                        },
                    },

                    agree_with_calculation: 'Ich bestätige hiermit, dass ich die Arbeitswerte und Teile überprüft habe und mit der kalkulierten Auftragsanfrage einverstanden bin.\n',
                },
                review: {
                    in_progress: {
                        title: 'Ihre Anfrage wird derzeit geprüft',
                        subtitle: 'Die Genehmigung kann einen Moment dauern...',
                        desc: 'Alternativ können Sie mit anderen Anfragen fortfahren und wir werden Sie benachrichtigen, sobald diese Anfrage beantwortet wurde. Sie können den Anforderungsstatus jederzeit in der Auftragsliste im Menu überprüfen.',
                        cancel: 'Anfrage stornieren',
                        all_requests: 'Alle Anfragen',
                        cancel_request_title: 'Sind Sie sicher, dass Sie diese Anfrage stornieren möchten? Es ist eine nicht umkehrbare Aktion',
                    },
                    accepted: {
                        title: 'Anfrage akzeptiert',
                        subtitle: 'Ihre Anfrage für {{car}} wurde akzeptiert.',
                        desc: 'Anfrage akzeptiert beschreibung',
                        cancel: 'Anfrage stornieren',
                        status: 'Akzeptiert',
                        see_details: 'Siehe Anforderungsdetails',
                        print: 'PDF drucken / Exportieren nach...',
                    },
                    rejected: {
                        title: 'Anfrage abgelehnt',
                        subtitle: 'Ihre Anfrage für {{car}} wurde abgelehnt.',
                        reason: 'Grund:',
                        status: 'Abgelehnt',
                        archive: 'Anfrage Archivieren',
                        edit: 'Anfrage bearbeiten',
                        edit_confirm: 'Sind Sie sicher, dass Sie diese Anfrage bearbeiten möchten? Es ist eine nicht umkehrbare Aktion.',
                        archive_confirm: 'Sind Sie sicher, dass Sie diese Anfrage archivieren möchten? Es ist eine nicht umkehrbare Aktion.',
                    },
                }
            },
            requests: {
                title: 'Alle Anfragen',
                filter_label: 'Nach Status filtern',
                no_result: 'Es gibt keine Ergebnisse',
                pending_status: 'Anstehend',
                approved_status: 'Genehmigt',
                denied_status: 'Verweigert',
                archived_status: 'Archiviert',
                all: 'Alle',
                table: {
                    status: 'Status',
                    leasing_provider: 'Flottenbesitzer',
                    car_info: 'Fahrzeuginformationen',
                    request_details: 'Anfragedetails',
                    date: 'Datum',
                    pdf: 'PDF',
                    status_1: 'Anstehend',
                    status_4: 'Storniert',
                    status_2: 'Genehmigt',
                    status_3: 'Verweigert',
                },
            },

            request_details: {
                title: 'Anfragedetails',
                upload_invoice_label: 'Rechnung hochladen',
                export_xml: 'XML exportieren',
                export_pdf: 'PDF Herunterladen',
                edit: 'Anfrage bearbeiten',
                edit_desc: 'Sind Sie sicher, dass Sie diese Anfrage bearbeiten möchten? Es ist eine nicht umkehrbare Aktion.',
                cancel: 'Anfrage stornieren',
                cancel_desc: 'Sind Sie sicher, dass Sie diese Anfrage stornieren möchten? Es ist eine nicht umkehrbare Aktion.',
                archive: 'Anfrage Archivieren',
                archive_desc: 'Sind Sie sicher, dass Sie diese Anfrage archivieren möchten? Es ist eine nicht umkehrbare Aktion.',
                statuses: {
                    1: 'Anstehend',
                    2: 'Genehmigt',
                    3: 'Verweigert',
                    4: 'Storniert',
                },
                request_id: 'Freigabenummer:',
                date: 'Datum:',
                status: 'Status:',
                archived: 'Archiviert',
                denial_reason: 'Grund für die Ablehnung',
                request_overview: 'Übersicht',
                add_new_service: 'Anfrage ändern',
                car_details: 'Fahrzeugdetails',
                car_model: 'Automodell',
                car_range: 'Modell',
                car_type: 'Ausführung',
                car_vin_swiss: 'VIN',
                car_mileage: 'Km-Stand',
                car_first_reg: '1. inverkehrsetzung',
                car_leasing: 'Flottenbesitzer',

                upload_invoice: {
                    file_type: 'Erkannte Dateitypen sind {{format}}',
                    file_size: 'Maximale Dateigröße {{size}}',
                },
                invoice: {
                    title: 'Rechnung',
                    download_invoice: 'Rechnung',
                    pending: ' (in Prüfung)',
                    uploaded: 'Hochgeladen',
                },
                drag_and_drop: {
                    title: 'Drag and drop',
                    or: 'oder',
                    upload_from: 'laden Sie die Datei von Ihrem Gerät hoch.',
                },

            },
        },
        toast: {
            success: 'Danke',
            error: 'Oops...',
            info: 'Info',
            warning: 'Warnung',
        },
        language: {
            en: 'Englisch',
            de: 'Deutsch',
            fr: 'Französisch',
        },
        form_steps: {
            1: 'Fahrzeugdaten',
            2: 'Service und Inspektion',
            3: 'Reifen',
            4: 'Weitere Wartungsarbeiten',
            5: 'Auftragsübersicht',
            6: 'Validierung des Auftrags',
        },
        footer: {
            copyright: 'Copyright by Stratos Technologies AG 2021',
            terms: 'AGB',
            privacy: 'Datenschutzerklärung',
        },
    },

}
