import {
    GARAGE_SET,
    GARAGE_REMOVE,
    GARAGE_UPDATE,
    GARAGE_TERMS_AND_CONDITIONS_ACCEPT,
} from '../actions/garage.actions'
import garage from '../models/garage'

export default function(state = garage, action) {
    switch (action.type) {
        case GARAGE_SET:
            return {
                ...state,
                logged: true,
                ...action.payload.garage,
            }

        case GARAGE_UPDATE:
            return {
                ...state,
                ...action.payload.data,
            }

        case GARAGE_REMOVE:
            return garage

        case GARAGE_TERMS_AND_CONDITIONS_ACCEPT:
            return {
                ...state,
                terms_and_conditions: {
                    ...state.terms_and_conditions, accepted: true
                },
            }

        default:
            return state
    }
}
