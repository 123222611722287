import React, {Component} from 'react'
import {translate} from 'react-i18next'
import Button from "../../../../components/shared/Button/Button";
import axios from "../../../../app/config/axios";
import {toast} from "react-toastify";
import Toast from "../../../../components/shared/Toast/Toast";
import {parseResponse} from "../../../../utils/common";
import {getValueFromArray} from "../../../../utils/car";
import Confirm from "../../../../components/shared/Confirm/Confirm";
import {fetchEditRequest} from "../../../../store/actions/editRequest.actions";

const ARCHIVE_REQUEST_URL = 'garage/form/archive-request'

class Rejected extends Component {

    state = {
        archiving: false,
        confirmArchive: false,
        confirmEdit: false,
        editing: false,
    }

    onArchive = () => {
        const {onArchive} = this.props;

        this.setState({archiving: true})

        axios.post(ARCHIVE_REQUEST_URL)
            .then(response => {
                onArchive()
            })
            .catch(error => {
                toast.error(<Toast text={parseResponse(error.response)} type="error"/>)
            })
            .finally(() => this.setState({archiving: false}))
    }

    onEdit = () => {
        const {dispatch, onEdit, car} = this.props;

        this.setState({editing: true})

        dispatch(fetchEditRequest(car.token))
            .then(() => {
                this.setState({confirmEdit: false, editing: false})
                onEdit()
            })
            .catch((error) => toast.error(<Toast text={parseResponse(error.response)} type="error"/>))
    }
    getReason = (reason) => {
        return {__html: reason};
    }

    render() {
        const {t, request, car} = this.props;
        const {archiving, confirmArchive, confirmEdit, editing} = this.state;

        const make = getValueFromArray(car.makes, 'id', car.make_id);
        const range = getValueFromArray(car.ranges, 'id', car.range_id);
        const type = getValueFromArray(car.types, 'id', car.type_id);

        return (
            <React.Fragment>

                <div className="review-status">
                    <h2>{t('pages.form_steps.review.rejected.title')}</h2>

                    <h4>{t('pages.form_steps.review.rejected.subtitle', {car: `${make.name} ${range.name} ${type.nameShort}`})}</h4>

                    <div className="review-status__icon review-status__icon--rejected">
                        <i className="icon-close"/>
                    </div>
                    <div className="review-status__status">{t('pages.form_steps.review.rejected.status')}</div>

                    <p className="review-status__reason">{t('pages.form_steps.review.rejected.reason')}</p>
                    <div className="review-status__desc"
                         dangerouslySetInnerHTML={this.getReason(request.reject_reason)}/>

                    <div className="buttons">
                        <Button size="md" type="primary" onClick={() => this.setState({confirmArchive: true})}
                                extraClass="btn-primary--outline"
                                disabled={archiving} loading={archiving}>
                            {t('pages.form_steps.review.rejected.archive')}
                        </Button>
                        <Button size="md" type="primary" onClick={() => this.setState({confirmEdit: true})}
                                disabled={archiving}>
                            {t('pages.form_steps.review.rejected.edit')}
                        </Button>
                    </div>
                </div>

                {confirmArchive &&
                <Confirm
                    title={t('pages.form_steps.review.rejected.archive')}
                    acceptText={t('pages.form_steps.review.rejected.archive')}
                    visible={!!confirmArchive}
                    accept={this.onArchive}
                    cancel={() => this.setState({confirmArchive: false})}
                    loading={archiving}
                >
                    <div className="mv-30">{t('pages.form_steps.review.rejected.archive_confirm')}</div>
                </Confirm>
                }

                {confirmEdit &&
                <Confirm
                    title={t('pages.form_steps.review.rejected.edit')}
                    acceptText={t('pages.form_steps.review.rejected.edit')}
                    visible={!!confirmEdit}
                    accept={this.onEdit}
                    cancel={() => this.setState({confirmEdit: false})}
                    loading={editing}
                >
                    <div className="mv-30">{t('pages.form_steps.review.rejected.edit_confirm')}</div>
                </Confirm>
                }

            </React.Fragment>
        )
    }
}

export default translate('translations')(Rejected)
