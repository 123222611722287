import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'core-js/features/array/find'
import 'core-js/features/array/find-index'
import 'core-js/features/string/repeat'
import 'url-search-params-polyfill'
import 'unorm'
import './canvas-toBlob'
import 'proxy-polyfill'

if (!String.prototype.includes) {
    // eslint-disable-next-line no-extend-native
    String.prototype.includes = function(search, start) {
        if (typeof start !== 'number') {
            start = 0
        }

        if (start + search.length > this.length) {
            return false
        } else {
            return this.indexOf(search, start) !== -1
        }
    }
}

if (!Array.prototype.includes) {
    // eslint-disable-next-line no-extend-native
    Object.defineProperty(Array.prototype, 'includes', {
        value: function(valueToFind, fromIndex) {
            if (this == null) {
                throw new TypeError('"this" is null or not defined')
            }

            var o = Object(this)

            var len = o.length >>> 0

            if (len === 0) {
                return false
            }

            var n = fromIndex | 0

            var k = Math.max(n >= 0 ? n : len - Math.abs(n), 0)

            function sameValueZero(x, y) {
                return x === y || (typeof x === 'number' && typeof y === 'number' && isNaN(x) && isNaN(y))
            }

            while (k < len) {
                if (sameValueZero(o[k], valueToFind)) {
                    return true
                }
                k++
            }

            return false
        },
    })
}

if (!Array.prototype.includes) {
    // eslint-disable-next-line no-extend-native
    Object.defineProperty(Array.prototype, 'includes', {
        value: function(valueToFind, fromIndex) {
            if (this == null) {
                throw new TypeError('"this" is null or not defined')
            }
            var o = Object(this)

            var len = o.length >>> 0

            if (len === 0) {
                return false
            }

            var n = fromIndex | 0

            var k = Math.max(n >= 0 ? n : len - Math.abs(n), 0)

            function sameValueZero(x, y) {
                return x === y || (typeof x === 'number' && typeof y === 'number' && isNaN(x) && isNaN(y))
            }

            while (k < len) {
                if (sameValueZero(o[k], valueToFind)) {
                    return true
                }
                k++
            }

            return false
        },
    })
}

if (!Object.assign) {
    Object.defineProperty(Object, 'assign', {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function(target) {
            if (target === undefined || target === null) {
                throw new TypeError('Cannot convert first argument to object')
            }

            var to = Object(target)
            for (var i = 1; i < arguments.length; i++) {
                var nextSource = arguments[i]
                if (nextSource === undefined || nextSource === null) {
                    continue
                }
                nextSource = Object(nextSource)

                var keysArray = Object.keys(Object(nextSource))
                for (var nextIndex = 0, len = keysArray.length; nextIndex < len; nextIndex++) {
                    var nextKey = keysArray[nextIndex]
                    var desc = Object.getOwnPropertyDescriptor(nextSource, nextKey)
                    if (desc !== undefined && desc.enumerable) {
                        to[nextKey] = nextSource[nextKey]
                    }
                }
            }
            return to
        },
    })
}

if (!String.prototype.parseFloat) {
    String.prototype.parseFloat = function() {
        let parse = parseFloat(this);

        if (typeof parse !== 'number' || isNaN(parse)) {
            return null;
        }

        return parse;
    }
}
