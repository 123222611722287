import React, {Component} from 'react';
import {translate} from "react-i18next";
import {Prompt, matchPath} from "react-router-dom";
import {formStepRoutes} from "../../routes/garage";

class FormBeforeLeavePrompt extends Component {

    render() {
        const {t} = this.props;

        return (
            <Prompt message={(location) => {
                return formStepRoutes.find((route) => matchPath(location.pathname, route)) ? true : t('global.form_leave_prompt')
            }} when={true}/>
        )
    }
}

export default translate('translations')(FormBeforeLeavePrompt);
