export const persistReset = state => {
    const models = [
        'loader',
        'user',
        'garage',
        'languages',
        'country',
    ]
    let store = {}

    models.forEach(model => {
        store[model] = state[model]
    })

    return store
}

export default persistReset
