import React, {Component} from 'react';
import './FormStepSubmit.scss';
import {translate} from "react-i18next";
import LinkIcon from "../shared/LinkIcon/LinkIcon";
import Button from "../shared/Button/Button";

class FormStepSubmit extends Component {

    render() {
        const {t, onBack, onNext, disabled, loading} = this.props;

        return (
            <div className="form-step-submit">
                <div className="row mt-30">
                    <div className="col-xs-6">
                        <LinkIcon icon={"icon-left"} left={true} text={t('global.back')} extraClass="back-link"
                                  onClick={onBack}/>
                    </div>
                    <div className="col-xs-6 text-right">
                        <Button size="sm" type="primary" disabled={disabled} onClick={onNext} loading={loading}>
                            {t('form.button.next')}</Button>
                    </div>
                </div>
            </div>
        )
    }
}

export default translate('translations')(FormStepSubmit);
