export const IN_PROGRESS_STATUS = 1;
export const ACCEPTED_STATUS = 2;
export const REJECTED_STATUS = 3;
export const CANCEL_STATUS = 4;

export const canSendInvoice = (status) => {
    return [ACCEPTED_STATUS].indexOf(status) !== -1
}

export default {
    IN_PROGRESS_STATUS,
    ACCEPTED_STATUS,
    REJECTED_STATUS,
    CANCEL_STATUS,
}
