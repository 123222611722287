import pages from "../../app/consts/routes";

export const FORM_STEP_CAR = 1;
export const FORM_STEP_OFFICIAL_SERVICE = 2;
export const FORM_STEP_TIRES = 3;
export const FORM_STEP_LABOURS = 4;
export const FORM_STEP_OVERVIEW = 5;
export const FORM_STEP_REVIEW = 6;

export const FORM_STEPS = [
    FORM_STEP_CAR,
    FORM_STEP_OFFICIAL_SERVICE,
    FORM_STEP_TIRES,
    FORM_STEP_LABOURS,
    FORM_STEP_OVERVIEW,
    FORM_STEP_REVIEW,
];

export const STEP_ROUTES = {
    [FORM_STEP_CAR]: pages.form_steps.car,
    [FORM_STEP_OFFICIAL_SERVICE]: pages.form_steps.official_service,
    [FORM_STEP_TIRES]: pages.form_steps.tires,
    [FORM_STEP_LABOURS]: pages.form_steps.labours,
    [FORM_STEP_OVERVIEW]: pages.form_steps.overview,
    [FORM_STEP_REVIEW]: pages.form_steps.review,
};
