export const GARAGE = '[Garage]'

export const GARAGE_SET = `${GARAGE} SET`
export const GARAGE_REMOVE = `${GARAGE} REMOVE`
export const GARAGE_REINITIALIZE = `${GARAGE} REINITIALIZE`
export const GARAGE_UPDATE = `${GARAGE} UPDATE`
export const GARAGE_TERMS_AND_CONDITIONS_ACCEPT = `${GARAGE} TERMS_AND_CONDITIONS_ACCEPT`

export const setGarage = garage => ({
    type: GARAGE_SET,
    payload: {
        garage,
    },
})

export const updateGarage = data => ({
    type: GARAGE_UPDATE,
    payload: {
        data,
    },
})

export const garageRemove = () => ({
    type: GARAGE_REMOVE,
})

export const garageReinitialize = () => ({
    type: GARAGE_REINITIALIZE,
})

export const garageTermsAndConditionsAccept = () => ({
    type: GARAGE_TERMS_AND_CONDITIONS_ACCEPT,
})
