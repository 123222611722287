import React, {Component} from 'react'
import {translate} from 'react-i18next'
import axios from 'app/config/axios'
import axiosRoot from 'axios'
import {toast} from "react-toastify";
import Toast from "../../../components/shared/Toast/Toast";
import {ext, parseResponse} from "../../../utils/common";
import Popup from "../../../components/shared/Popup/Popup";
import Button from "../../../components/shared/Button/Button";

const UPLOAD_INVOICE_URL = 'garage/requests/upload-invoice';

const ALLOWED_TYPES = ['pdf', 'xml', 'txt'];
const MAXIMUM_FILE_SIZE = 2097152 // 2MB

class UploadInvoice extends Component {

    constructor(props) {
        super(props);

        this.state = {
            file: null,
            uploadProgress: 0,
            uploading: false,
        }

        this.cancel = null;
        this.inputFile = React.createRef();
    }

    onChange = (e) => {
        const {t} = this.props;

        let toUpload = e.target.files[0];

        if (ALLOWED_TYPES.indexOf(ext(toUpload.name)) === -1) {
            toast.error(<Toast
                text={t('pages.request_details.upload_invoice.file_type', {'format': '.' + ALLOWED_TYPES.join(', .')})}
                type="error"/>)
            return
        }

        if (toUpload.size > MAXIMUM_FILE_SIZE) {
            toast.error(<Toast
                text={t('pages.request_details.upload_invoice.file_size', {'size': Math.round(MAXIMUM_FILE_SIZE / 1024 / 1024)})}
                type="error"/>)
            return
        }

        this.setState({
            file: toUpload,
        })

        e.target.value = null;
    }

    upload = () => {
        const {file} = this.state;
        const {request, onUpload} = this.props;

        const formData = new FormData();

        this.cancel = axiosRoot.CancelToken.source();

        formData.append('file', file)
        formData.append('offer_token', request.token)

        this.setState({uploading: true})

        return axios
            .post(UPLOAD_INVOICE_URL, formData, {
                headers: {
                    'content-type': 'multipart/form-data'
                },
                onUploadProgress: progressEvent => {
                    this.setState({
                        uploadProgress: Math.floor((progressEvent.loaded * 100) / progressEvent.total)
                    })
                },
                cancelToken: this.cancel.token
            })
            .then(response => {
                onUpload(response.data)
            })
            .catch(error => {
                if (!axiosRoot.isCancel(error)) {
                    toast.error(<Toast text={parseResponse(error.response)} type="error"/>)
                }
            })
            .finally(() => {
                this.setState({uploading: false})
            })
    }

    cancelUpload = () => {
        const {file} = this.state;

        if (file && this.cancel) {
            this.cancel.cancel();
        }

        this.setState({file: null})
    }

    render() {
        const {t, onClose} = this.props;
        const {uploading, uploadProgress, file} = this.state;

        const FileItem = () => {
            return (
                <div className="upload-file-item">
                    <div className="row">
                        <div className="col-lg-1">
                            <div className="upload-file-item__name">
                                <div className="close" onClick={this.cancelUpload}><i className="icon-close"/></div>
                            </div>
                        </div>
                        <div className="col-lg-9">{file.name}</div>
                        <div className="col-lg-2">
                            {uploading && <div className="upload-file-item__progress">{uploadProgress}%</div>}
                        </div>
                    </div>
                </div>
            )
        }

        const Upload = () => {
            return (
                <div className="upload-area">
                    <label htmlFor="upload-invoice"/>
                    <input ref={r => this.inputFile = r} type="file" name="files[]" id="upload-invoice"
                           accept={ALLOWED_TYPES}
                           onChange={this.onChange}
                    />

                    <div>{t('pages.request_details.drag_and_drop.title')}</div>
                    <div>{t('pages.request_details.drag_and_drop.or')}</div>
                    <div className="link-style">{t('pages.request_details.drag_and_drop.upload_from')}</div>
                </div>
            )
        }

        return (
            <>
                <Popup visible={true} title={t('pages.request_details.upload_invoice_label')} onClose={onClose}>

                    <div className="upload-invoice">

                        {file ? <FileItem/> : <Upload/>}

                        <div className="buttons">
                            <div className="row mt-30">
                                <div className="col-xs-6">
                                    <span className="link-style inline-button"
                                          onClick={onClose}>{t('global.cancel')}</span>
                                </div>
                                <div className="col-xs-6 text-right">
                                    <Button size="sm" type="primary" disabled={uploading || !file}
                                            onClick={this.upload}>
                                        {t('global.upload')}</Button>
                                </div>
                            </div>
                        </div>

                    </div>

                </Popup>
            </>
        )
    }
}

export default translate('translations')(UploadInvoice)
