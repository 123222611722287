import React, { Component } from 'react';
import './Loader.scss';
import {
    LOADER_API_GET_CAR,
    LOADER_FORM_VEHICLE,
    LOADER_LICENCE_PLATE,
    LOADER_INIT,
    LOADER_API_GET_CAR_NUMBER,
    LOADER_REQUEST_REVIEW,
    LOADER_LINK_ICON
} from 'store/consts/loader.constants';
import {LOADER_CHECKBOX} from "../../../store/consts/loader.constants";

export const LOADER_BUTTON = 'button'
export const LOADER_LOADING = 'loading'

class Loader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: props.isLoading ? props.isLoading : false,
            addClass: this.props.addClass ? this.props.addClass : ''
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.state.isLoading !== nextProps.isLoading) {
            this.setState({
                isLoading: nextProps.isLoading
            });
        }
    }

    renderLoaderLinear = loaderClassName => {
        return (
            <div className={loaderClassName}>
                <div className="spinner">
                    <div className="bounce1" />
                    <div className="bounce2" />
                    <div className="bounce3" />
                </div>
            </div>
        );
    };

    renderLoaderCircular = loaderClassName => {
        return (
            <div className={`${loaderClassName} ${this.state.addClass} sk-fading-circle`}>
                <div className="sk-circle1 sk-circle" />
                <div className="sk-circle2 sk-circle" />
                <div className="sk-circle3 sk-circle" />
                <div className="sk-circle4 sk-circle" />
                <div className="sk-circle5 sk-circle" />
                <div className="sk-circle6 sk-circle" />
                <div className="sk-circle7 sk-circle" />
                <div className="sk-circle8 sk-circle" />
                {/*<div className="sk-circle9 sk-circle" />
                <div className="sk-circle10 sk-circle" />
                <div className="sk-circle11 sk-circle" />
                <div className="sk-circle12 sk-circle" />*/}
            </div>
        );
    };

    renderLoaderSpinner = loaderClassName => {
        return (
            <div className={`${loaderClassName} ${this.state.addClass} loader-spinner`} />
        );
    };

    render() {
        if (!this.state.isLoading || !this.props.type) return null;

        switch (this.props.type) {
            case LOADER_FORM_VEHICLE:
                return this.renderLoaderCircular('loader-form-vehicle');
            case LOADER_API_GET_CAR:
                return this.renderLoaderCircular('loader-button-funnel');
            case LOADER_LICENCE_PLATE:
                return this.renderLoaderCircular('loader-button-funnel');
            case LOADER_API_GET_CAR_NUMBER:
                return this.renderLoaderCircular('loader-button-funnel');
            case LOADER_BUTTON:
                return this.renderLoaderCircular('loader-button-funnel');
            case LOADER_INIT:
                return this.renderLoaderLinear('loader-global');
            case LOADER_LOADING:
                return this.renderLoaderCircular('loader-loading');
            case LOADER_CHECKBOX:
                return this.renderLoaderSpinner('loader-checkbox');
            case LOADER_REQUEST_REVIEW:
                return this.renderLoaderCircular('loader-request-review');
            case LOADER_LINK_ICON:
                return this.renderLoaderSpinner('loader-link-icon');
            default:
                return null;
        }
    }
}

export default Loader;
