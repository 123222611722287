import React, {Component} from 'react'
import {translate} from 'react-i18next'

class CarDetails extends Component {

    render() {
        const {t, request} = this.props;

        return (
            <div className="request-detail-box">
                <div className="request-detail-box__header">{t('pages.request_details.car_details')}</div>

                <div className="row request-detail-box__line">
                    <div className="col-lg-6">
                        <span className="label">{t('pages.request_details.car_model')}</span>
                    </div>
                    <div className="col-lg-6">{request.car.make}</div>
                </div>

                <div className="row request-detail-box__line">
                    <div className="col-lg-6">
                        <span className="label">{t('pages.request_details.car_range')}</span>
                    </div>
                    <div className="col-lg-6">{request.car.range}</div>
                </div>

                <div className="row request-detail-box__line">
                    <div className="col-lg-6">
                        <span className="label">{t('pages.request_details.car_type')}</span>
                    </div>
                    <div className="col-lg-6">{request.car.type}</div>
                </div>

                <div className="row request-detail-box__line">
                    <div className="col-lg-6">
                        <span className="label">{t('global.car.licence_plate')}</span>
                    </div>
                    <div className="col-lg-6">{request.car.licence_plate}</div>

                </div>
                <div className="row request-detail-box__line">
                    <div className="col-lg-6">
                        <span className="label">{t('pages.request_details.car_vin_swiss')}</span>
                    </div>
                    <div className="col-lg-6">/</div>
                    {/*{request.car.car_number}*/}
                </div>

                <div className="row request-detail-box__line">
                    <div className="col-lg-6">
                        <span className="label">{t('pages.request_details.car_mileage')}</span>
                    </div>
                    <div className="col-lg-6">{request.mileage}</div>
                </div>

                <div className="row request-detail-box__line">
                    <div className="col-lg-6">
                        <span className="label">{t('pages.request_details.car_first_reg')}</span>
                    </div>
                    <div className="col-lg-6">{request.car.year}</div>
                </div>

                <div className="row request-detail-box__line">
                    <div className="col-lg-6">
                        <span className="label">{t('pages.request_details.car_leasing')}</span>
                    </div>
                    <div className="col-lg-6">{request.leasing.name}</div>
                </div>

            </div>
        )
    }
}

export default translate('translations')(CarDetails)
