import tiresModel from '../models/tires'
import {
    TIRES_UPDATE,
    TIRES_SET,
    TIRES_AXEL_SAME_DIMENSION_SET,
    TIRES_AXEL_DIMENSION_UPDATE,
    TIRES_TIRE_SELECT,
    TIRES_STEP_UPDATE,
    TIRES_LOADING_UPDATE,
    TIRES_RESET,
    TIRES_RESET_SELECTED_TIRES, TIRES_LABOUR_SELECT, TIRES_SORT_UPDATE, TIRES_LABOUR_SELECT_LABOUR,
} from "../actions/tires.actions";
import {set} from "lodash";
import {
    TIRES_AXEL_FRONT,
    TIRES_AXEL_REAR,
    TIRES_AXEL_RESET_FIELDS_BELLOW,
    TIRES_SORT_ASC, TIRES_SORT_DESC
} from "../consts/tires.constants";
import {getFieldsToReset} from "../../utils/car";
import {cloneDeep} from 'lodash'

const FRONT_POSITION = 'front'
const WIDE_TYPE = 'wide'
const DIAMETER_TYPE = 'diameter'

export default function (state = tiresModel, action) {
    switch (action.type) {

        case TIRES_UPDATE:
            return action.payload.tires;

        case TIRES_SET:
            return set(cloneDeep(state), action.payload.path, action.payload.value);

        case TIRES_LOADING_UPDATE:
            return {
                ...state,
                loading: action.payload.loading,
            };

        case TIRES_STEP_UPDATE:
            return {
                ...state,
                step: action.payload.step,
                brand: action.payload.resetFilter ? tiresModel.brand : state.brand,
                brand_list: action.payload.resetFilter ? tiresModel.brand_list : state.brand,
                index: action.payload.resetFilter ? tiresModel.index : state.index,
                index_list: action.payload.resetFilter ? tiresModel.index_list : state.index,
                list: action.payload.resetFilter ? tiresModel.list : state.list,
            };

        case TIRES_AXEL_SAME_DIMENSION_SET:
            return {
                ...state,
                axel: {
                    ...state.axel,
                    rear: {...state.axel.front, selected: state.axel.rear.selected}
                }
            }

        case TIRES_TIRE_SELECT:

            let clone = cloneDeep(state);

            clone.axel.front.tire = action.payload.axels.indexOf(TIRES_AXEL_FRONT) !== -1 ? action.payload.tire : clone.axel.front.tire;
            clone.axel.rear.tire = action.payload.axels.indexOf(TIRES_AXEL_REAR) !== -1 ? action.payload.tire : clone.axel.rear.tire;

            return {
                ...clone
            }

        case TIRES_AXEL_DIMENSION_UPDATE:
            let cloneState = cloneDeep(state);

            if (TIRES_AXEL_RESET_FIELDS_BELLOW[action.payload.name]) {
                getFieldsToReset(TIRES_AXEL_RESET_FIELDS_BELLOW[action.payload.name], cloneState.axel[action.payload.axel], (item, value) => {
                    cloneState.axel[action.payload.axel][item] = value
                })
            }

            let updated = set(cloneState, `axel.${action.payload.axel}.${action.payload.name}`, action.payload.value);

            if (cloneState.sameDimensions) {
                updated.axel.rear = {...updated.axel.front, selected: updated.axel.rear.selected};
            } else if (!cloneState.sameDimensions && action.payload.name === WIDE_TYPE && action.payload.axel === FRONT_POSITION) {
                updated.axel.rear.wide_list = []
            } else if (!cloneState.sameDimensions && action.payload.name === DIAMETER_TYPE && action.payload.axel === FRONT_POSITION) {
                updated.axel.rear.diameter_list = []
            }

            return cloneState;

        case TIRES_LABOUR_SELECT:
            return {
                ...state,
                labours: state.labours.map(labourGroup => {
                    if(labourGroup.id === action.payload.labourGroupId){
                        return {
                            ...labourGroup,
                            checked: action.payload.checked,
                            selected: labourGroup.selected ? labourGroup.selected : labourGroup.labours[0].id,
                        };
                    }

                    return labourGroup;
                })
            }

        case TIRES_LABOUR_SELECT_LABOUR:
            return {
                ...state,
                labours: state.labours.map(labourGroup => {
                    if(labourGroup.id === action.payload.labourGroupId){
                        return {
                            ...labourGroup, selected: action.payload.labourId,
                        };
                    }

                    return labourGroup;
                })
            }

        case TIRES_RESET_SELECTED_TIRES:
            let cloneResetSelected = cloneDeep(state);

            cloneResetSelected.axel.front.tire = tiresModel.axel.front.tire;
            cloneResetSelected.axel.rear.tire = tiresModel.axel.rear.tire;

            return cloneResetSelected;

        case TIRES_SORT_UPDATE:
            let column = TIRES_SORT_ASC;

            if(state.sort.column !== action.payload.column){
                column = TIRES_SORT_ASC;
            }
            else {
                column = state.sort.order === TIRES_SORT_ASC ? TIRES_SORT_DESC : TIRES_SORT_ASC;
            }

            return {
                ...state,
                sort: {
                    column: action.payload.column,
                    order: column,
                }
            };

        case TIRES_RESET:
            return tiresModel;

        default:
            return state
    }
}
