import React, {Component} from 'react';
import Select from 'components/shared/Select/Select';
import {translate} from 'react-i18next';
import {withFormik} from 'formik';
import {connect} from 'react-redux';
import {
    carDataChange, carReset, carUpdateField,
    licencePlateChangeReset,
} from 'store/actions/car.actions';
import Button from 'components/shared/Button/Button';
import {setLoader} from 'store/actions/loader.actions';
import {LOADER_API_GET_CAR, LOADER_FORM_VEHICLE, LOADER_LICENCE_PLATE} from 'store/consts/loader.constants';
import scroller from 'utils/scroller';
import {formVehicleConfig} from "app/config/yup";
import {
    FORM_VEHICLE_TYPE_MANUAL_1,
    FORM_VEHICLE_TYPE_MANUAL_2,
    FORM_VEHICLE_TYPE_MULTIPLE_VEHICLE, FORM_VEHICLE_TYPE_CAR_NUMBER
} from "app/consts/vehicle";
import LicencePlate from "components/shared/LicencePlate/LicencePlate";
import "./FormVehicle.scss";
import {carStepPop, carStepSet} from "store/actions/carStep.actions";
import {isMobileView} from "utils/common";
import fieldExists from "../../../utils/fieldExists";
import {resetToken} from "../../../utils/resetToken";
import {toast} from "react-toastify";
import Toast from "../../shared/Toast/Toast";
import CarNumber from "../../shared/CarNumber/CarNumber";
import {SWISS_LENGTH, KBA_LENGTH, KBA_STRING, SWISS_STRING, VIN_STRING, VIN_REGEX} from "../../../app/consts/carNumber";
import {LOADER_API_GET_CAR_NUMBER} from "../../../store/consts/loader.constants";
import {carStepReplace} from "../../../store/actions/carStep.actions";
import {carNumberLabel} from "../../../store/selectors/carNumberLabel";
import {officialServicesReset} from "../../../store/actions/officialServices.actions";
import Input from "../../shared/Input/Input";
import FormStepCounter from "../../FormStepCounter/FormStepCounter";
import LinkIcon from "../../shared/LinkIcon/LinkIcon";
import CarDataComplete from "../../CarDataComplete/CarDataComplete";

const FIRST_REGISTRATION_POSITION_TOP = 0;
const FIRST_REGISTRATION_POSITION_BOTTOM = 1;

class FormVehicle extends Component {

    constructor(props) {
        super(props);

        this.state = {
            licence_plate: null,
        }
    }

    componentDidMount() {
        const {dispatch, garage} = this.props;

        if (!garage.show_licence_plate) {
            if (garage.show_vin || garage.show_swiss || garage.show_kba) {
                dispatch(carStepReplace(FORM_VEHICLE_TYPE_CAR_NUMBER));
            } else {
                dispatch(carStepReplace(FORM_VEHICLE_TYPE_MANUAL_1));
            }
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const {dispatch, car} = this.props;

        if (this.props.carStep.step !== FORM_VEHICLE_TYPE_CAR_NUMBER && nextProps.carStep.step === FORM_VEHICLE_TYPE_CAR_NUMBER) {
            this.setState({licence_plate: null});
            dispatch(carReset());
        }

        if (nextProps.car.token && car.token && nextProps.car.token !== car.token) {
            dispatch(officialServicesReset())
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {carStep: {step, message, timestamp}, loader} = this.props;

        this.onErrorScrollTo(prevProps.errors, prevProps.isSubmitting);

        if (isMobileView() && (step !== prevProps.carStep.step || loader.licencePlate !== prevProps.loader.licencePlate)) {
            scroller('form-container', {
                duration: 500,
                smooth: "ease"
            });
        }
        
        if (message && prevProps.carStep.timestamp !== timestamp) {
            toast.info(<Toast text={message} type="info"/>)
        }
    }

    onErrorScrollTo = (errors, isSubmitting) => {
        const errorFields = Object.keys(errors);
        if (errorFields.length && isSubmitting) {
            scroller(errorFields[0], {
                duration: 500,
                smooth: "ease"
            })
        }
    };

    onFormFieldChange = (name, value, shouldCallAPI = false) => {
        this.updateFormField(name, value);
        this.props.dispatch(carDataChange(name, value, shouldCallAPI)); /* update redux store and eventually call api */
    };

    updateFormField = (name, value) => {
        const {values, setValues} = this.props;

        values[name] = value;
        setValues(values);
    };

    handleHasOneOption = (fieldId, {id}) => {
        /* if there is only one item in the list and the value is not yet set for it */
        if (!this.props.values[fieldId]) {
            this.onFormFieldChange(fieldId, id);
        }
    };

    handleChangeSelectForMakes = (name, {id}) => {
        if (this.props.car.first_registration == null) {
            this.handleChangeSelect(name, {id});
        }
    };

    handleChangeSelect = (name, {id}) => {
        if (name === 'make_id' && fieldExists(this.props.car, 'make_id')) {
            resetToken();
        }

        this.onFormFieldChange(name, id, true);
        this.props.dispatch(setLoader(LOADER_FORM_VEHICLE, {
            [name]: true
        }));
    };

    handleChangeSelectColorSelect = (name, {id}) => {
        this.onFormFieldChange(name, id, false);
        this.props.dispatch(setLoader(LOADER_FORM_VEHICLE, {
            [name]: true
        }));
    };

    handleTextChange = (e) => {
        const {handleChange} = this.props;
        const {name, value} = e.target;

        if (e.target instanceof HTMLInputElement) {
            handleChange(e);
            this.onFormFieldChange(name, value);
        }
    };

    handleCarNumberChange = (e) => {
        const {handleChange} = this.props;
        const {name, value} = e.target;

        if (e.target instanceof HTMLInputElement) {
            handleChange(e);
            this.onFormFieldChange(name, value);
        }

        if ((new RegExp(VIN_REGEX)).test(value)) {
            this.onFormFieldChange('car_number_type', VIN_STRING);
        } else if (value.length === SWISS_LENGTH) {
            this.onFormFieldChange('car_number_type', SWISS_STRING);
        } else if (value.length === KBA_LENGTH) {
            this.onFormFieldChange('car_number_type', KBA_STRING);
        } else {
            this.onFormFieldChange('car_number_type', null);
        }
    };

    shouldForceChangeMake = () => {
        const {car} = this.props;

        return car.makes.length === 1 && car.first_registration_list.length === 0;
    };

    handleMakesEmpty = () => {
        const {car} = this.props;

        resetToken();

        this.props.dispatch(carDataChange('makes', car.makes, true));
        this.props.dispatch(setLoader(LOADER_FORM_VEHICLE, {
            'makes': true
        }));
    };

    handleLicencePlateChange = (canton, number) => {
        const isCantonFound = !this.props.cantonList.find(cantonName => cantonName.toLowerCase() === canton.toLowerCase());
        const isNumberEmpty = (number.toString().length === 0);

        if (isCantonFound || isNumberEmpty)
            return this.onFormFieldChange('licence_plate', null);

        this.setState({
            licence_plate: canton.toUpperCase() + number
        });

        this.onFormFieldChange('licence_plate', `${canton.toUpperCase()}${number}`);
    };

    handleLicencePlateReset = () => {
        this.props.errors['licence_plate'] = null;
        this.onFormFieldChange('licence_plate', null);

        this.props.dispatch(licencePlateChangeReset());
    };

    handleChangeLeasing = (id, option) => {
        const {dispatch} = this.props;

        this.updateFormField(id, option.id);
        dispatch(carUpdateField(id, option.id))
    }

    resetToManual = () => {
        const {dispatch} = this.props;

        this.setState({licence_plate: null});
        dispatch(carReset());
        dispatch(carStepSet(FORM_VEHICLE_TYPE_MANUAL_1));
    };

    goToCarNumber = () => {
        const {dispatch} = this.props;

        dispatch(carStepSet(FORM_VEHICLE_TYPE_CAR_NUMBER));
    };

    renderFormStep = () => {
        const {carStep: {step}} = this.props;

        if (step === FORM_VEHICLE_TYPE_MANUAL_1) {
            return this.renderFormStepManualFirst();
        } else if (step === FORM_VEHICLE_TYPE_MANUAL_2) {
            return this.renderFormStepManualSecond();
        } else if (step === FORM_VEHICLE_TYPE_MULTIPLE_VEHICLE) {
            return this.renderFormStepMultipleVehicle();
        } else if (step === FORM_VEHICLE_TYPE_CAR_NUMBER) {
            return this.renderFormStepCarNumber();
        }

        return this.renderFormStepLicencePlate();
    };

    renderFormStepLicencePlate = () => {
        const {t, car, touched, errors, loader, cantonList, formSubmitting, garage} = this.props;

        return (
            <div key="licence-plate" className="row">

                <div className="col-sm-6">
                    <FormStepCounter/>
                </div>

                <div className="col-sm-6">
                    <h2 className="licence-plate-form-group">{t('pages.form_steps.car.method_title_lp')}</h2>

                    <LicencePlate
                        onChange={this.handleLicencePlateChange}
                        list={cantonList}
                        error={touched.licence_plate && errors.licence_plate}
                        onReset={this.handleLicencePlateReset}
                        value={car.licence_plate || this.state.licence_plate}
                        onFocus={() => {
                        }}
                        t={t}
                    />

                    <Input
                        type="tel"
                        name="mileage"
                        label={`${t('form.label.mileage')}`}
                        value={car.mileage}
                        onChange={this.handleTextChange}
                        error={touched.mileage && errors.mileage}
                        extraClass="licence-plate-form-group"
                        integer={true}
                        maxLength={6}
                        addOn={'Km'}
                    />

                    <div className="licence-plate-form-group">
                        <Select
                            id="leasing_id"
                            list={garage.leasing}
                            value={car.leasing_id}
                            label={t('form.label.leasing')}
                            onChange={(id, option) => this.handleChangeLeasing(id, option)}
                            onHasOneOption={(fieldId, option) => this.handleHasOneOption(fieldId, option)}
                            error={touched.leasing_id && errors.leasing_id}
                            disabled={!garage.leasing.length}
                            selected={car.leasing_id}
                        >
                            {t('form.placeholder.leasing')}
                        </Select>
                    </div>

                    <div className="licence-plate-form-group">
                        <span onClick={this.goToCarNumber} className="link-style">{t('global.dont_know_lp')}</span>
                    </div>

                    <div className="licence-plate-form-group mt-30">
                        <Button size="lg" type="primary" loading={loader[LOADER_LICENCE_PLATE]}
                                disabled={(formSubmitting && formSubmitting.vehicle) || (loader[LOADER_LICENCE_PLATE] && loader[LOADER_LICENCE_PLATE] === true) || loader[LOADER_LICENCE_PLATE]}>
                            {t('form.button.next')}
                        </Button>
                    </div>
                </div>
            </div>
        )
    };

    renderFormStepCarNumber = () => {
        const {t, car, touched, errors, loader, formSubmitting, carNumberLabel, dispatch, garage} = this.props;

        return (
            <div>

                <div key="licence-plate" className="row">

                    <div className="col-sm-6">
                        <FormStepCounter/>
                    </div>

                    <div className="col-sm-6">
                        <h2 className="licence-plate-form-group">{t('pages.form_steps.car.method_title_lp')}</h2>

                        <div className="licence-plate-form-group">
                            <CarNumber
                                onChange={this.handleCarNumberChange}
                                error={touched.car_number && errors.car_number}
                                value={car.car_number}
                                carNumberLabel={carNumberLabel}
                            />

                            <Input
                                type="text"
                                name="contract_number"
                                label={`${t('form.label.contract_number')}`}
                                value={car.contract_number}
                                onChange={this.handleTextChange}
                                error={touched.contract_number && errors.contract_number}
                                extraClass="licence-plate-form-group"
                                placeholder={`${t('form.label.contract_number')}`}
                            />

                            <Input
                                type="text"
                                name="mileage"
                                label={`${t('form.label.mileage')}`}
                                value={car.mileage}
                                onChange={this.handleTextChange}
                                error={touched.mileage && errors.mileage}
                                extraClass="licence-plate-form-group"
                                integer={true}
                                maxLength={7}
                                addOn={'Km'}
                            />

                            <Select
                                id="leasing_id"
                                list={garage.leasing}
                                value={car.leasing_id}
                                label={t('form.label.leasing')}
                                onChange={(id, option) => this.handleChangeLeasing(id, option)}
                                onHasOneOption={(fieldId, option) => this.handleHasOneOption(fieldId, option)}
                                error={touched.leasing_id && errors.leasing_id}
                                disabled={!garage.leasing.length}
                                selected={car.leasing_id}
                            >
                                {t('form.placeholder.leasing')}
                            </Select>
                        </div>

                        <div className="licence-plate-form-group">
                            <div className="row mt-50">
                                <div className="col-xs-6">
                                    <LinkIcon icon={"icon-left"} left={true} text={t('global.back')} extraClass="back-link"
                                              onClick={() => dispatch(carStepPop())}/>
                                </div>
                                <div className="col-xs-6 text-right">
                                    <Button size="sm" type="primary" loading={loader[LOADER_API_GET_CAR_NUMBER]}
                                            disabled={(formSubmitting && formSubmitting.vehicle) || (loader[LOADER_API_GET_CAR_NUMBER] && loader[LOADER_API_GET_CAR_NUMBER] === true)}>
                                        {t('form.button.next')}
                                    </Button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    };

    firstRegistrationComponent = () => {
        const {t, car, values, touched, errors, loader} = this.props;

        return (
            <Select
                id="first_registration"
                list={car.first_registration_list}
                value={values.first_registration}
                label={t('form.label.year')}
                onChange={(id, option) => this.handleChangeSelect(id, option)}
                onHasOneOption={(fieldId, option) => this.handleHasOneOption(fieldId, option)}
                error={touched.first_registration && errors.first_registration}
                disabled={!car.first_registration_list.length}
                selected={car.first_registration}
                hidden={typeof car.first_registration === 'boolean' && car.first_registration === true}
                isLoading={loader[LOADER_FORM_VEHICLE]['make_id']}
            >
                {t('form.placeholder.year')}
            </Select>
        );
    }

    renderFirstRegistration = (position) => {
        const {car} = this.props;

        if (position === FIRST_REGISTRATION_POSITION_TOP && car.car_number === '') {
            return this.firstRegistrationComponent();
        } else if (position === FIRST_REGISTRATION_POSITION_BOTTOM && car.car_number !== '') {
            return this.firstRegistrationComponent();
        }
    }

    renderFormStepManualFirst = () => {
        const {t, car, values, touched, errors, loader, formSubmitting, dispatch} = this.props;

        return (
            <div key="manual-first" className="row">

                <div className="col-sm-1">
                    <FormStepCounter short={true}/>
                </div>

                <div className="col-sm-push-2 col-sm-6">
                    <h2>{t('pages.form_steps.car.method_title_manual')}</h2>

                    <Select
                        id="make_id"
                        list={car.makes}
                        value={values.make_id}
                        label={t('form.label.make')}
                        onHasOneOption={(fieldId, option) => this.handleChangeSelectForMakes(fieldId, option)}
                        error={touched.make_id && errors.make_id}
                        selected={car.make_id}
                        disabled={!car.makes.length}
                        onChange={(id, option) => this.handleChangeSelect(id, option)}
                        onEmptyList={this.handleMakesEmpty}
                        isLoading={loader[LOADER_FORM_VEHICLE]['makes']}
                    >
                        {t('form.placeholder.make')}
                    </Select>

                    {this.renderFirstRegistration(FIRST_REGISTRATION_POSITION_TOP)}

                    <Select
                        id="range_id"
                        list={car.ranges}
                        value={values.range_id}
                        label={t('form.label.range')}
                        onChange={(id, option) => this.handleChangeSelect(id, option)}
                        onHasOneOption={(fieldId, option) => this.handleHasOneOption(fieldId, option)}
                        error={touched.range_id && errors.range_id}
                        disabled={!car.ranges.length}
                        selected={car.range_id}
                        isLoading={loader[LOADER_FORM_VEHICLE]['first_registration']}
                        attributes={['productionFrom', 'productionTo']}
                        attributesSeparator={' - '}
                    >
                        {t('form.placeholder.range')}
                    </Select>

                    <Select
                        id="type_id"
                        list={car.types}
                        value={values.type_id}
                        label={t('form.label.type')}
                        onChange={(id, option) => this.handleChangeSelect(id, option)}
                        onHasOneOption={(fieldId, option) => this.handleHasOneOption(fieldId, option)}
                        error={touched.type_id && errors.type_id}
                        disabled={!car.types.length}
                        selected={car.type_id}
                        hidden={typeof car.type_id === 'boolean' && car.type_id === true}
                        isLoading={loader[LOADER_FORM_VEHICLE]['range_id']}
                        attributes={['productionFrom', 'productionTo']}
                        attributesSeparator={' - '}
                    >
                        {t('form.placeholder.type')}
                    </Select>
                    {this.renderFirstRegistration(FIRST_REGISTRATION_POSITION_BOTTOM)}
                    <Input
                        type="tel"
                        name="mileage"
                        label={`${t('form.label.mileage')}`}
                        value={car.mileage}
                        onChange={this.handleTextChange}
                        error={touched.mileage && errors.mileage}
                        integer={true}
                        maxLength={7}
                        addOn={'Km'}
                    />

                    <div className="row mt-50">
                        <div className="col-xs-6">
                            <LinkIcon icon={"icon-left"} left={true} text={t('global.back')} extraClass="back-link"
                                      onClick={() => dispatch(carStepPop())}
                            />
                        </div>
                        <div className="col-xs-6 text-right">
                            <Button size="sm" type="primary" loading={loader[LOADER_API_GET_CAR]}
                                    disabled={(formSubmitting && formSubmitting.vehicle) || (loader[LOADER_API_GET_CAR] && loader[LOADER_API_GET_CAR] === true)}>
                                {t('form.button.next')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    renderFormStepManualSecond = () => {
        const {t, car, values, touched, errors, loader, formSubmitting, dispatch} = this.props;

        return (
            <div key="manual-second" className="row">

                <div className="col-sm-1">
                    <FormStepCounter short={true}/>
                </div>

                <div className="col-sm-push-2 col-sm-6">

                    <h2>{t('pages.form_steps.car.method_title_manual_2')}</h2>

                    <div className="mb-50">
                        <CarDataComplete car={car}/>
                    </div>

                    <h3>{t('pages.form_steps.car.method_title_manual')}</h3>

                    <Select
                        id="gear_id"
                        list={car.gears}
                        value={values.gear_id}
                        label={t('form.label.gear')}
                        onChange={(id, option) => this.handleChangeSelect(id, option)}
                        onHasOneOption={(fieldId, option) => this.handleHasOneOption(fieldId, option)}
                        error={touched.gear_id && errors.gear_id}
                        disabled={!car.gears.length}
                        selected={car.gear_id}
                        hidden={car.gears.length <= 1}
                        // isLoading={loader[LOADER_FORM_VEHICLE]['first_registration']}
                    >
                        {t('form.placeholder.gear')}
                    </Select>

                    <Select
                        id="qual_md"
                        list={car.qual_md_list}
                        value={values.qual_md}
                        label={t('form.label.qual_md_list')}
                        onChange={(id, option) => this.handleChangeSelect(id, option)}
                        onHasOneOption={(fieldId, option) => this.handleHasOneOption(fieldId, option)}
                        error={touched.qual_md && errors.qual_md}
                        disabled={!car.qual_md_list.length}
                        selected={car.qual_md}
                        hidden={car.qual_md_list.length <= 1}
                        isLoading={loader[LOADER_FORM_VEHICLE]['gear_id']}
                    >
                        {t('form.placeholder.qual_md_list')}
                    </Select>

                    <Select
                        id="qual_lt"
                        list={car.qual_lt_list}
                        value={values.qual_lt}
                        label={t('form.label.qual_lt_list')}
                        onChange={(id, option) => this.handleChangeSelect(id, option)}
                        onHasOneOption={(fieldId, option) => this.handleHasOneOption(fieldId, option)}
                        error={touched.qual_lt && errors.qual_lt}
                        disabled={!car.qual_lt_list.length}
                        selected={car.qual_lt}
                        hidden={car.qual_lt_list.length <= 1}
                        isLoading={loader[LOADER_FORM_VEHICLE]['qual_md']}
                    >
                        {t('form.placeholder.qual_lt_list')}
                    </Select>

                    <div className="row mt-50">
                        <div className="col-sm-6">
                            <LinkIcon icon={"icon-left"} left={true} text={t('global.back')} extraClass="back-link"
                                      onClick={() => dispatch(carStepPop())}
                            />
                        </div>
                        <div className="col-sm-6 text-right">
                            <Button size="sm" type="primary" loading={loader[LOADER_API_GET_CAR]}
                                    disabled={(formSubmitting && formSubmitting.vehicle) || (loader[LOADER_API_GET_CAR] && loader[LOADER_API_GET_CAR] === true)}>
                                {t('form.button.next')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    renderFormStepMultipleVehicle = () => {
        const {car, formSubmitting, touched, loader, values, t, errors, dispatch} = this.props;

        return (
            <div key="multiple-vehicle">
                <Select
                    id="color_make_id"
                    list={car.makes}
                    value={values.color_make_id}
                    label={t('form.label.make')}
                    onHasOneOption={(fieldId, option) => this.handleChangeSelectForMakes(fieldId, option)}
                    error={touched.color_make_id && errors.color_make_id}
                    selected={car.color_make_id}
                    onChange={(id, option) => this.handleChangeSelectColorSelect(id, option)}
                    directSearch
                >
                    {t('form.placeholder.make')}
                </Select>

                <Select
                    id="color"
                    list={car.color_list}
                    value={values.color}
                    label={t('form.label.color')}
                    onChange={(id, option) => this.handleChangeSelectColorSelect(id, option)}
                    onHasOneOption={(fieldId, option) => this.handleHasOneOption(fieldId, option)}
                    error={touched.color && errors.color}
                    selected={car.color}
                >
                    {t('form.placeholder.color')}
                </Select>

                <div className="row mt-50">
                    <div className="col-xs-6">
                        <LinkIcon icon={"icon-left"} left={true} text={t('global.back')} extraClass="back-link"
                                  onClick={() => dispatch(carStepPop())}
                        />
                    </div>
                    <div className="col-xs-6 text-right">
                        <Button size="sm" type="primary" loading={loader[LOADER_LICENCE_PLATE]}
                                disabled={(formSubmitting && formSubmitting.vehicle) || (loader[LOADER_LICENCE_PLATE] && loader[LOADER_LICENCE_PLATE] === true)}>
                            {t('form.button.next')}
                        </Button>
                    </div>
                </div>
            </div>
        )
    };

    render() {
        const {handleSubmit} = this.props;

        return (
            <div className="form-holder">
                <div className="form-container">

                    <form onSubmit={e => handleSubmit(e)}>
                        {this.renderFormStep()}
                    </form>

                </div>
            </div>
        );
    }
}

const FormikApp = withFormik(formVehicleConfig)(FormVehicle);

const mapStateToProps = state => {
    return {
        car: state.car,
        error: state.error,
        loader: state.loader,
        global: state.global,
        formSubmitting: state.formSubmitting,
        cantonList: state.cantonList,
        carStep: state.carStep,
        country: state.country,
        garage: state.garage,
        carNumberLabel: carNumberLabel(state.garage),
    };
};

export default connect(mapStateToProps)(translate('translations')(FormikApp));
