import React, {Component} from 'react';
import {connect} from 'react-redux';
import {translate} from 'react-i18next';
import Header from "components/layout/Header/Header";
import {pickGarage} from "store/actions/auth.actions";
import {garageRemove} from "store/actions/garage.actions";
import {userLogout} from "store/actions/auth.actions";
import Button from "../../components/shared/Button/Button";
import SubmitContainer from "../../components/shared/SubmitContainer/SubmitContainer";
import history from "../../routes/history";
import pages from "../../app/consts/routes";

class PickGarageContainer extends Component {
    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(garageRemove());
    }

    pickGarage = (garage) => {
        const { dispatch } = this.props;

        dispatch(pickGarage(garage.id)).then(() => {
            history.push(pages.home)
        });
    }

    render() {
        const { title, user, t, dispatch } = this.props;

        return (
            <React.Fragment>

                <Header title={t(title)} showBackArrow={false}/>

                <div className="container container--has-submit-container">

                    <p className="font-size-sm text-gray-dark mb-50">{t('pages.pick_garage.tip')}</p>

                    <ul className="unstyled">
                        {user.garages.map((garage) => {
                            return (
                                <li key={garage.id} onClick={() => this.pickGarage(garage)}>
                                    <div className="pick-garage-item">
                                        <div className="pick-garage-item__name">
                                            {garage.name}
                                        </div>
                                        <div className="pick-garage-item__address">
                                            {garage.address}
                                        </div>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>

                    <SubmitContainer stat={true}>
                        <Button size="lg" type="primary" extraClass="btn-primary--outline">
                            <span onClick={() => dispatch(userLogout())}>{t('global.cancel')}</span>
                        </Button>
                    </SubmitContainer>

                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    };
};

export default connect(mapStateToProps)(translate('translations')(PickGarageContainer));
