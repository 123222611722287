import React, {Component} from 'react'
import { translate } from 'react-i18next'
import RequestStatus from "../RequestStatus";
import {dateFormat} from "../../../../utils/common";

class CanceledDetail extends Component {

    render() {
        const {t, request} = this.props;

        return(
            <div className="request-detail-box">

                <div className="row request-detail-box__line">
                    <div className="col-lg-3"><span className="label">{t('pages.request_details.request_id')}</span></div>
                    <div className="col-lg-5">{request.id}</div>
                </div>

                <div className="row request-detail-box__line">
                    <div className="col-lg-3"><span className="label">{t('pages.request_details.date')}</span></div>
                    <div className="col-lg-5">{dateFormat(request.date, '/')}</div>
                </div>

                <div className="row request-detail-box__line">
                    <div className="col-lg-3"><span className="label">{t('pages.request_details.status')}</span></div>
                    <div className="col-lg-5">
                        <RequestStatus status={request.transaction.status}/>
                    </div>
                </div>

            </div>
        )
    }
}

export default translate('translations')(CanceledDetail)
