export const LOADER_INIT = 'initialization'
export const LOADER_FORM_VEHICLE = 'formVehicle'
export const LOADER_LICENCE_PLATE = 'licencePlate'
export const LOADER_API_GET_CAR = 'apiGetCar'
export const LOADER_API_GET_CAR_NUMBER = 'apiGetCarNumber'
export const LOADER_SETTINGS_PAGE = 'settingsPage'
export const LOADER_REQUESTS = 'requests'
export const LOADER_CHECKBOX = 'checkbox'
export const LOADER_REQUEST_REVIEW = 'request-review'
export const LOADER_LINK_ICON = 'link-icon'
export const LOADER_NOTIFICATIONS = 'notifications'

export default {
    LOADER_INIT,
    LOADER_FORM_VEHICLE,
    LOADER_LICENCE_PLATE,
    LOADER_API_GET_CAR,
    LOADER_API_GET_CAR_NUMBER,
    LOADER_SETTINGS_PAGE,
    LOADER_REQUESTS,
    LOADER_CHECKBOX,
    LOADER_REQUEST_REVIEW,
    LOADER_LINK_ICON,
    LOADER_NOTIFICATIONS,
}
