import React, {Component} from 'react';
import './CarDataComplete.scss';
import {translate} from "react-i18next";
import {getValueFromArray} from "utils/car";
import {getServiceImgPath} from "../../utils/service";

class CarDataComplete extends Component {

    render() {
        const {car} = this.props;

        const make = getValueFromArray(car.makes, 'id', car.make_id);
        const range = getValueFromArray(car.ranges, 'id', car.range_id);
        const type = getValueFromArray(car.types, 'id', car.type_id);

        if (!car) {
            return null;
        }

        return (
            <React.Fragment>
                <div className="car-data-complete">
                    <div className="row">
                        <div className="col-md-2 col-sm-2 col-xs-2">
                            <div className="car-data-complete__logo ">
                                <img
                                    src={make.name && getServiceImgPath(make.name.toLowerCase())}
                                    alt={make.name}
                                />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="car-data-complete__info">
                                <div className="car-data-complete__info--make">{make.name}</div>
                                <div className="car-data-complete__info--range">{range.name}</div>
                                {type && <div className="car-data-complete__info--type">{type.name}</div>}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default translate('translations')(CarDataComplete);
