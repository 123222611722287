import store from 'store/store';
import history from 'routes/history';
import React from "react";
import {fieldExists} from "utils/fieldExists";
import pages from "app/consts/routes";

export const HasOfferToken = (Component) => {

    return class extends React.Component {

        constructor(props) {
            super(props);

            const car = store.getState().car;
            this.shouldRender = true;

            if( !(!!car && fieldExists(car, 'token')) ){
                this.shouldRender = false;
                history.replace(pages.form_steps.car);
            }
        }

        render() {
            if(this.shouldRender){
                return <Component {...this.props} />;
            }

            return <div/>;
        }

    }

};

export default HasOfferToken;
