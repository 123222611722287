import axios from "../../app/config/axios";
import {toBool} from "../../utils/common";
import {LABOUR_TYPE_OFFICIAL, LABOUR_TYPE_REGULAR} from "../consts/labours/labours.constants";

export const LABOURS_PREDEFINED = '[Labours Predefined]'

export const LABOURS_PREDEFINED_SET = `${LABOURS_PREDEFINED} SET`
export const LABOURS_PREDEFINED_UPDATE = `${LABOURS_PREDEFINED} UPDATE`
export const LABOURS_PREDEFINED_APPEND = `${LABOURS_PREDEFINED} APPEND`
export const LABOURS_PREDEFINED_REMOVE = `${LABOURS_PREDEFINED} REMOVE`

const LABOURS_PREDEFINED_URL = 'garage/form/labours-predefined'

export const laboursPredefinedSet = list => ({
    type: LABOURS_PREDEFINED_SET,
    payload: {
        list,
    },
})

export const laboursPredefinedUpdate = labour => ({
    type: LABOURS_PREDEFINED_UPDATE,
    payload: {
        labour,
    },
})

export const laboursPredefinedAppend = labour => ({
    type: LABOURS_PREDEFINED_APPEND,
    payload: {
        labour,
    },
})

export const laboursPredefinedRemove = labour => ({
    type: LABOURS_PREDEFINED_REMOVE,
    payload: {
        labour,
    },
})

export const laboursPredefinedFetch = () => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {

            axios
                .post(LABOURS_PREDEFINED_URL)
                .then(response => {

                    dispatch(laboursPredefinedSet(response.data.map(labour => {
                        return {
                            ...labour,
                            prechecked: toBool(getState().labours.find(l => l.itemMpId === labour.itemMpId && l.korId === labour.korId && l.type === LABOUR_TYPE_OFFICIAL)),
                            checked: toBool(getState().labours.find(l => l.itemMpId === labour.itemMpId && l.korId === labour.korId)),
                            type: LABOUR_TYPE_REGULAR,
                            loading: false,
                            exist: true,
                        }
                    })))

                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}

