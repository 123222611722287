import React, {Component} from 'react'
import {translate} from 'react-i18next'
import InputCheckbox from "../../../../components/shared/InputCheckbox";

class AgreeWithCalculation extends Component {

    state = {
        removeConfirm: null,
        removing: false,
    }

    render() {
        const {t, onChange, agree} = this.props;

        return (
            <React.Fragment>

                <div className="mt-30">
                    <InputCheckbox
                        id={'agree-with-calculation'}
                        name={'agree-with-calculation'}
                        value={true}
                        onChange={onChange}
                        checked={agree}
                        label={t('pages.form_steps.overview.agree_with_calculation')}
                    />
                </div>

            </React.Fragment>
        )
    }
}

export default translate('translations')(AgreeWithCalculation);
