import {USER_SET, USER_REMOVE, USER_UPDATE_COMMUNICATION, USER_UPDATE_LOCALE} from 'store/actions/user.actions'
import user from 'store/models/user'

export default function(state = user, action) {
    switch (action.type) {
        case USER_SET:
            return {
                ...state,
                logged: true,
                ...action.payload.user,
            }

        case USER_UPDATE_COMMUNICATION:
            return {
                ...state,
                communication: action.payload.communications,
            }

        case USER_UPDATE_LOCALE:
            return {
                ...state,
                locale: action.payload.locale,
            }

        case USER_REMOVE:
            return user

        default:
            return state
    }
}
