import React, {Component} from 'react';
import {connect} from 'react-redux';
import {translate} from 'react-i18next';
import Header from "components/layout/Header/Header";
import "./LaboursStepContainer.scss";
import {changeStepData, setStepNext, setStepPrev} from "../../../store/actions/step.actions";
import CarStepMiddleware from "../../../routes/middleware/FormSteps/CarStepMiddleware";
import {HasOfferToken} from "../../../routes/middleware/HasOfferToken";
import {
    laboursPredefinedAppend,
    laboursPredefinedFetch, laboursPredefinedSet,
    laboursPredefinedUpdate
} from "../../../store/actions/laboursPredefined.actions";
import ListLoader from "../../../components/shared/ListLoader/ListLoader";
import {toast} from "react-toastify";
import Toast from "../../../components/shared/Toast/Toast";
import {parseResponse, toBool} from "../../../utils/common";
import LabourItem from "./Components/LabourItem";
import LabourSearch from "./Components/LabourSearch";
import {laboursCalculate} from "../../../store/actions/labours.actions";
import {uniqBy} from "lodash";
import {LABOUR_TYPE_OFFICIAL, LABOUR_TYPE_REGULAR} from "../../../store/consts/labours/labours.constants";
import FormStepCounter from "../../../components/FormStepCounter/FormStepCounter";
import FormStepSubmit from "../../../components/FormStepSubmit/FormStepSubmit";
import FormBeforeLeavePrompt from "../../../components/FormBeforeLeavePrompt/FormBeforeLeavePrompt";
import {FORM_STEP_LABOURS} from "../../../store/consts/step.constants";

const SEARCH_LIST_COUNT = 10;

class LaboursStepContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            creating: false,
            searching: false,
            searchValue: '',
            laboursSearchList: [],
            laboursSearchListPage: 1,
        }
    }

    componentDidMount() {
        const {dispatch, laboursPredefined, labours} = this.props;

        dispatch(changeStepData(FORM_STEP_LABOURS));

        if (laboursPredefined.length === 0) {
            this.setState({loading: true})

            dispatch(laboursPredefinedFetch())
                .catch((error) => {
                    toast.error(<Toast text={parseResponse(error.response)} type="error"/>)
                })
                .finally(() => {
                    this.setState({loading: false})
                })
        }
        else {
            dispatch(laboursPredefinedSet(laboursPredefined.map(labour => {
                return {
                    ...labour,
                    prechecked: toBool(labours.filter(l => l.parentId === null)
                        .find(l => l.itemMpId === labour.itemMpId && l.korId === labour.korId && l.type === LABOUR_TYPE_OFFICIAL)),
                    checked: toBool(labours.filter(l => l.parentId === null)
                        .find(l => (l.itemMpId === labour.itemMpId && l.korId === labour.korId) || (labour.alternative && (l.itemMpId === labour.alternative.itemMpId && l.korId === labour.alternative.korId)))),
                    type: LABOUR_TYPE_REGULAR,
                    loading: false,
                    exist: true,
                }
            })))
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.laboursPredefined !== nextProps.laboursPredefined) {
            this.setState({laboursPredefined: nextProps.laboursPredefined})
        }
    }

    labourItemChange = (e, data) => {
        const {laboursPredefined, dispatch} = this.props;

        if (laboursPredefined.find(labour => labour.itemMpId === data.itemMpId && labour.korId === data.korId)) {
            dispatch(laboursPredefinedUpdate(data));
        }
    }

    labourSearchItemChange = (e, data) => {
        const {dispatch, laboursPredefined} = this.props;
        const {laboursSearchList} = this.state;

        this.setState({
            laboursSearchList: laboursSearchList.map(labour => (labour.itemMpId === data.itemMpId && labour.korId === data.korId) ? data : labour)
        })

        if (laboursPredefined.find(labour => labour.itemMpId === data.itemMpId && labour.korId === data.korId)) {
            dispatch(laboursPredefinedUpdate(data));
        } else {
            dispatch(laboursPredefinedAppend(data));
        }
    }

    canSearch = (value) => {
        return toBool(value.trim().length >= 2);
    }

    onSearch = (value) => {
        const {laboursPredefined} = this.props;

        if (!value) {
            this.onClear(value);
        } else {
            this.setState({
                searching: this.canSearch(value),
                laboursSearchList: laboursPredefined.filter(l =>
                    l.name.toLowerCase().includes(value.toLowerCase().trim()) ||
                    l.group.toLowerCase().includes(value.toLowerCase().trim()) ||
                    l.subGroup.toLowerCase().includes(value.toLowerCase().trim())
                ),
                searchValue: value,
            })
        }
    }

    onClear = () => {
        this.setState({
            searching: false,
            searchValue: '',
            laboursSearchList: [],
        })
    }

    labourSearchResolve = (list) => {
        const {labours} = this.props;
        const {laboursSearchList} = this.state;

        list = list.map(labour => (
            {
                ...labour,
                prechecked: toBool(labours.find(l => l.itemMpId === labour.itemMpId && l.korId === labour.korId && l.type === LABOUR_TYPE_OFFICIAL)),
                checked: toBool(labours.find(l => l.itemMpId === labour.itemMpId && l.korId === labour.korId)),
            }
        ))

        this.setState({
            searching: false,
            laboursSearchListPage: 1,
            laboursSearchList: laboursSearchList.concat(list.filter(item => !laboursSearchList.find(l => l.itemMpId === item.itemMpId && l.korId === item.korId)))
        })
    }

    getSelectedServices = () => {
        const {labours} = this.props;
        const {laboursPredefined} = this.props;

        return uniqBy(
            labours.filter(l => l.type !== LABOUR_TYPE_REGULAR).concat(laboursPredefined.filter(labour => labour.checked === true)),
            labour => [labour.itemMpId, labour.korId].join()
        );
    }

    validateNext = () => {
        const {laboursPredefined, tireLabours, tiresNew} = this.props;
        const {creating} = this.state;

        let hasSelectedLabours = this.getSelectedServices().length > 0 || tireLabours.length > 0 || tiresNew

        return creating || !hasSelectedLabours || laboursPredefined.filter(l => l.loading === true).length > 0;
    }

    back = () => this.props.dispatch(setStepPrev())

    submit = () => {
        const {dispatch} = this.props;

        this.setState({creating: true});

        let services = this.getSelectedServices();

        dispatch(laboursCalculate(services))
            .then((response) => {

                dispatch(setStepNext())

            })
            .catch(error => {
                toast.error(<Toast text={parseResponse(error.response)} type="error"/>)
            })
            .finally(() => this.setState({creating: false}))
    }

    render() {
        const {t, laboursPredefined, title} = this.props;
        const {laboursSearchList, loading, creating, searching, searchValue, laboursSearchListPage} = this.state;

        return (
            <React.Fragment>

                <FormBeforeLeavePrompt/>

                <Header/>

                <div className="container container--has-submit-container">

                    <div className="row">
                        <div className="col-sm-1">
                            <FormStepCounter short={true}/>
                        </div>
                        <div className="col-sm-11">
                            {loading ? <ListLoader/> :
                                <div className="labours-step">
                                    <h2>{t(title)}</h2>

                                    <div className="labours-container">

                                        <LabourSearch
                                            onSearch={this.onSearch}
                                            onResolve={this.labourSearchResolve}
                                            onError={() => {
                                            }}
                                            onClear={this.onClear}
                                        />

                                        {!searchValue &&
                                        <div className="labours-list">
                                            {laboursPredefined.slice(0, SEARCH_LIST_COUNT * laboursSearchListPage).map((labour) => (
                                                <LabourItem
                                                    key={`${labour.itemMpId}${labour.korId}`}
                                                    labour={labour}
                                                    onChange={this.labourItemChange}
                                                />
                                            ))}

                                            {(Math.ceil(laboursPredefined.length / SEARCH_LIST_COUNT) > laboursSearchListPage) &&
                                            <div className="labour-item labour-item--load-more"
                                                 onClick={() => this.setState({laboursSearchListPage: laboursSearchListPage + 1})}>{t('pages.form_steps.labours.load_more')}</div>
                                            }
                                        </div>
                                        }

                                        <div className="labours-list labours-list--search">
                                            {laboursSearchList.slice(0, SEARCH_LIST_COUNT * laboursSearchListPage).map((labour) => (
                                                <LabourItem
                                                    key={`${labour.itemMpId}${labour.korId}`}
                                                    labour={labour}
                                                    onChange={this.labourSearchItemChange}
                                                />
                                            ))}

                                            {(Math.ceil(laboursSearchList.length / SEARCH_LIST_COUNT) > laboursSearchListPage) &&
                                            <div className="labour-item labour-item--load-more"
                                                 onClick={() => this.setState({laboursSearchListPage: laboursSearchListPage + 1})}>{t('pages.form_steps.labours.load_more')}</div>
                                            }

                                            {(!searching && !laboursSearchList.length && this.canSearch(searchValue)) &&
                                            <div
                                                className="labour-item labour-item--no-result">{t('pages.form_steps.labours.no_result')}</div>
                                            }
                                        </div>

                                        {(searching) && <ListLoader stat={true} removeText={true}/>}

                                    </div>

                                    {!loading &&
                                    <FormStepSubmit
                                        onBack={this.back}
                                        onNext={this.submit}
                                        disabled={this.validateNext()}
                                        loading={creating}
                                    />
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        carStep: state.carStep,
        labours: state.labours,
        tireLabours: state.tireLabours,
        tiresNew: state.tiresNew,
        laboursPredefined: state.laboursPredefined,
    };
};

export default connect(mapStateToProps)(CarStepMiddleware(HasOfferToken(translate('translations')(LaboursStepContainer))))
