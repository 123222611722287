import React from 'react'
import store from 'store/store'
import pages from 'app/consts/routes'
import history from 'routes/history'

export const ReviewMiddleware = Component => {
    return class extends React.Component {
        constructor(props) {
            super(props)

            const request = store.getState().request
            this.shouldRender = true

            if (!request.status) {
                this.shouldRender = false
                history.replace(pages.form_steps.car)
            }
        }

        render() {
            if (this.shouldRender) {
                return <Component {...this.props} />
            }

            return <div />
        }
    }
}

export default ReviewMiddleware
