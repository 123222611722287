import React, {Component} from 'react';
import {translate} from "react-i18next";
import Popup from "../shared/Popup/Popup";
import {connect} from "react-redux";
import language from "../../utils/language";

class PrivacyPolicy extends Component {

    render() {
        const {t, onClose, garage} = this.props;

        return (
            <React.Fragment>

                <Popup visible={true} title={t('global.privacy_policy')} onClose={onClose} contentScrollable={true}>
                    <iframe title={t('global.privacy_policy')} src={garage.privacy_policy.url[language()]}/>
                </Popup>

            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        garage: state.garage,
    };
};

export default connect(mapStateToProps)(translate('translations')(PrivacyPolicy));
