import tireLaboursModel from '../models/tireLabours/tireLabours'
import {
    TIRE_LABOURS_SET, TIRE_LABOURS_RESET,
} from "../actions/tireLabours.actions";

export default function (state = tireLaboursModel, action) {
    switch (action.type) {

        case TIRE_LABOURS_SET:
            return action.payload.labours;

        case TIRE_LABOURS_RESET:
            return tireLaboursModel;

        default:
            return state
    }
}
