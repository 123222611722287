import React, { Component } from 'react';
import './DropDown.scss';

class DropDown extends Component {

    render() {
        const { list, onSelect } = this.props;

        return (
            <div className="drop-down-list">

                {list.map((item) => {
                    return <div key={item.id} className="drop-down-item" onClick={() => onSelect(item)}>{item.name}</div>
                })}

            </div>
        );
    }
}

export default DropDown;
