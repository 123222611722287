export const RECOMMENDED_OIL = '[Recommended Oil]'

export const RECOMMENDED_OIL_SET = `${RECOMMENDED_OIL} SET`

export const setRecommendedOil = (oil, viscosity) => ({
    type: RECOMMENDED_OIL_SET,
    payload: {
        oil, viscosity
    },
})
