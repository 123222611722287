import { USER_ROLE_MECHANIC } from '../consts/user.constants'

export default {
    logged: false,
    id: null,
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    avatar: '',
    active: '',
    garages: [],
    type: USER_ROLE_MECHANIC,
    communication: [],
    username: '',
    locale: '',
}
