export const REQUEST = '[Request]'

export const REQUEST_STATUS_SET = `${REQUEST} STATUS SET`
export const REQUEST_UPDATE = `${REQUEST} UPDATE`

export const setRequestStatus = status => ({
    type: REQUEST_STATUS_SET,
    payload: {
        status,
    },
})

export const updateRequest = data => ({
    type: REQUEST_UPDATE,
    payload: {
        data,
    },
})
