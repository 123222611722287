import React, { Component } from 'react';
import './LinkIcon.scss';
import classNames from 'classnames';
import Loader from "../Loader/Loader";
import {LOADER_LINK_ICON} from "../../../store/consts/loader.constants";

class LinkIcon extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        const {icon, text, left, right, extraClass, onClick, type, loading, disabled} = this.props;

        return (
            <div className={classNames("link-icon primary", {[extraClass]: extraClass, [type]: type})} onClick={!disabled ? onClick : null}>
                <span className="link-style">
                    {left && (loading ? <Loader isLoading={loading} type={LOADER_LINK_ICON}/> : <i className={classNames(icon, {'left': left})}/>)}
                    {text}
                    {right && (loading ? <Loader isLoading={loading} type={LOADER_LINK_ICON}/> : <i className={classNames(icon, {'right': right})}/>)}
                </span>
            </div>
        )
    }
}

export default LinkIcon;
