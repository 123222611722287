import React from 'react'
import './index.scss'
import { translate } from 'react-i18next'
import {
    IN_PROGRESS_STATUS,
    ACCEPTED_STATUS,
    REJECTED_STATUS,
    CANCEL_STATUS,
} from '../../../../store/consts/status.constants'
import Badge from "../../../../components/shared/Badge/Badge";

const RequestStatus = ({ status, t }) => {
    if (!status) {
        return null
    }

    if (status === IN_PROGRESS_STATUS) {
        return (
            <Badge type="warning" inverted={true}>
                {t('pages.request_details.statuses.' + status)}
            </Badge>
        )
    }

    if (status === ACCEPTED_STATUS) {
        return (
            <Badge type="success" inverted={true}>
                {t('pages.request_details.statuses.' + status)}
            </Badge>
        )
    }

    if (status === REJECTED_STATUS) {
        return (
            <Badge type="danger" inverted={true}>
                {t('pages.request_details.statuses.' + status)}
            </Badge>
        )
    }

    if (status === CANCEL_STATUS) {
        return (
            <Badge type="default" inverted={true}>
                {t('pages.request_details.statuses.' + status)}
            </Badge>
        )
    }

    return (
        <Badge type="primary" inverted={true}>
            {t('pages.request_details.statuses.' + status)}
        </Badge>
    )
}

export default translate('translations')(RequestStatus)
