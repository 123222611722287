import React, {Component} from 'react'
import {translate} from 'react-i18next'
import {LOADER_REQUEST_REVIEW} from "../../../../store/consts/loader.constants";
import Loader from "../../../../components/shared/Loader/Loader";
import "./style.scss";
import history from "../../../../routes/history";
import pages from "../../../../app/consts/routes";

class InProgress extends Component {

    seeRequestDetails = () => history.push(pages.requests)


    render() {
        const {t} = this.props;

        return (
            <React.Fragment>

                <div className="review-status in-progress">
                    <h2>{t('pages.form_steps.review.in_progress.title')}</h2>

                    <h4>{t('pages.form_steps.review.in_progress.subtitle')}</h4>

                    <div>
                        <Loader isLoading={true} type={LOADER_REQUEST_REVIEW}/>
                    </div>

                    <p className="review-status__desc">{t('pages.form_steps.review.in_progress.desc')}</p>

                    <div className="buttons">
                        <span onClick={this.seeRequestDetails} className="link-style">{t('pages.form_steps.review.in_progress.all_requests')}</span>
                    </div>
                </div>

            </React.Fragment>
        )
    }
}

export default translate('translations')(InProgress)
