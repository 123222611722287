import recommendedOil from 'store/models/recommendedOil'
import {RECOMMENDED_OIL_SET} from "../actions/recommendedOil.actions";

export default function(state = recommendedOil, action) {
    switch (action.type) {
        case RECOMMENDED_OIL_SET:
            return {
                oil: action.payload.oil,
                viscosity: action.payload.viscosity,
            }

        default:
            return state
    }
}
