import React, {Component} from 'react'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'
import Header from "components/layout/Header/Header";
import "./ReviewStepContainer.scss";
import history from "../../../routes/history";
import pages from "../../../app/consts/routes";
import FormStepCounter from "../../../components/FormStepCounter/FormStepCounter";
import {changeStepData, setStepPrev} from "../../../store/actions/step.actions";
import InProgress from "./components/InProgress";
import {
    REQUEST_ACCEPTED_STATUS,
    REQUEST_IN_PROGRESS_STATUS,
    REQUEST_REJECTED_STATUS
} from "../../../store/consts/request.constants";
import ReviewMiddleware from "../../../routes/middleware/FormSteps/ReviewMiddleware";
import LaboursMiddleware from "../../../routes/middleware/FormSteps/LaboursMiddleware";
import CarStepMiddleware from "../../../routes/middleware/FormSteps/CarStepMiddleware";
import HasOfferToken from "../../../routes/middleware/HasOfferToken";
import {STORAGE_OFFER_TOKEN} from "../../../app/consts/storage.consts";
import {updateRequest} from "../../../store/actions/request.actions";
import Accepted from "./components/Accepted";
import Rejected from "./components/Rejected";
import {removeSessionStorageItem} from "../../../utils/storage";
import {storeReset} from "../../../store/actions/store.actions";
import {FORM_STEP_REVIEW} from "../../../store/consts/step.constants";

class ReviewStepContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showRequestSpecification: false,
            agreeWithCalculation: false,
            canceling: false,
        }

        this.token = props.car.token
        this.preventReset = false
    }

    componentDidMount() {
        const {dispatch, user} = this.props;

        dispatch(changeStepData(FORM_STEP_REVIEW));

        let channel = `user-repair-shop.${user.id}`;
        let event = `.business-gateway.response.${this.token}`;

        window.Echo.private(channel).listen(event, e => {
            dispatch(updateRequest({status: e.status, reject_reason: e.reason, auth_number: e.authNumber}));
        });
    }

    componentWillUnmount() {
        if(this.preventReset === false){
            this.reset()
        }
    }

    back = () => this.props.dispatch(setStepPrev())

    reset = () => {
        const {dispatch} = this.props;

        dispatch(storeReset());
        removeSessionStorageItem(STORAGE_OFFER_TOKEN);
    }

    onCancel = () => {
        this.reset()
        this.seeRequestDetails()
    }

    onArchive = () => {
        this.reset()
        this.seeRequestDetails()
    }

    onSeeRequestDetails = () => {
        this.reset()
        this.seeRequestDetails()
    }

    onEdit = () => {
        this.preventReset = true
        history.go(-1);
    }

    seeRequestDetails = () => history.push(`${pages.request_details}${this.token}`)

    render() {
        const {request, car, dispatch} = this.props;

        return (
            <React.Fragment>
                <Header/>

                <div className="container container--has-submit-container">

                    <div className="row">
                        <div className="col-sm-1">
                            <FormStepCounter short={true}/>
                        </div>
                        <div className="col-sm-10">

                            <div className="request-overview">

                                {request.status === REQUEST_IN_PROGRESS_STATUS &&
                                <InProgress />}
                                {request.status === REQUEST_ACCEPTED_STATUS &&
                                <Accepted car={car} request={request} seeRequestDetails={this.onSeeRequestDetails}/>}
                                {request.status === REQUEST_REJECTED_STATUS &&
                                <Rejected car={car} request={request} onArchive={this.onArchive} onEdit={this.onEdit} dispatch={dispatch}/>}

                            </div>

                        </div>
                    </div>

                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        car: state.car,
        user: state.user,
        request: state.request,
    }
}

export default connect(mapStateToProps)(ReviewMiddleware(LaboursMiddleware(CarStepMiddleware(HasOfferToken(translate('translations')(ReviewStepContainer))))))
