import React, {Component} from "react";
import {connect} from "react-redux";
import {translate} from "react-i18next";
import "./index.scss";
import Header from "../../components/layout/Header/Header";
import axios from "../../app/config/axios";
import {parseResponse} from "../../utils/common";
import Toast from "../../components/shared/Toast/Toast";
import {toast} from "react-toastify";
import axiosDefault from "axios";
import LinkIcon from "../../components/shared/LinkIcon/LinkIcon";
import AcceptedDetail from "./components/StatusDetail/AcceptedDetail";
import {
    ACCEPTED_STATUS,
    CANCEL_STATUS,
    canSendInvoice,
    IN_PROGRESS_STATUS,
    REJECTED_STATUS
} from "../../store/consts/status.constants";
import CanceledDetail from "./components/StatusDetail/CanceledDetail";
import InProgressDetail from "./components/StatusDetail/InProgressDetail";
import RejectedDetail from "./components/StatusDetail/RejectedDetail";
import RequestOverview from "./components/RequestOverview";
import CarDetails from "./components/CarDetails";
import RequestSpecification from "./components/RequestSpecification";
import {setRequestSettings} from "../../store/actions/requestSettings.actions";
import {fetchEditRequest} from "../../store/actions/editRequest.actions";
import Confirm from "../../components/shared/Confirm/Confirm";
import history from "../../routes/history";
import pages from "../../app/consts/routes";
import ListLoader from "../../components/shared/ListLoader/ListLoader";
import UploadInvoice from "./components/UploadInvoice";

const REQUEST_DETAILS_URL = 'garage/requests/request'
const CANCEL_REQUEST_URL = 'garage/form/cancel-request'
const ARCHIVE_REQUEST_URL = 'garage/form/archive-request'
const GENERATE_FILE_URL = 'garage/form/generate-file';

class RequestsDetailsContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            request: null,
            loading: true,
            confirmEdit: false,
            confirmCancel: false,
            editing: false,
            canceling: false,
            confirmArchive: false,
            archiving: false,
            showUploadInvoice: false,
            generatingFile: null,
        }

        this.cancelToken = null;
    }

    componentDidMount() {
        this.fetch()
    }

    componentWillUnmount() {
        if (this.cancelToken) this.cancelToken.cancel();
    }

    fetch = () => {
        const {dispatch} = this.props;

        this.cancelToken = axiosDefault.CancelToken.source();

        axios
            .post(REQUEST_DETAILS_URL, {offer_token: this.props.match.params.request}, {cancelToken: this.cancelToken.token})
            .then(response => {
                this.setState({
                    request: response.data,
                    loading: false,
                })

                dispatch(setRequestSettings(response.data.settings))

            })
            .catch(error => {
                if(!axiosDefault.isCancel(error)) {
                    toast.error(<Toast text={parseResponse(error.response)} type="error"/>)
                }
            })
            .finally(() => this.cancelToken = null)
    }

    onCancel = () => {
        const {request} = this.state;

        this.setState({canceling: true})

        axios.post(CANCEL_REQUEST_URL, {offer_token: request.token})
            .then(response => {
                this.setState({
                    request: {...request, transaction: {...request.transaction, status: CANCEL_STATUS}},
                    confirmCancel: false,
                    canceling: false,
                })
            })
            .catch(error => {
                toast.error(<Toast text={parseResponse(error.response)} type="error"/>)
            })
            .finally(() => this.setState({canceling: false}))
    }

    onArchive = () => {
        const {request} = this.state;

        this.setState({archiving: true})

        axios.post(ARCHIVE_REQUEST_URL, {offer_token: request.token})
            .then(response => {
                this.setState({
                    request: {...request, is_archived: true},
                    confirmArchive:false,
                    archiving:false,
                })
            })
            .catch(error => {
                toast.error(<Toast text={parseResponse(error.response)} type="error"/>)
            })
            .finally(() => this.setState({archiving: false}))
    }

    onEdit = () => {
        const {dispatch} = this.props;

        this.setState({editing: true})

        dispatch(fetchEditRequest(this.props.match.params.request))
            .then(() => {
                this.setState({confirmEdit: false, editing: false})
                history.push(pages.form_steps.overview)
            })
            .catch((error) => toast.error(<Toast text={parseResponse(error.response)} type="error"/>))
    }

    generateFile = (type) => {
        const {request} = this.state;

        this.setState({generatingFile: type})

        axios.post(GENERATE_FILE_URL, {
            type: type,
            offer_token: request.token
        })
            .then(response => {
                window.open(response.data.link);
            })
            .catch(error => toast.error(<Toast text={parseResponse(error.response)} type="error"/>))
            .finally(() => this.setState({generatingFile: null}))
    }

    render() {
        const {request, loading, confirmEdit, editing, confirmCancel, canceling, confirmArchive, archiving, generatingFile, showUploadInvoice} = this.state;
        const {t, title, requestSettings} = this.props;

        const Status = () => {

            if(request.transaction.status === IN_PROGRESS_STATUS){
               return <InProgressDetail request={request}
                                        offerToken={this.props.match.params.request}/>
            }
            else if(request.transaction.status === ACCEPTED_STATUS){
               return <AcceptedDetail request={request} generatingFile={generatingFile}
                                      onCancel={() => this.setState({confirmCancel: true})}
                                      onEdit={() => this.setState({confirmEdit: true})}
                                      onGenerateInvoice={() => this.generateFile('invoice')}/>
            }
            else if(request.transaction.status === REJECTED_STATUS){
               return <RejectedDetail onArchive={() => this.setState({confirmArchive: true})}
                                      onEdit={() => this.setState({confirmEdit: true})}
                                      request={request}
                                      offerToken={this.props.match.params.request}/>
            }

            return <CanceledDetail request={request}/>
        }

        return (
            <React.Fragment>
                <Header title={t(title)}/>

                <div className="container container--has-submit-container">

                    <div className="request-details">

                        <h2>{t(title)}</h2>

                        {loading ? <ListLoader stat={true}/> :
                            <div>
                                <div className="button-navigation">

                                    <div className="row">
                                        <div className="col-lg-4">
                                            {(canSendInvoice(request.transaction.status) && !request.invoice) &&
                                                <LinkIcon icon={"icon-export"} left={true} text={t('pages.request_details.upload_invoice_label')} onClick={() => this.setState({showUploadInvoice: true})}/>
                                            }
                                        </div>
                                        <div className="col-lg-4">
                                            <LinkIcon icon={"icon-download"} left={true} loading={generatingFile === 'xml'} disabled={generatingFile === 'xml'} text={t('pages.request_details.export_xml')} onClick={() => this.generateFile('xml')}/>
                                        </div>
                                        <div className="col-lg-4">
                                            <LinkIcon icon={"icon-download"} left={true} loading={generatingFile === 'pdf'} disabled={generatingFile === 'pdf'} text={t('pages.request_details.export_pdf')} onClick={() => this.generateFile('pdf')}/>
                                        </div>
                                    </div>

                                </div>

                                <Status />

                                <div className="row">
                                    <div className="col-lg-6">
                                        <RequestOverview request={request} onEdit={() => this.setState({confirmEdit: true})}/>
                                    </div>
                                    <div className="col-lg-6">
                                        <CarDetails request={request}/>
                                    </div>
                                </div>

                                <RequestSpecification
                                    labours={request.labours}
                                    tireLabours={request.tireLabours}
                                    tires={request.tires}
                                    settings={requestSettings}
                                    recommendedOil={request.recommended_oil}
                                />

                            </div>
                        }
                    </div>
                </div>

                {confirmEdit &&
                    <Confirm
                        title={t('pages.request_details.edit')}
                        acceptText={t('global.yes')}
                        cancelText={t('global.no')}
                        visible={!!confirmEdit}
                        accept={this.onEdit}
                        cancel={() => this.setState({confirmEdit: false})}
                        loading={editing} disabled={editing}
                    >
                        <div className="mv-30">{t('pages.request_details.edit_desc')}</div>
                    </Confirm>
                }

                {confirmCancel &&
                    <Confirm
                        title={t('pages.request_details.cancel')}
                        acceptText={t('global.yes')}
                        cancelText={t('global.no')}
                        visible={!!confirmCancel}
                        accept={this.onCancel}
                        cancel={() => this.setState({confirmCancel: false})}
                        loading={canceling} disabled={canceling}
                    >
                        <div className="mv-30">{t('pages.request_details.cancel_desc')}</div>
                    </Confirm>
                }

                {confirmArchive &&
                    <Confirm
                        title={t('pages.request_details.archive')}
                        acceptText={t('global.yes')}
                        cancelText={t('global.no')}
                        visible={!!confirmArchive}
                        accept={this.onArchive}
                        cancel={() => this.setState({confirmArchive: false})}
                        loading={archiving} disabled={archiving}
                    >
                        <div className="mv-30">{t('pages.request_details.archive_desc')}</div>
                    </Confirm>
                }

                {(showUploadInvoice && canSendInvoice(request.transaction.status)) &&
                    <UploadInvoice
                        request={request}
                        onUpload={(response) => {
                            this.setState({showUploadInvoice: false, request: {...request, invoice: response}})
                        }}
                        onClose={() => this.setState({showUploadInvoice: false})}
                    />
                }

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        requestSettings: state.requestSettings,
    };
};

export default connect(mapStateToProps)(translate('translations')(RequestsDetailsContainer));
