import React, {Component} from 'react';
import {connect} from 'react-redux';
import {translate} from 'react-i18next';
import Header from "components/layout/Header/Header";
import GarageDetails from "./GarageDetails";

class SettingsContainer extends Component {
    render() {
        const {t, title} = this.props;

        return (
            <React.Fragment>

                <Header title={t(title)}/>

                <div className="container">

                    <GarageDetails/>

                </div>

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loader: state.loader,
        user: state.user,
        garage: state.garage
    };
};

export default connect(mapStateToProps)(translate('translations')(SettingsContainer));
