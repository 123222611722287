import React, {Component} from 'react'
import {translate} from 'react-i18next'
import {Popup} from "components/shared/Popup/Popup";
import InputRadio from "components/shared/InputRadio";
import InputCheckbox from "components/shared/InputCheckbox";
import BaseCountry from "utils/BaseCountry";
import {WORK_TYPE_CHECKBOX} from "store/consts/labours/work";
import {LABOUR_TYPE_OFFICIAL} from "store/consts/labours/labours.constants";
import {__clone, compare, fixedFloat, parseResponse} from "../../../../utils/common";
import Button from "../../../../components/shared/Button/Button";
import {toast} from "react-toastify";
import Toast from "../../../../components/shared/Toast/Toast";
import classNames from "classnames";
import Input from "../../../../components/shared/Input/Input";

class LabourEdit extends Component {

    constructor(props) {
        super(props);

        this.state = {
            labour: __clone(props.labour),
            updating: false,
            partSetAmountEdit: null,
            partSetPriceEdit: null,
        }
    }

    workUpdate = (e, work) => {
        const {labour} = this.state;

        this.setState({
            labour: {
                ...labour,
                works: labour.works.map(w => {
                    w.selected = w.id === work.id;

                    return w;
                })
            }
        })
    }

    partUpdate = (e, work, part) => {
        const {labour} = this.state;

        this.setState({
            labour: {
                ...labour,
                works: labour.works.map(w => {
                    if(w.id === work.id){
                        w.parts = w.parts.map(p => {
                            if(p.id === part.id){

                                p.selected = e.target.checked;

                                if(e.target.checked){
                                    p.versions = p.versions.slice(0, 1).map(v => {
                                        v.selected = e.target.checked;

                                        v.set = v.set.map(s => {
                                            s.selected = e.target.checked;

                                            return s;
                                        })

                                        return v;
                                    }).concat(p.versions.slice(1, p.versions.length))
                                }
                                else {
                                    p.versions = p.versions.map(v => {
                                        v.selected = e.target.checked;

                                        v.set = v.set.map(s => {
                                            s.selected = e.target.checked;

                                            return s;
                                        })

                                        return v;
                                    })
                                }
                            }

                            return p;
                        })
                    }

                    return w;
                })
            }
        })
    }

    partVersionUpdate = (e, work, part, version) => {
        const {labour} = this.state;

        this.setState({
            labour: {
                ...labour,
                works: labour.works.map(w => {
                    if(w.id === work.id){
                        w.parts = w.parts.map(p => ({
                            ...p,
                            selected: p.id === part.id ? true : part.selected,
                            versions: p.versions.map(v => (p.id === part.id ? {
                                ...v,
                                selected: v.id === version.id,
                                set: v.set.map(s => ({
                                    ...s,
                                    selected: v.id === version.id,
                                }))
                            } : {...v}))
                        }))
                    }

                    return w;
                })
            }
        })
    }

    partVersionUpdateSet = (e, work, part, version, set) => {
        const {labour} = this.state;

        this.setState({
            labour: {
                ...labour,
                works: labour.works.map(w => {
                    if(w.id === work.id){
                        w.parts = w.parts.map(p => {
                            if(p.id === part.id){

                                p.selected = true;

                                p.versions = p.versions.map(v => {
                                    v.selected = v.id === version.id;

                                    v.set = v.set.map(s => {
                                        if(v.id === version.id){
                                            if(s.id === set.id){
                                                s.selected = e.target.checked;
                                            }
                                        }
                                        else {
                                            s.selected = false;
                                        }

                                        return s;
                                    })

                                    return v;
                                })
                            }

                            return p;
                        })
                    }

                    return w;
                })
            }
        })
    }

    partSetAmountsUpdate = (work, part, version, set, value) => {
        const {labour} = this.state;

        this.setState({
            partSetAmountEdit: null,

            labour: {
                ...labour,
                works: labour.works.map(w => {
                    if(w.id === work.id){
                        w.parts = w.parts.map(p => {
                            if(p.id === part.id){

                                p.selected = true;

                                p.versions = p.versions.map(v => {
                                    v.selected = v.id === version.id;

                                    v.set = v.set.map(s => {
                                        if(s.id === set.id){
                                            s.amounts = value;
                                        }

                                        return s;
                                    })

                                    return v;
                                })
                            }

                            return p;
                        })
                    }

                    return w;
                })
            },
        })
    }

    partSetPriceUpdate = (work, part, version, set, value) => {
        const {labour} = this.state;

        this.setState({
            partSetPriceEdit: null,

            labour: {
                ...labour,
                works: labour.works.map(w => {
                    if(w.id === work.id){
                        w.parts = w.parts.map(p => {
                            if(p.id === part.id){

                                p.selected = true;

                                p.versions = p.versions.map(v => {
                                    v.selected = v.id === version.id;

                                    v.set = v.set.map(s => {
                                        if(s.id === set.id){
                                            s.price = value.toString().parseFloat();
                                        }

                                        return s;
                                    })

                                    return v;
                                })
                            }

                            return p;
                        })
                    }

                    return w;
                })
            },
        })
    }

    onUpdate = () => {
        const {onUpdate, onClose} = this.props;
        const {labour} = this.state;

        this.setState({updating: true})

        onUpdate(labour)
            .then(() => onClose())
            .catch((error) => toast.error(<Toast text={parseResponse(error.response)} type="error"/>))
            .finally(() => this.setState({updating: true}))
    }

    render() {
        const {t, onClose} = this.props;
        const {labour, updating, partSetAmountEdit, partSetPriceEdit} = this.state;

        const Work = ({work}) => (
            <div className="labour-edit-work labour-edit-line">
                {(labour.works.length === 1 || work.input_type === WORK_TYPE_CHECKBOX) ?
                    <span>
                        <span>{work.name}</span>
                        <span className="vertical-text-delimiter">|</span>
                        <span className="bold">{work.duration}h</span>
                    </span>
                    :
                    <InputRadio
                        id={work.id}
                        name={"works"}
                        value={work.id}
                        label={
                            <span>
                                <span>{work.name}</span>
                                <span className="vertical-text-delimiter">|</span>
                                <span className="bold">{work.duration}h</span>
                            </span>
                        }
                        checked={work.selected}
                        onChange={(e) => this.workUpdate(e, work)}
                        disabled={labour.type === LABOUR_TYPE_OFFICIAL}
                    />
                }

                {work.parts.map(part => <Part key={part.id} work={work} part={part}/>)}

            </div>
        )

        const Part = ({work, part}) => (
            <div className={classNames('labour-edit-part labour-edit-line', {
                'not-selected': !part.selected,
            })}>
                {work.parts.length > 1 &&
                    <InputCheckbox
                        id={`parts-${part.id}`}
                        name={`parts-${part.id}`}
                        value={part.id}
                        onChange={(e) => this.partUpdate(e, work, part)}
                        checked={part.selected}
                        label={part.name}
                        disabled={work.parts.length === 1}
                    />
                }

                {part.versions.map(version => <PartVersion key={version.id} work={work} part={part} version={version}/>)}
            </div>
        )

        const PartVersion = ({work, part, version}) => (
            <div className="labour-edit-part-version labour-edit-line">
                <div className="labour-edit-part-version-option">
                    <div className="labour-edit-part-version-option__radio">
                        <InputRadio
                            id={`versions-${version.id}`}
                            name={`versions-${version.id}`}
                            value={version.id}
                            checked={version.selected}
                            onChange={(e) => this.partVersionUpdate(e, work, part, version)}
                        />
                    </div>
                    <div className="labour-edit-part-version-option__set">
                        <PartVersionSets work={work} part={part} version={version} />
                    </div>
                </div>
            </div>
        )

        const PartVersionSets = ({work, part, version}) => (
            <div className="labour-edit-part-version-set labour-edit-line">
                <div className="labour-edit-part-header">
                    <div className="col-sm-5">{version.name}</div>
                    <div className="col-sm-3">{t('pages.form_steps.overview.request_specification.serial_number')}</div>
                    <div className="col-sm-2">{t('pages.form_steps.overview.request_specification.quantity')}</div>
                    <div className="col-sm-2">{t('pages.form_steps.overview.request_specification.price')}</div>
                </div>
                {version.set.map(set =>
                    <div className="labour-edit-part-content" key={set.id}>
                        <div className="col-sm-5">
                            <InputCheckbox
                                id={`sets-${set.id}`}
                                name={`sets-${set.id}`}
                                value={set.id}
                                label={set.name}
                                checked={set.selected}
                                onChange={(e) => this.partVersionUpdateSet(e, work, part, version, set)}
                            />
                        </div>
                        <div className="col-sm-3">{set.partNo}</div>
                        <div className="col-sm-2">
                            {(partSetAmountEdit === set.id) ?
                                <div>
                                    <Input
                                        type="text"
                                        name={`sets-amounts-${set.id}`}
                                        value={set.amounts}
                                        float={true}
                                        confirmEdit={true}
                                        extraClass="form-group-sm"
                                        onEditAccept={(value) => this.partSetAmountsUpdate(work, part, version, set, value)}
                                        onEditDecline={() => this.setState({partSetAmountEdit: null})}
                                    />
                                </div>
                                :
                                <>
                                    {set.amounts}
                                    {(set.selected && !set.amounts) && <i className="icon-edit" onClick={() => this.setState({partSetAmountEdit: set.id})}/>}
                                </>
                            }
                        </div>
                        <div className="col-sm-2">
                            {(partSetPriceEdit === set.id) ?
                                <div>
                                    <Input
                                        type="text"
                                        name={`sets-price-${set.id}`}
                                        value={set.price}
                                        float={true}
                                        confirmEdit={true}
                                        extraClass="form-group-sm"
                                        onEditAccept={(value) => this.partSetPriceUpdate(work, part, version, set, value)}
                                        onEditDecline={() => this.setState({partSetPriceEdit: null})}
                                    />
                                </div>
                                :
                                <>
                                    {fixedFloat(set.price)} <span className="currency">{BaseCountry().currency}</span>
                                    {(set.selected && !set.price) && <i className="icon-edit" onClick={() => this.setState({partSetPriceEdit: set.id})}/>}
                                </>
                            }
                        </div>
                    </div>
                )}

            </div>
        )

        let works = labour.works.filter(work => work.parts.length > 0);

        if(works.length === 0){
            works = labour.works;
        }

        return (
            <React.Fragment>

                <Popup title={`${t('global.edit')} ${labour.name}`}
                       responsive={true} visible={true} onClose={onClose}>

                    <div className="labour-edit">
                        {works.map((work) => <Work key={work.id} work={work}/>)}
                    </div>

                    <div className="text-right mt-30">
                        <span className="next-to-btn-sm link-style" onClick={onClose}>{t('global.cancel')}</span>
                        <Button size="sm" type="primary" disabled={!compare(this.props.labour, labour) || updating} loading={updating}
                                onClick={this.onUpdate}>{t('global.save')}</Button>
                    </div>
                </Popup>

            </React.Fragment>
        )
    }
}

export default translate('translations')(LabourEdit);
