import React, {Component} from 'react';
import {translate} from "react-i18next";
import InputCheckbox from "../shared/InputCheckbox";
import Select from "../shared/Select/Select";
import classNames from 'classnames';

class TiresLabour extends Component {

    handleChange = (name, checked) => {
        const {handleChangeLabourSelect} = this.props;

        handleChangeLabourSelect(name, checked)
    }

    handleChangeLabour = (name, option) => {
        const {handleChangeLabourSelectLabour} = this.props;

        handleChangeLabourSelectLabour(name, option)
    }

    render() {
        const {labours} = this.props;

        return (
            <React.Fragment>

                <div className="tires-labour">
                    <div className="row tires-axle-item">
                        {labours.map((labourGroup) => (
                            <TiresLabourItem
                                labourGroup={labourGroup}
                                key={labourGroup.id}
                                onChange={this.handleChange}
                                onChangeLabour={this.handleChangeLabour}
                            />
                        ))}
                    </div>
                </div>

            </React.Fragment>
        )
    }
}

class TiresLabourItem extends Component {

    render() {
        const {labourGroup, onChange, onChangeLabour} = this.props;

        return (
            <div >
                <div className={classNames("col-xs-12 col-sm-3", {
                    'col-sm-12': labourGroup.labours.length === 1,
                })}>
                    <InputCheckbox
                        id={`tire-${labourGroup.id}-select`}
                        name={`tire-${labourGroup.id}-select`}
                        value={labourGroup.id}
                        checked={labourGroup.checked}
                        onChange={(e) => onChange(e.target.value.parseFloat(), e.target.checked)}
                        label={labourGroup.labours[0].name}
                    />
                </div>
                {labourGroup.labours.length > 1 &&
                <div className="col-xs-12 col-sm-6">
                    <Select
                        id={`tire-${labourGroup.id}-type`}
                        list={labourGroup.labours}
                        selected={labourGroup.selected}
                        onChange={(id, option) => onChangeLabour(labourGroup.id, option)}
                        size={'sm'}
                        isLoading={!labourGroup.labours.length}
                        disabled={!labourGroup.checked || !labourGroup.labours.length}
                    />
                </div>
                }
            </div>
        )
    }
}

export default translate('translations')(TiresLabour);
