import React, {Component} from "react";
import {connect} from "react-redux";
import {translate} from "react-i18next";
import "./index.scss";
import Header from "../../components/layout/Header/Header";
import Select from "../../components/shared/Select/Select";
import { fetchRequests } from "../../store/actions/requestsList.actions"
import axios from "../../app/config/axios";
import {toast} from "react-toastify";
import Toast from "../../components/shared/Toast/Toast";
import {parseResponse, roundCeil} from "../../utils/common";
import history from "../../routes/history";
import pages from "../../app/consts/routes";

const GENERATE_PDF_URL = 'garage/form/generate-file';

class RequestsContainer extends Component {

    constructor(props) {
        super(props)

        this.state = {
            currentPage: 1,
            pages: null,
            statusList: null,
            search: '',
            filterStatus: 6,
            generating: false,
        };
    }

    componentDidMount() {
        this.props.dispatch(fetchRequests(this.state.currentPage, this.state.filterStatus))
    }

    generateFile = (e, token) => {
        e.stopPropagation()

        this.setState({generating: true})

        axios.post(GENERATE_PDF_URL, {
            type: 'pdf',
            offer_token: token
        })
            .then(response => {
                window.open(response.data.link);
            })
            .catch(error => {
                toast.error(<Toast text={parseResponse(error.response)} type="error"/>)
            })
            .finally(() => this.setState({generating: false}))
    }

    handleIncrementPagination = () => {
        let pageValue = parseInt(this.state.currentPage) + 1

        if (this.props.requestsList.requestsList.meta.last_page < pageValue) return

        this.setState( {
            currentPage:  pageValue
        })
        this.props.dispatch(fetchRequests(pageValue, this.state.filterStatus))
    }

    handleDecrementPagination = () => {
        if (this.state.currentPage === 1) return
        let pageValue = parseInt(this.state.currentPage) - 1

        this.setState( {
            currentPage:  pageValue
        })
        this.props.dispatch(fetchRequests(pageValue, this.state.filterStatus))
    }

    handleSetCurrentPage = (e) => {
        this.setState({
            currentPage: e.target.value
        })

        this.props.dispatch(fetchRequests(e.target.value, this.state.filterStatus))
    }

    handleSetFirstPage = () => {
        this.setState({
            currentPage: 1
        })

        this.props.dispatch(fetchRequests(1, this.state.filterStatus))
    }

    handleSetLastPage = () => {

        let currentPage = this.props.requestsList.requestsList.meta.last_page

        this.setState({
            currentPage: currentPage
        })

        this.props.dispatch(fetchRequests(currentPage, this.state.filterStatus))
    }

    handleChangeStatusFilter = (label, index) => {
        this.setState({
            filterStatus: index.id,
            currentPage: 1
        })
        this.props.dispatch(fetchRequests(1, index.id))
    }

    renderLicencePlate = str => {
        return (str) ? str.substring(0, 2) + ` • ` + str.substring(2, str.length) : ''
    }

    renderRequestItem = (item, index) => {

        return (
            <tr key={item.id} className="requests__table-ior-item" onClick={() => history.push(`${pages.request_details}${item.token}`)}>
                <td className="requests__table-status">
                    <div className="requests__table-status-emblem-container">
                        {item.instant_offer_request_transaction.status === 1 &&
                            <div className="pending-status-emblem">
                                <i className="icon-info"/>
                            </div>
                        }

                        {item.instant_offer_request_transaction.status === 2 &&
                            <div className="approved-status-emblem">
                                <i className="icon-checkbox"/>
                            </div>
                        }

                        {item.instant_offer_request_transaction.status === 3 &&
                            <div className="denied-status-emblem">
                                <i className="icon-close"/>
                            </div>
                        }

                        {item.instant_offer_request_transaction.status === 4 &&
                            <div className="denied-status-emblem">
                                <i className="icon-close"/>
                            </div>
                        }
                    </div>
                    <div className="requests__table-status-label-container">
                        <div>{`${this.props.t('pages.requests.table.status_' + item.instant_offer_request_transaction.status)}`}</div>
                    </div>
                    <div className="requests__table-status-archived-container">
                        {item.is_archived &&
                            <i className="icon-archive"/>
                        }
                    </div>
                </td>
                <td className="request__table-leasing-provider">
                    {item.leasing.name}
                </td>
                <td className="request__table-car-info">
                    <div>
                        {item.instant_offer_car.make} {item.instant_offer_car.range}
                    </div>
                    <div className="requests__table-licence-plate">
                        {this.renderLicencePlate(item.instant_offer_car.licence_plate)}
                    </div>
                </td>
                <td className="request__table-details">
                    {roundCeil(item.request_details, true)} <span className="requests__table-currency">{`${this.props.t('global.currency')}`}</span>
                </td>
                <td>
                    {item.created_at}
                </td>
                <td className="requests__table-download">
                    <i onClick={(e) => this.generateFile(e, item.token)} className="icon-download"/>
                </td>
            </tr>
        )
    }

    render() {
        const {t, title, requestsList} = this.props

        const {currentPage, filterStatus } = this.state

        const requestStatuses = [
            {
                id: 6,
                name: t('pages.requests.all')
            },
            {
                id: 1,
                name: t('pages.requests.pending_status')
            },
            {
                id: 2,
                name: t('pages.requests.approved_status')
            },
            {
                id: 3,
                name: t('pages.requests.denied_status')
            },
            {
                id: 5,
                name: t('pages.requests.archived_status')
            },
        ]

        return (
            <React.Fragment>
                <Header title={t(title)}/>

                <div className="container requests-list">

                    <div className="col-sm-6">
                        <h2>{`${t('pages.requests.title')}`}</h2>
                    </div>
                    <div className="col-sm-6 requests-list-filter">

                        <div className="filter-container filter-label">
                            <span>{`${t('pages.requests.filter_label')}`}</span>
                        </div>

                        <div className="filter-container">
                            <Select
                                id="request_status_id"
                                list={requestStatuses}
                                selected={filterStatus}
                                onChange={(id, option) => this.handleChangeStatusFilter(id, option)}
                                disableFilter={true}
                                size="sm"
                            />
                        </div>
                    </div>

                    <div className="col-sm-12">

                        {requestsList.requestsLoadingStatus && requestsList.requestsList.data.length > 0 ?
                            <div className="requests__table-container">
                                <table className="requests__table">
                                    <thead>
                                    <tr className="requests__table-header">
                                        <th>{`${t('pages.requests.table.status')}`}</th>
                                        <th>{`${t('pages.requests.table.leasing_provider')}`}</th>
                                        <th>{`${t('pages.requests.table.car_info')}`}</th>
                                        <th>{`${t('pages.requests.table.request_details')}`}</th>
                                        <th>{`${t('pages.requests.table.date')}`}</th>
                                        <th className="requests__table-pdf-column">{`${t('pages.requests.table.pdf')}`}</th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    {requestsList.requestsLoadingStatus && requestsList.requestsList.data.map(this.renderRequestItem)}

                                    </tbody>
                                </table>
                            </div>
                            :
                            <div className="requests-list__empty-result">{`${t('pages.requests.no_result')}`}</div>
                        }
                    </div>

                    {(requestsList.requestsLoadingStatus && requestsList.requestsList.data.length > 0 && requestsList.requestsList.meta.last_page > 1) &&
                    <div className="pagination-container">
                        <i className="icon-forward-last" onClick={this.handleSetLastPage}/>
                        <i className="icon-right" onClick={this.handleIncrementPagination}/>
                        <input type="text" value={currentPage} onChange={this.handleSetCurrentPage} />
                        <i className="icon-left" onClick={this.handleDecrementPagination}/>
                        <i className="icon-previous-first" onClick={this.handleSetFirstPage}/>
                    </div>
                    }


                </div>

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        requests: state.requests,
        loader: state.loader,
        requestsList: state.requestsList
    };
};

export default connect(mapStateToProps)(translate('translations')(RequestsContainer));
