import axios from "../../app/config/axios";
import {tiresNewSet} from "./tiresNew.actions";
import tiresProcessModel from '../models/tiresProcess';

export const TIRE_LABOURS = '[Tire Labours]'

export const TIRE_LABOURS_SET = `${TIRE_LABOURS} SET`;
export const TIRE_LABOURS_RESET = `${TIRE_LABOURS} RESET`;

const TIRE_LABOURS_UPDATE_URL = 'garage/form/tire-labours-update'

export const tireLaboursSet = (labours) => ({
    type: TIRE_LABOURS_SET,
    payload: {
        labours
    }
});

export const tireLaboursReset = () => ({
    type: TIRE_LABOURS_RESET,
});

export const tireLaboursUpdate = (tires, labours) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            axios
                .post(TIRE_LABOURS_UPDATE_URL, {
                    tires: tires ? tires : tiresProcessModel.tires,
                    labours: labours,
                })
                .then(response => {
                    dispatch(tireLaboursSet(response.data.labours))
                    dispatch(tiresNewSet(response.data.tires))

                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}
