export const SCREEN_MD_MIN = 768

export function compare(item1, item2) {
    return JSON.stringify(item1) !== JSON.stringify(item2)
}

export function getClassName(baseClass = '', isTrue = false, classIfTrue = '', classIfFalse = '') {
    return isTrue === true ? `${baseClass} ${classIfTrue}` : `${baseClass} ${classIfFalse}`
}

export function itemAlreadyExist(array, item) {
    return JSON.stringify(array).includes(JSON.stringify(item))
}

export function __clone(object) {
    return JSON.parse(JSON.stringify(object))
}

export function isMobileView() {
    return window.innerWidth < 768
}

export function toFixed(val, dec) {
    return parseFloat(val.toFixed(dec))
}

export function isUncheckButtonDisabled(filteredList) {
    return (
        filteredList.filter(
            service => service.exist === true && service.is_official_selected === false && service.selected === true,
        ).length === 0
    )
}

export function arrayColumn(input, ColumnKey, IndexKey = null) {
    if (input !== null && (typeof input === 'object' || Array.isArray(input))) {
        var newarray = []
        if (typeof input === 'object') {
            let temparray = []
            for (let key of Object.keys(input)) {
                temparray.push(input[key])
            }
            input = temparray
        }
        if (Array.isArray(input)) {
            for (let key of input.keys()) {
                if (IndexKey && input[key][IndexKey]) {
                    if (ColumnKey) {
                        newarray[input[key][IndexKey]] = input[key][ColumnKey]
                    } else {
                        newarray[input[key][IndexKey]] = input[key]
                    }
                } else {
                    if (ColumnKey) {
                        newarray.push(input[key][ColumnKey])
                    } else {
                        newarray.push(input[key])
                    }
                }
            }
        }

        return Object.assign({}, newarray)
    }
}

export const intersect = (o1, o2) => {
    return Object.keys(o1)
        .concat(Object.keys(o2))
        .sort()
        .reduce(function (r, a, i, aa) {
            if (i && aa[i - 1] === a) {
                r.push(a)
            }
            return r
        }, [])
}

export const parseResponse = response => {
    if (!response) {
        return ''
    }

    if (!response.hasOwnProperty('data')) {
        return ''
    }
    if (response.data.hasOwnProperty('errors') && typeof response.data.errors === 'object') {
        return response.data.errors[Object.keys(response.data.errors)[0]][0]
    }

    if (response.data.hasOwnProperty('message')) {
        return response.data.message
    }

    return ''
}

export const urlSearchParams = (name, href) => {
    if (!href) {
        href = window.location.href
    }

    let url = new URL(href)
    let params = new URLSearchParams(url.search.slice(1))

    return params.get(name)
}

export const updateObject = (object, update, path) => {
    let elements = path.split('/')

    let result = __clone(object)
    let newObject = __clone(object)
    let arrayWalk = []

    for (let element in elements) {
        let params = elements[element].split(':')
        if (params.length > 1) {
            let find = result.find((item, i) => {
                if (item[params[0]] == params[1]) {
                    elements[element] = i
                    return item
                }
                return null
            })
            if (find) {
                result = find
                arrayWalk.push(result)
            } else break
        } else {
            result = result[elements[element]]
            arrayWalk.push(result)
        }
    }

    arrayWalk[arrayWalk.length - 1] = update
    let reverseWalk = arrayWalk.reverse()
    elements = elements.reverse()

    for (let key in reverseWalk) {
        if (key < elements.length - 1) {
            reverseWalk[parseInt(key) + 1][elements[key]] = reverseWalk[key]
        } else {
            newObject[elements[key]] = reverseWalk[reverseWalk.length - 1]
        }
    }

    return newObject
}

export const removeDeep = (object, path) => {
    let elements = path.split('/')

    let result = __clone(object)
    let newObject = __clone(object)
    let arrayWalk = []

    for (let element in elements) {
        let params = elements[element].split(':')
        if (params.length > 1) {
            let find = result.find((item, i) => {
                if (item[params[0]] == params[1]) {
                    elements[element] = i
                    return item
                }
                return null
            })
            if (find) {
                result = find
                arrayWalk.push(result)
            } else break
        } else {
            result = result[elements[element]]
            arrayWalk.push(result)
        }
    }

    if (arrayWalk.length > 1) arrayWalk[arrayWalk.length - 1] = []

    let reverseWalk = arrayWalk.reverse()

    if (reverseWalk.length > 1) reverseWalk[1].splice(elements[elements.length - 1], 1)

    elements = elements.reverse()

    for (let key in reverseWalk) {
        if (key < elements.length - 1) {
            if (!reverseWalk[key].length) {
                continue
            }

            reverseWalk[parseInt(key) + 1][elements[key]] = reverseWalk[key]
        } else {
            newObject[elements[key]] = reverseWalk[reverseWalk.length - 1]
        }
    }

    return newObject
}

export const deepObject = (object, path) => {
    let elements = path.split('/')

    let result = object

    for (let element in elements) {
        let params = elements[element].split(':')
        if (params.length > 1) {
            let find = result.find(item => {
                if (item[params[0]] == params[1]) {
                    return item
                }

                return null
            })
            if (find) {
                result = find
            } else break
        } else {
            result = result[elements[element]]
        }
    }

    return result
}

export const parseDeepPath = path => {
    return path
        .split('/')
        .map(i => i.split(':'))
        .map(i => (i.length > 1 ? {[i[0]]: i[1]} : i[0]))
}

export const roundCeil = (number, forPrint) => {
    let float = parseFloat((Math.ceil(parseFloat(number) * 20 - 0.5) / 20).toFixed(2))

    if (forPrint) {
        return float.toFixed(2) //.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }

    return float
}

export const fixedFloat = number => {
    return number.toFixed(2)
}

export const b64toBlob = (b64, onsuccess, onerror) => {
    let img = new Image()

    img.onerror = onerror

    img.onload = function onload() {
        let canvas = document.createElement('canvas')
        canvas.width = img.width
        canvas.height = img.height

        let ctx = canvas.getContext('2d')
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height)

        canvas.toBlob(onsuccess)
    }

    img.src = b64
}

export const imgDimFromUrl = imageUrl => {
    return new Promise(resolve => {
        let img = new Image()
        img.src = imageUrl
        img.onload = function (e) {
            resolve({width: this.width, height: this.height})
        }
    })
}

export const ext = name => {
    return name
        .split('.')
        .pop()
        .toLowerCase()
}

export const monthsRange = range => {
    let monthRange = []

    for (let i = 0; i < range; i++) {
        let d = new Date()
        d.subMonths(i)
        monthRange.unshift(d.getMonth())
    }

    return monthRange
}

export const todayTimestamp = () => {
    let d = new Date()
    d.setHours(0, 0, 0)

    return Math.floor(d.getTime() / 1000)
}

export const yesterdayTimestamp = () => {
    return todayTimestamp() - 86400
}

export const thisWeekTimestamp = () => {
    return todayTimestamp() - (new Date().getDay() - 1) * 86400
}

export const stringToBool = value => {
    if (value === 'true') {
        return true
    } else if (value === 'false') {
        return false
    }

    return null
}

export const setCountryCode = value => {
    let code = document.location.host.split('.').pop()

    return value.replace('{code}', process.env.REACT_APP_COUNTRY_CODES.split(',').indexOf(code) !== -1 ? code : 'test')
}

export const timestamp = () => Math.round((new Date()).getTime() / 1000)

export const isDate = (value) => /^(\d{2,2})(\.|\-|\/)(\d{2,2})(\.|\-|\/)(\d{4,4})$/.test(value)

export const toBool = val => !!val;

export const scrollToLastThird = e => e.scrollTop > (e.scrollHeight - e.offsetHeight) - (e.scrollHeight - e.offsetHeight) * 0.3;

export const firstDayOfMonth = () => new Date((new Date).getFullYear(), (new Date).getMonth(), 1);
export const lastDayOfMonth = () => new Date((new Date).getFullYear(), (new Date).getMonth() + 1, 0);

export const dateFormat = (date, delimiter = '.') => {
    let d = new Date(date);

    return d.getDate() + delimiter + (d.getMonth() + 1) + delimiter + d.getFullYear();
}
export const timeFormat = (date) => {
    let d = new Date(date);
    let hours = d.getHours();
    let minutes = (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();

    return hours + ':' + minutes;
}

export default {
    compare,
    getClassName,
    SCREEN_MD_MIN,
    arrayColumn,
    intersect,
    urlSearchParams,
    parseResponse,
    updateObject,
    deepObject,
    removeDeep,
    roundCeil,
    ext,
    b64toBlob,
    monthsRange,
    todayTimestamp,
    yesterdayTimestamp,
    stringToBool,
    timestamp,
    isDate,
    toBool,
    timeFormat
}
