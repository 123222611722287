import React, {Component} from 'react';
import './FormStepCounter.scss';
import {translate} from "react-i18next";
import {connect} from "react-redux";
import classNames from "classnames";
import {FORM_STEPS} from "../../store/consts/step.constants";

class FormStepCounter extends Component {

    render() {
        const {step: {activeStep}, short, t} = this.props;

        return (
            <React.Fragment>
                <div className="form-step-counter">

                    {FORM_STEPS.map(step => <Step t={t} step={step} key={step} short={short} active={activeStep === step}/>)}

                </div>
            </React.Fragment>
        )
    }
}

const Step = ({t, step, active, short}) => {
    return (
        <div className="form-step-item">
            <div className={classNames("form-step-item__index", {'active': active})}>{step}</div>
            {!short && <div className={classNames("form-step-item__title", {'active': active})}>{t(`form_steps.${step}`)}</div>}
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        step: state.step
    };
};

export default connect(mapStateToProps)(translate('translations')(FormStepCounter));
