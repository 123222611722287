import pages from 'app/consts/routes'
import SettingsContainer from 'views/SettingsContainer'
import UserProfileContainer from 'views/UserProfileContainer'
import RequestsContainer from 'views/RequestsContainer'
import RequestDetailsContainer from 'views/RequestDetailsContainer'
import {
    USER_ROLE_SUPERVISOR,
    USER_ROLE_MANAGER,
    USER_ROLE_MECHANIC,
    USER_ROLE_MECHANIC_ADVANCED,
} from '../store/consts/user.constants'
import CarStepContainer from "../views/FormSteps/CarStepContainer/CarStepContainer";
import OfficialServiceStepContainer from "../views/FormSteps/OfficialServiceStepContainer/OfficialServiceStepContainer";
import LaboursStepContainer from "../views/FormSteps/LaboursStepContainer/LaboursStepContainer";
import OverviewStepContainer from "../views/FormSteps/OverviewStepContainer/OverviewStepContainer";
import ReviewStepContainer from "../views/FormSteps/RequestReviewStepContainer/ReviewStepContainer";
import TiresStepContainer from "../views/FormSteps/TiresStepContainer/TiresStepContainer";

export const formStepRoutes = [
    {
        name: 'Car Step',
        path: `${pages.form_steps.car}`,
        component: CarStepContainer,
        exact: true,
        roles: [USER_ROLE_SUPERVISOR, USER_ROLE_MANAGER, USER_ROLE_MECHANIC, USER_ROLE_MECHANIC_ADVANCED],
        headerTitle: 'pages.form_steps.car.title',
        pageTitle: 'titles.form_steps.car',
    },
    {
        name: 'Official Service Step',
        path: `${pages.form_steps.official_service}`,
        component: OfficialServiceStepContainer,
        exact: true,
        roles: [USER_ROLE_SUPERVISOR, USER_ROLE_MANAGER, USER_ROLE_MECHANIC, USER_ROLE_MECHANIC_ADVANCED],
        headerTitle: 'pages.form_steps.official_service.title',
        pageTitle: 'titles.form_steps.official_service',
    },
    {
        name: 'Labours Step',
        path: `${pages.form_steps.labours}`,
        component: LaboursStepContainer,
        exact: true,
        roles: [USER_ROLE_SUPERVISOR, USER_ROLE_MANAGER, USER_ROLE_MECHANIC, USER_ROLE_MECHANIC_ADVANCED],
        headerTitle: 'pages.form_steps.labours.title',
        pageTitle: 'titles.form_steps.labours',
    },
    {
        name: 'Tires Step',
        path: `${pages.form_steps.tires}`,
        component: TiresStepContainer,
        exact: true,
        roles: [USER_ROLE_SUPERVISOR, USER_ROLE_MANAGER, USER_ROLE_MECHANIC, USER_ROLE_MECHANIC_ADVANCED],
        headerTitle: 'pages.form_steps.tires.title',
        pageTitle: 'titles.form_steps.tires',
    },
    {
        name: 'Overview Step',
        path: `${pages.form_steps.overview}`,
        component: OverviewStepContainer,
        exact: true,
        roles: [USER_ROLE_SUPERVISOR, USER_ROLE_MANAGER, USER_ROLE_MECHANIC, USER_ROLE_MECHANIC_ADVANCED],
        headerTitle: 'pages.form_steps.overview.title',
        pageTitle: 'titles.form_steps.overview',
    },
    {
        name: 'Review Step',
        path: `${pages.form_steps.review}`,
        component: ReviewStepContainer,
        exact: true,
        roles: [USER_ROLE_SUPERVISOR, USER_ROLE_MANAGER, USER_ROLE_MECHANIC, USER_ROLE_MECHANIC_ADVANCED],
        headerTitle: 'pages.form_steps.review.title',
        pageTitle: 'titles.form_steps.review',
    },
]

export const settingsRoutes = [
    {
        name: 'Settings',
        path: `${pages.settings}`,
        component: SettingsContainer,
        exact: true,
        roles: [USER_ROLE_SUPERVISOR, USER_ROLE_MANAGER, USER_ROLE_MECHANIC, USER_ROLE_MECHANIC_ADVANCED],
        headerTitle: 'pages.settings.title',
        pageTitle: 'titles.settings',
    },
    {
        name: 'User Profile',
        path: `${pages.user_profile}`,
        component: UserProfileContainer,
        exact: true,
        roles: [USER_ROLE_SUPERVISOR, USER_ROLE_MANAGER, USER_ROLE_MECHANIC, USER_ROLE_MECHANIC_ADVANCED],
        headerTitle: 'pages.user_profile.title',
        pageTitle: 'titles.user_profile',
    },
]

export const requestsRoutes = [
    {
        name: 'Requests',
        path: `${pages.requests}`,
        component: RequestsContainer,
        exact: true,
        roles: [USER_ROLE_SUPERVISOR, USER_ROLE_MANAGER, USER_ROLE_MECHANIC, USER_ROLE_MECHANIC_ADVANCED],
        headerTitle: 'pages.requests.title',
        pageTitle: 'titles.requests',
    },
    {
        name: 'Request Details',
        path: `${pages.request_details}:request`,
        component: RequestDetailsContainer,
        exact: true,
        roles: [USER_ROLE_SUPERVISOR, USER_ROLE_MANAGER, USER_ROLE_MECHANIC, USER_ROLE_MECHANIC_ADVANCED],
        headerTitle: 'pages.request_details.title',
        pageTitle: 'titles.request_details',
    },
]

export default [
    ...formStepRoutes,
    ...settingsRoutes,
    ...requestsRoutes,
]
