export const REQUEST_SETTINGS = '[Request Settings]'

export const REQUEST_SETTINGS_SET = `${REQUEST_SETTINGS} SET`

export const setRequestSettings = settings => ({
    type: REQUEST_SETTINGS_SET,
    payload: {
        settings,
    },
})
