import requestSettings from '../models/requestSettings'
import {REQUEST_SETTINGS_SET} from "../actions/requestSettings.actions";

export default function(state = requestSettings, action) {
    switch (action.type) {
        case REQUEST_SETTINGS_SET:
            return {
                ...state, ...action.payload.settings,
            }

        default:
            return state
    }
}
