import React, {Component} from 'react'
import {translate} from 'react-i18next'
import {OFFICIAL_SERVICE_SELECTED} from "store/consts/officialServices.constants";
import Info from "../Info/Info";
import classNames from "classnames";
import InputCheckbox from "../../../../components/shared/InputCheckbox";

class ItemRecommended extends Component {

    state = {
        labourInfo: null
    }

    parts = (service) => {
        if (service.works.find(work => /^-\d+$/.test(work.id))) {
            return service.works.find(work => /^-\d+$/.test(work.id)).parts.filter(part => part.selected);
        }

        return [];
    }

    showInfo = (e, service) => {
        e.stopPropagation();
        e.preventDefault();

        this.setState({labourInfo: service})
    }

    render() {
        const {labourInfo} = this.state;
        const {t, recommended, value, change} = this.props;

        return (
            <>
                <div className={classNames('official-services-single', {
                    'official-services-single-selected': recommended.selected === value,
                })}>
                    <div className="official-services-single__header">
                        <InputCheckbox
                            id={`official-services-single-${OFFICIAL_SERVICE_SELECTED[value]}`}
                            name={'official-services-single'}
                            value={value}
                            onChange={change}
                            checked={recommended.selected === value}
                            label={`${t('pages.form_steps.official_service.service_interval_title')}  ${recommended[OFFICIAL_SERVICE_SELECTED[value] + 'Km']}km`}
                        />
                    </div>

                    {recommended[OFFICIAL_SERVICE_SELECTED[value]].map((service, key) => (
                        <div className="official-services-single__name" key={key}>
                            <div className="official-services-single__name__left">
                                <div className="official-services-single__name__left__name">
                                    {service.name}
                                </div>
                            </div>
                            {this.parts(service).length > 1 &&
                            <div className="official-services-single__name__right">
                                        <span className="link-style" onClick={(e) => this.showInfo(e, service)}>
                                            <i className="icon-info"/>
                                        </span>
                            </div>
                            }
                        </div>
                    ))}
                </div>

                {labourInfo &&
                <Info
                    title={labourInfo.name}
                    visible={!!labourInfo}
                    content={
                        <ul>{this.parts(labourInfo).map(part => <li key={part.id}>{part.name}</li>)}</ul>
                    }
                    onClose={() => this.setState({labourInfo: null})}
                />
                }
            </>
        )
    }
}

export default translate('translations')(ItemRecommended);
