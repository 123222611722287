import React, {Component} from 'react'
import {translate} from 'react-i18next'
import LinkIcon from "../../../../components/shared/LinkIcon/LinkIcon";
import RequestStatus from "../RequestStatus";
import {dateFormat} from "../../../../utils/common";

class RejectedDetail extends Component {

    onArchive = () => {

    }

    getReason = (reason) => {
        return {__html: reason};
    }

    render() {
        const {t, request, onEdit, onArchive} = this.props;

        return (
            <div className="request-detail-box">
                <div className="row request-detail-box__line">
                    <div className="col-lg-3"><span className="label">{t('pages.request_details.request_id')}</span>
                    </div>
                    <div className="col-lg-5">{request.id}</div>
                    <div className="col-lg-4">
                        {!request.is_archived &&
                        <div className="action">
                            <LinkIcon icon={"icon-edit"} left={true} text={t('pages.request_details.edit')}
                                      onClick={onEdit}/>
                        </div>
                        }
                    </div>
                </div>

                <div className="row request-detail-box__line">
                    <div className="col-lg-3"><span className="label">{t('pages.request_details.date')}</span></div>
                    <div className="col-lg-5">{dateFormat(request.date, '/')}</div>
                </div>

                <div className="row request-detail-box__line">
                    <div className="col-lg-3"><span className="label">{t('pages.request_details.status')}</span></div>
                    <div className="col-lg-5">
                        <RequestStatus status={request.transaction.status}/>
                    </div>
                    <div className="col-lg-4">
                        {!request.is_archived ?
                            <div className="action">
                                <LinkIcon icon={"icon-archive"} left={true} text={t('pages.request_details.archive')}
                                          onClick={onArchive}/>
                            </div>
                            :
                            <div className="archived"><i
                                className="icon-archive"/> {t('pages.request_details.archived')}</div>
                        }
                    </div>
                </div>

                <div className="row request-detail-box__line">
                    <div className="col-lg-12"><span
                        className="label">{t('pages.request_details.denial_reason')}:</span></div>
                    <div className="col-lg-12">
                        <div className="reject-message"
                             dangerouslySetInnerHTML={this.getReason(request.transaction.message)}/>
                    </div>
                </div>


            </div>
        )
    }
}

export default translate('translations')(RejectedDetail)
