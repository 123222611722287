import {TIRES_EDIT_PREPARE, tiresUpdate} from "../../actions/tires.actions";
import {
    TIRES_AXEL_NAME, TIRES_AXELS, TIRES_STEP_LIST
} from "../../consts/tires.constants";
import tiresModel from '../../models/tires'
import {cloneDeep} from "lodash";
import {toBool} from "../../../utils/common";

export const tiresMiddleware = ({dispatch}) => next => {
    return action => {
        next(action)

        switch (action.type) {
            case TIRES_EDIT_PREPARE:

                let tires = cloneDeep(tiresModel);

                TIRES_AXELS.forEach(axle => {
                    if(action.payload.tires[axle]){
                        tires.axel[TIRES_AXEL_NAME[axle]] = {
                            selected: toBool(action.payload.tires[axle]),

                            wide: action.payload.tires[axle].wide,
                            wide_list: [],
                            depth: action.payload.tires[axle].depth,
                            depth_list: [],
                            diameter: action.payload.tires[axle].diameter,
                            diameter_list: [],

                            tire: action.payload.tires[axle],
                        };
                    }
                    else {
                        tires.axel[TIRES_AXEL_NAME[axle]] = {
                            ...tires.axel[TIRES_AXEL_NAME[axle]],
                            selected: false,
                        };
                    }
                })

                tires.step = TIRES_STEP_LIST;
                tires.sameDimensions = true;

                dispatch(tiresUpdate(tires));

                break

            default:
                break
        }
    }
}
