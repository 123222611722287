import React, {Component} from 'react';
import {connect} from 'react-redux';
import {translate} from 'react-i18next';
import Header from "components/layout/Header/Header";
import "./CarStepContainer.scss";
import FormVehicle from "components/forms/FormVehicle/FormVehicle";
import {carStepPop} from "store/actions/carStep.actions";
import history from "routes/history";
import {changeStepData, setStepNext} from "../../../store/actions/step.actions";
import {FORM_STEP_CAR} from "../../../store/consts/step.constants";

class CarStepContainer extends Component {

    componentDidMount() {
        const { dispatch, car } = this.props;

        if(car.token)
            dispatch(changeStepData(FORM_STEP_CAR));
    }

    onBack = () => {
        const { carStep: { prevSteps }, dispatch } = this.props;

        if(prevSteps.length > 0){
            dispatch(carStepPop());
        }
        else{
            history.goBack();
        }
    };

    render() {
        const {t, title, dispatch} = this.props;

        return (
            <React.Fragment>

                <Header title={t(this.props.title)} onBack={this.onBack}/>

                <div className="container">

                    <FormVehicle
                        title={title}
                        vehicleResolve={() => dispatch(setStepNext())}
                    />

                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        car: state.car,
        carStep: state.carStep
    };
};

export default connect(mapStateToProps)(translate('translations')(CarStepContainer));
