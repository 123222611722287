import React, {Component} from 'react';
import {translate} from "react-i18next";
import TiresAxel from "./TiresAxel";
import {connect} from "react-redux";
import './TiresPickContainer.scss';
import {
    tiresAxelDimensionUpdate,
    tiresAxelSameDimensionSet, tiresLabourSelectLabour,
    tiresGet, tiresLoadingUpdate, tiresReset, tiresResetSelectedTires, tiresSelectTire,
    tiresSet, tiresSortUpdate, tiresStepUpdate,
    tiresUpdate
} from "../../store/actions/tires.actions";
import {TIRES_STEP_AXEL, TIRES_STEP_LIST, TIRES_STEPS} from "../../store/consts/tires.constants";
import TiresSelect from "./TiresSelect";
import {get} from 'lodash';
import {selectTires, selectTiresFormatSelected, selectTiresValidateSteps} from "../../store/selectors/tires";
import {compare} from "../../utils/common";
import {Popup} from "../shared/Popup/Popup";
import {
    tireLaboursPredefinedRimSizeUpdate,
    tireLaboursPredefinedSelect
} from "../../store/actions/tireLaboursPredefined.actions";
import {selectTireLaboursIncluded, selectTireLaboursInTireProcess} from "../../store/selectors/tireLaboursPredefined";

class TiresPickContainer extends Component {

    componentWillUnmount() {
        const {dispatch} = this.props;

        dispatch(tiresReset())
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const {tires, tiresValidateSteps} = this.props;

        let toCompareFront = ['axel.front.wide', 'axel.front.depth', 'axel.front.diameter'];
        let toCompareRear = ['axel.rear.wide', 'axel.rear.depth', 'axel.rear.diameter'];

        if(compare(nextProps.tires.axel, this.props.tires.axel)){
            for(let path of toCompareFront) {
                if(get(tires, path) !== get(nextProps.tires, path)) {
                    this.getTires()
                    break;
                }
            }
        }

        if (!nextProps.tires.sameDimensions) {
            for(let path of toCompareRear) {
                if(get(tires, path) !== get(nextProps.tires, path)) {
                    this.getTires()
                    break;
                }
            }
        }

        if(tires.step > parseInt(Object.keys(tiresValidateSteps).find(step => tiresValidateSteps[step] === false))) {
            this.goToStep(parseInt(Object.keys(tiresValidateSteps).find(step => tiresValidateSteps[step] === false)))
        }
    }

    getTires = () => {
        const {dispatch} = this.props;

        dispatch(tiresGet())
            .then((response) => {

                dispatch(tiresUpdate(response.data))

            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => dispatch(tiresLoadingUpdate(false)))
    }

    updateTires = (path, value) => this.props.dispatch(tiresSet(path, value))

    setSameDimensions = () => this.props.dispatch(tiresAxelSameDimensionSet())

    resetSelectedTires = () => this.props.dispatch(tiresResetSelectedTires())

    goToStep = (step) => {
        const {onCreate, dispatch, tiresFormatSelected} = this.props;

        if (TIRES_STEPS.indexOf(step) !== -1) {
            dispatch(tiresStepUpdate(step, true))
        } else if (step > TIRES_STEPS[TIRES_STEPS.length - 1]) {
            onCreate(tiresFormatSelected)
        }
    }

    axelDimensionUpdate = (axel, name, value) => this.props.dispatch(tiresAxelDimensionUpdate(axel, name, value))

    selectTire = (tire, axels) => this.props.dispatch(tiresSelectTire(tire, axels))

    tireLaboursPredefinedSelect = (labourGroupId, checked) => {
        const {dispatch} = this.props;

        dispatch(tireLaboursPredefinedSelect(labourGroupId, checked));
    }

    tireLaboursPredefinedSelectLabour = (labourGroupId, labourId) => this.props.dispatch(tiresLabourSelectLabour(labourGroupId, labourId))

    tireLaboursPredefinedAxelUpdate = (labourGroupId, labourId, axels) => {
        const {dispatch} = this.props;

        dispatch(tireLaboursPredefinedRimSizeUpdate(labourGroupId, labourId, axels));
    }

    sortUpdate = (column) => this.props.dispatch(tiresSortUpdate(column));

    render() {
        const {t, tires, tiresValidateSteps, onClose, tireLaboursInTireProcess, tireLaboursIncluded} = this.props;

        return (
            <Popup
                title={t('pages.form_steps.tires.process.title')}
                visible={true}
                onClose={onClose}
                responsive={true}
            >
                <div className="tires-pick-container">
                    {tires.step === TIRES_STEP_AXEL &&
                    <TiresAxel tires={tires} goToStep={this.goToStep} onGet={this.getTires}
                               labours={tireLaboursInTireProcess}
                               onUpdate={this.updateTires}
                               axelDimensionUpdate={this.axelDimensionUpdate}
                               setSameDimensions={this.setSameDimensions} validateSteps={tiresValidateSteps}
                               resetSelectedTires={this.resetSelectedTires}
                               tiresLabourSelect={this.tireLaboursPredefinedSelect}
                               tiresLabourSelectLabour={this.tireLaboursPredefinedSelectLabour} />
                    }
                    {tires.step === TIRES_STEP_LIST &&
                    <TiresSelect tires={tires} tireLabours={tireLaboursIncluded} goToStep={this.goToStep} onGet={this.getTires}
                                 onUpdate={this.updateTires}
                                 selectTire={this.selectTire}
                                 validateSteps={tiresValidateSteps}
                                 resetSelectedTires={this.resetSelectedTires}
                                 sortUpdate={this.sortUpdate}
                                 tiresLabourSelect={this.tireLaboursPredefinedSelect}
                                 tireLaboursPredefinedAxelUpdate={this.tireLaboursPredefinedAxelUpdate}/>
                    }
                </div>
            </Popup>
        )
    }
}

const mapStateToProps = state => ({
    tires: selectTires(state),
    tiresValidateSteps: selectTiresValidateSteps(state),
    tiresFormatSelected: selectTiresFormatSelected(state),
    tireLaboursInTireProcess: selectTireLaboursInTireProcess(state),
    tireLaboursIncluded: selectTireLaboursIncluded(state),
});

export default connect(mapStateToProps)(translate('translations')(TiresPickContainer));
