import {createSelector} from 'reselect';
import tiresProcessModel from '../models/tiresProcess';

const selectTireProcess = state => state.tiresProcess;

export const selectTireProcessSelected = createSelector(
    [selectTireProcess],
    (tiresProcess) => {

        if(tiresProcess.process_selected){
            return tiresProcess.tires;
        }

        return tiresProcessModel.tires;
    }
);
