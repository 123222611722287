import tiresNewModel from '../models/tiresNew'
import {
    TIRES_NEW_SET,
    TIRES_NEW_UPDATE,
    TIRES_NEW_RESET,
} from "../actions/tiresNew.actions";
import {cloneDeep, set} from "lodash";

export default function (state = tiresNewModel, action) {
    switch (action.type) {

        case TIRES_NEW_SET:
            return action.payload.tires;

        case TIRES_NEW_UPDATE:
            return set(cloneDeep(state), action.payload.path, action.payload.value);

        case TIRES_NEW_RESET:
            return tiresNewModel;

        default:
            return state
    }
}
