import React, {Component} from 'react';
import strNormalize from "utils/strNormalize";
import classNames from 'classnames';
import './TextHighligher.scss';

class TextHighlighter extends Component {

    constructor(props) {
        super(props);
        this.accents = {};
    }

    handleAccents = (text) => {
        const accents = 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';

        const textHasAccents = [...text.toString()].filter(char => {
            return accents.match(this.escapeRegExp(char));
        });

        textHasAccents.map(accent => {
            const accentIndex = this.props.children.indexOf(accent);
            this.accents[accentIndex] = accent;

            return accent;
        });
    };

    escapeRegExp = (string) => {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    };

    render() {
        this.handleAccents(this.props.children);
        const target = strNormalize(this.props.target.toString().toLowerCase())
        const text = strNormalize(this.props.children.toString().toLowerCase())

        if (!target) {
            return this.props.children;
        }

        const matchedText = text.match(this.escapeRegExp(target))

        if (!matchedText) {
            return this.props.children;
        }

        const start = matchedText.index;
        const end = matchedText.index + (matchedText[0].length - 1);

        return <React.Fragment>
            {[...this.props.children.toString()].map((char, index) => {
                return (<span key={index}
                              className={classNames({
                                  'strong': (index >= start && index <= end)
                              })}>
                            {this.accents[index] ? this.accents[index] : char}
                       </span>)
            })}
        </React.Fragment>
    }
}

export default TextHighlighter;
