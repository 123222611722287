import React from 'react'
import Popup from '../Popup/Popup'
import { translate } from 'react-i18next'
import Button from 'components/shared/Button/Button'

export const Confirm = props => {
    const { title, subtitle, t, visible, cancel, accept, cancelText, acceptText, loading, children} = props

    if (!visible) return null

    return (
        <Popup title={title} subtitle={subtitle} visible={visible} onClose={cancel} hideCancel={true}>

            {children}

            <div className="popup__content__buttons">
                <span className="link-style" onClick={cancel}>{cancelText ? cancelText : t('confirm_popup.cancel')}</span>

                <Button size="sm" type="primary" onClick={accept} loading={loading} disabled={loading}>
                    {acceptText ? acceptText : t('confirm_popup.ok')}
                </Button>
            </div>
        </Popup>
    )
}

export default translate('translations')(Confirm)
