import React, {Component} from 'react'
import { translate } from 'react-i18next'
import LinkIcon from "../../../components/shared/LinkIcon/LinkIcon";
import {CANCEL_STATUS} from "../../../store/consts/status.constants";

class RequestOverview extends Component {

    render() {
        const {t, request, onEdit} = this.props;

        return(
            <div className="request-detail-box labours-list">

                <div className="request-detail-box__header">{t('pages.request_details.request_overview')}</div>

                <div>
                    <ul>
                        {request.labours.map((labour, key) => <li key={key}>{labour.name}</li>)}
                    </ul>
                </div>

                {(!request.is_archived && !request.invoice && request.transaction.status !== CANCEL_STATUS) &&
                    <div className="mt-30">
                        <LinkIcon icon={"icon-add"} left={true} text={t('pages.request_details.add_new_service')} onClick={onEdit}/>
                    </div>
                }
            </div>
        )
    }
}

export default translate('translations')(RequestOverview)
