import carStep from 'store/models/carStep'
import { CAR_STEP_POP, CAR_STEP_SET, CAR_STEP_SET_MESSAGE } from 'store/actions/carStep.actions'
import { ALLOWED_IN_CAR_STEP_POP } from 'app/consts/vehicle'
import { CAR_STEP_REPLACE } from '../actions/carStep.actions'

export default function(state = carStep, action) {
    switch (action.type) {
        case CAR_STEP_SET:
            let prevSteps = state.prevSteps

            if (ALLOWED_IN_CAR_STEP_POP.indexOf(state.step) !== -1) {
                prevSteps.push(state.step)
            }

            return { ...action.payload, prevSteps: prevSteps }

        case CAR_STEP_POP:
            return {
                ...state,
                step: state.prevSteps[state.prevSteps.length - 1],
                prevSteps: state.prevSteps.slice(0, state.prevSteps.length - 1),
                message: '',
            }

        case CAR_STEP_REPLACE:
            return { ...carStep, ...action.payload }

        case CAR_STEP_SET_MESSAGE:
            return { ...state, message: action.payload.message, timestamp: action.payload.timestamp }

        default:
            return state
    }
}
