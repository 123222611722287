import React, {Component} from 'react';
import {connect} from 'react-redux';
import {translate} from 'react-i18next';
import {formGarageDetails} from "app/config/yup";
import {withFormik} from "formik";
import Button from "components/shared/Button/Button";
import Input from "components/shared/Input/Input";
import "./index.scss";
import ZipPicker from "../../../components/shared/ZipPicker/ZipPicker";
import SubmitContainer from "../../../components/shared/SubmitContainer/SubmitContainer";

class GarageDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            changeLogo: false
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.garage.logo !== this.props.garage.logo){
            this.setState({changeLogo: false});
        }
    }

    handleTextChange = (e) => {
        const {handleChange} = this.props;

        if (e.target instanceof HTMLInputElement) {
            handleChange(e);
        }
    };

    handleFieldChange = (name, value) => {
        const {setFieldTouched, setFieldValue} = this.props;

        setFieldTouched(name, true);
        setFieldValue(name, value);
    };

    handleImageCancel = () => {
        const {setFieldTouched, setFieldValue} = this.props;

        setFieldTouched('logo', false);
        setFieldValue('logo', null);
        this.setState({changeLogo: false})
    }

    render() {
        const { t, touched, errors, handleBlur, handleSubmit, formSubmitting, garage } = this.props;

        return (
            <React.Fragment>
                <div className="settings-tab">
                    <div className="settings-tab__header">
                        <div className="settings-tab__header__title">{t('pages.settings.section_tabs.change_details')}</div>
                    </div>
                    <div className="settings-tab__content">

                        <form onSubmit={e => handleSubmit(e)}>

                            <Input
                                type="text"
                                placeholder={t('pages.settings.section_form.placeholder.address')}
                                id="address"
                                name="address"
                                label={t('pages.settings.section_form.label.address')}
                                value={garage.address}
                                onBlur={handleBlur}
                                onChange={this.handleTextChange}
                                error={touched.address && errors.address}
                            />

                            <Input
                                type="text"
                                placeholder={t('pages.settings.section_form.placeholder.web')}
                                id="web"
                                name="web"
                                label={t('pages.settings.section_form.label.web')}
                                value={garage.web}
                                onBlur={handleBlur}
                                onChange={this.handleTextChange}
                                error={touched.web && errors.web}
                            />

                            <ZipPicker
                                id="zip_id"
                                name="zip_id"
                                label={t('pages.settings.section_form.label.zip_id')}
                                value={{label: `${garage.zip.zip} ${garage.zip.name}`, value: garage.zip.id}}
                                onChange={value => this.handleFieldChange('zip_id', value.value)}
                                error={touched.zip_id && errors.zip_id}
                            />

                            <SubmitContainer stat={true}>
                                <div>
                                    <Button id="save_settings_garage_details" size="lg" type="primary" disabled={formSubmitting.settings}>{t('pages.settings.section_form.submit_user_details')}</Button>
                                </div>
                            </SubmitContainer>
                        </form>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const GarageDetailsForm = withFormik(formGarageDetails)(GarageDetails);

const mapStateToProps = (state) => {
    return {
        formSubmitting: state.formSubmitting,
        garage: state.garage
    };
};

export default connect(mapStateToProps)(translate('translations')(GarageDetailsForm));
