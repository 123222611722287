import officialServices from '../models/officialServices'
import {
    OFFICIAL_SERVICES_SET,
    OFFICIAL_SERVICES_RESET,
    OFFICIAL_SERVICES_UPDATE
} from "../actions/officialServices.actions";

export default function(state = officialServices, action) {
    switch (action.type) {
        case OFFICIAL_SERVICES_SET:
            return {
                recommended: action.payload.exist ? action.payload.recommended : officialServices.recommended,
                main: action.payload.main,
                additional: action.payload.additional,
                mileage: action.payload.mileage,
                exist: action.payload.exist,
            }

        case OFFICIAL_SERVICES_UPDATE:
            return {
                ...state,
                [action.payload.type]: state[action.payload.type].map(l => {
                    if(l.itemMpId === action.payload.labour.itemMpId && l.korId === action.payload.labour.korId){
                        return action.payload.labour;
                    }

                    return l;
                })
            }

        case OFFICIAL_SERVICES_RESET:
            return officialServices

        default:
            return state
    }
}
