import store from 'store/store'
import pages from 'app/consts/routes'
import history from 'routes/history'
import React from 'react'

export const LaboursMiddleware = Component => {
    return class extends React.Component {
        constructor(props) {
            super(props)

            const labours = store.getState().labours
            const tireLabours = store.getState().tireLabours
            const tiresNew = store.getState().tiresNew

            this.shouldRender = true

            if (labours.length === 0 && tireLabours.length === 0 && !tiresNew) {
                this.shouldRender = false
                history.replace(pages.form_steps.labours)
            }
        }

        render() {
            if (this.shouldRender) {
                return <Component {...this.props} />
            }

            return <div />
        }
    }
}

export default LaboursMiddleware
