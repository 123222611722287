import React, { Component } from 'react';
import './Phone.scss';
import PhoneInput from "react-phone-input-2";
import de from 'react-phone-input-2/lang/de'
import fr from 'react-phone-input-2/lang/fr'

import {language} from "utils/language";
import {translate} from "react-i18next";
import {connect} from "react-redux";

import {trim} from 'lodash';

const LOCALIZATION = {'en': '', 'de': de, 'fr': fr}

class Phone extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.value || '',
            dialCode: '',
            phoneCodes: props.country.phone_codes.split(','),
            currentPhoneCode: props.country.phone_codes.split(',')[0],
        };

        this.ref = React.createRef();
    }

    componentDidMount() {
        this.ref.numberInputRef.addEventListener('change', this.props.onChangeInput);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.value !== nextProps.value) {
            this.setState({
                value: nextProps.value
            })
        }
    }

    handleChange = (e, callback) => {
        this.setState({ value: e });
        callback(e);
    };

    render() {
        const { value, phoneCodes, currentPhoneCode, dialCode } = this.state;
        const {
            t,
            name,
            onChange,
            onBlur,
            error
        } = this.props;

        return (
          <div className="react-tel-custom">
              <div className="form-group">
                  <label>{t('form.label.phone')}</label>
                  <PhoneInput
                    ref={ref => this.ref = ref}
                    country={currentPhoneCode}
                    localization={LOCALIZATION[language()]}
                    onlyCountries={phoneCodes}
                    inputProps={{
                        name: name,
                        onKeyUp: (e) => {
                            if(!trim(e.target.value, ['+', ' '])){
                                e.target.value = '+' + dialCode;
                            }
                        }
                    }}
                    onChange={(e, data) => {
                        if(data.countryCode !== currentPhoneCode){
                            this.setState({
                                currentPhoneCode: currentPhoneCode
                            })
                        }

                        this.handleChange(e, (value) => {
                            if (onChange) {
                                onChange(trim(value, ['+', ' ']) === data.dialCode || value.length <= 5 ? '' : value);
                            }
                        });
                    }}
                    onFocus={(e, data) => {
                        this.setState({
                            dialCode: data.dialCode,
                        })
                    }}
                    onBlur={onBlur}
                    value={value ? value : ''}
                    enableLongNumbers={true}
                    countryCodeEditable={true}
                    containerClass={"react-tel-input"}
                    inputClass={`react-tel-input-custom ${error ? 'input-error' : ''}`}
                  />

                  {error && (
                      <span className="form-error">
                        <p>{error}</p>
                    </span>
                  )}
              </div>
          </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        country: state.country
    };
};

export default connect(mapStateToProps)(translate('translations')(Phone));

