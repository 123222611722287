import React, {Component} from 'react'
import {translate} from 'react-i18next'
import Confirm from "../../../../components/shared/Confirm/Confirm";
import {toast} from "react-toastify";
import Toast from "../../../../components/shared/Toast/Toast";
import {parseResponse} from "../../../../utils/common";
import {TIRES_AXELS} from "../../../../store/consts/tires.constants";

class TiresDetails extends Component {

    state = {
        removeConfirm: false,
        removing: false,
    }

    confirmRemove = () => {
        const {tires} = this.state;
        const {onRemove} = this.props;

        this.setState({removing: true})

        onRemove(tires)
            .then(() => this.setState({removeConfirm: false}))
            .catch((error) => toast.error(<Toast text={parseResponse(error.response)} type="error"/>))
            .finally(() => this.setState({removing: false}))
    }

    render() {
        const {removeConfirm, removing} = this.state;
        const {t, tiresNew, remove} = this.props;

        return (
            <React.Fragment>

                <div className="labour-detail">
                    <div className="labour-detail-header">
                        <div className="labour-detail-header__title">{t('pages.form_steps.tires.labours.new_tires')}</div>
                        <div className="labour-detail-header__icons">
                            {remove !== false &&
                                <span className="remove" onClick={() => this.setState({removeConfirm: true})}>
                                    <i className="icon-delete"/> {t('global.remove')}
                                </span>
                            }
                        </div>
                    </div>
                    <div className="labour-detail-content">
                        {TIRES_AXELS.map(axel => (
                            <div key={axel}>{tiresNew[axel].full_name} {tiresNew[axel].full_axel}</div>
                        ))}
                    </div>
                </div>

                {removeConfirm &&
                    <Confirm
                        title={t('pages.form_steps.overview.labour_remove_title')}
                        acceptText={t('global.remove')}
                        visible={removeConfirm}
                        accept={this.confirmRemove}
                        loading={removing}
                        cancel={() => this.setState({removeConfirm: false})}
                    >
                        <div className={"mv-30"}>
                            {t('pages.form_steps.tires.labours.new_tires')}
                        </div>
                    </Confirm>
                }

            </React.Fragment>
        )
    }
}

export default translate('translations')(TiresDetails);
