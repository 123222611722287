import {sumPriceTotal} from "./laboursPrice";
import {sumBy} from "lodash";

export const offerPriceTotal = (labours, tireLabours, tires) => {
    let price = 0

    price += sumPriceTotal(labours, false, false);
    price += tires ? tires.price_tires : 0;
    price += tireLabours.length ? sumBy(tireLabours, l => l.price) : 0;

    return price
}

export const offerPriceTotalWithTax = (labours, tireLabours, tires) => {
    let price = 0

    price += sumPriceTotal(labours, false);
    price += tires ? tires.price_tires_with_tax : 0;
    price += tireLabours.length ? sumBy(tireLabours, l => l.price_with_tax) : 0;

    return price
}
