import React, {Component} from 'react';
import {translate} from "react-i18next";
import InputCheckbox from "../shared/InputCheckbox";
import Select from "../shared/Select/Select";
import {
    TIRES_AXEL_FRONT,
    TIRES_AXEL_REAR, TIRES_STEP_AXEL,
    TIRES_STEP_LIST,
} from "../../store/consts/tires.constants";
import Button from "../shared/Button/Button";
import TiresLabour from "./TiresLabour";

class TiresAxel extends Component {

    componentDidMount() {
        const {onGet} = this.props;

        onGet()
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const {setSameDimensions, tires} = this.props;

        if (nextProps.tires.sameDimensions !== tires.sameDimensions && nextProps.tires.sameDimensions) {
            setSameDimensions()
        }
    }

    handleChangeAxel = (e) => {
        const {onUpdate, resetSelectedTires, tires, onGet} = this.props;

        resetSelectedTires();

        if (e.target.value === TIRES_AXEL_FRONT) {
            onUpdate('axel.front.selected', e.target.checked)

            if(e.target.checked === false) {
                onUpdate('sameDimensions', false)
            }
        } else {
            onUpdate('axel.rear.selected', e.target.checked)
        }

        if(e.target.checked === true && (tires.axel.front.wide_list.length === 0 || tires.axel.rear.wide_list.length === 0)) {
            onGet()
        }
    }

    handleChangeSameDimensions = (e) => {
        const {onUpdate} = this.props;

        onUpdate('sameDimensions', e.target.checked)
    }

    dimensionUpdate = (axel, name, option) => {
        const {axelDimensionUpdate, resetSelectedTires} = this.props;

        resetSelectedTires();
        axelDimensionUpdate(axel, name, option.id);
    }

    handleChangeLabourSelect = (labourGroupId, checked) => {
        const {tiresLabourSelect, resetSelectedTires} = this.props;

        resetSelectedTires();
        tiresLabourSelect(labourGroupId, checked)
    }

    handleChangeLabourSelectLabour = (labourGroupId, option) => {
        const {tiresLabourSelectLabour, resetSelectedTires} = this.props;

        resetSelectedTires();
        tiresLabourSelectLabour(labourGroupId, option.id)
    }

    render() {
        const {t, tires, goToStep, validateSteps, labours} = this.props;

        return (
            <React.Fragment>

                <div className="tires-axles">
                    <div className="row tires-axle-item">
                        <div className="col-xs-12 col-sm-3 col-md-2">
                            <InputCheckbox
                                id={`tire-axel-front`}
                                name={`tire-axel-front`}
                                value={TIRES_AXEL_FRONT}
                                checked={tires.axel.front.selected}
                                onChange={this.handleChangeAxel}
                                label={t('pages.form_steps.tires.label.front_axel')}
                            />
                        </div>
                        <div className="col-xs-12 col-sm-8  col-md-7">
                            {tires.axel.front.selected &&
                            <div className="row">
                                <div className="col-xs-4">
                                    <Select
                                        id={"tire-axel-front-dimension-wide"}
                                        list={tires.axel.front.wide_list}
                                        selected={tires.axel.front.wide}
                                        onChange={(id, option) => this.dimensionUpdate('front', 'wide', option)}
                                        size={'sm'}
                                        isLoading={!tires.axel.front.wide_list.length && tires.axel.front.wide === null}
                                        disabled={!tires.axel.front.wide_list.length && tires.axel.front.wide === null}
                                    >
                                        {t('pages.form_steps.tires.label.wide')}
                                    </Select>
                                </div>
                                <div className="col-xs-4">
                                    <Select
                                        id={"tire-axel-front-dimension-depth"}
                                        list={tires.axel.front.depth_list}
                                        selected={tires.axel.front.depth}
                                        onChange={(id, option) => this.dimensionUpdate('front', 'depth', option)}
                                        size={'sm'}
                                        isLoading={!tires.axel.front.depth_list.length && tires.axel.front.wide !== null}
                                        disabled={!tires.axel.front.depth_list.length}
                                    >
                                        {t('pages.form_steps.tires.label.depth')}
                                    </Select>
                                </div>
                                <div className="col-xs-4">
                                    <Select
                                        id={"tire-axel-front-dimension-diameter"}
                                        list={tires.axel.front.diameter_list}
                                        selected={tires.axel.front.diameter}
                                        onChange={(id, option) => this.dimensionUpdate('front', 'diameter', option)}
                                        size={'sm'}
                                        isLoading={!tires.axel.front.diameter_list.length && tires.axel.front.depth !== null}
                                        disabled={!tires.axel.front.diameter_list.length}
                                    >
                                        {t('pages.form_steps.tires.label.diameter')}
                                    </Select>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                    <div className="row tires-axle-item">
                        <div className="col-xs-12 col-sm-3 col-md-2">
                            <InputCheckbox
                                id={`tire-axel-rear`}
                                name={`tire-axel-rear`}
                                value={TIRES_AXEL_REAR}
                                checked={tires.axel.rear.selected}
                                onChange={this.handleChangeAxel}
                                label={t('pages.form_steps.tires.label.rear_axel')}
                            />
                        </div>
                        <div className="col-xs-12 col-sm-8 col-md-7">
                            {tires.axel.rear.selected &&
                            <div className="row">
                                <div className="col-xs-4">
                                    <Select
                                        id={"tire-axel-rear-dimension-wide"}
                                        list={tires.axel.rear.wide_list}
                                        selected={tires.axel.rear.wide}
                                        onChange={(id, option) => this.dimensionUpdate('rear', 'wide', option)}
                                        size={'sm'}
                                        isLoading={!tires.axel.rear.wide_list.length && tires.axel.rear.wide === null}
                                        disabled={(!tires.axel.rear.wide_list.length && tires.axel.rear.wide === null) || tires.sameDimensions}
                                    >
                                        {t('pages.form_steps.tires.label.wide')}
                                    </Select>
                                </div>
                                <div className="col-xs-4">
                                    <Select
                                        id={"tire-axel-rear-dimension-depth"}
                                        list={tires.axel.rear.depth_list}
                                        selected={tires.axel.rear.depth}
                                        onChange={(id, option) => this.dimensionUpdate('rear', 'depth', option)}
                                        size={'sm'}
                                        isLoading={!tires.axel.rear.depth_list.length && tires.axel.rear.wide !== null}
                                        disabled={!tires.axel.rear.depth_list.length || tires.sameDimensions}
                                    >
                                        {t('pages.form_steps.tires.label.depth')}
                                    </Select>
                                </div>
                                <div className="col-xs-4">
                                    <Select
                                        id={"tire-axel-rear-dimension-diameter"}
                                        list={tires.axel.rear.diameter_list}
                                        selected={tires.axel.rear.diameter}
                                        onChange={(id, option) => this.dimensionUpdate('rear', 'diameter', option)}
                                        size={'sm'}
                                        isLoading={!tires.axel.rear.diameter_list.length && tires.axel.rear.depth !== null}
                                        disabled={!tires.axel.rear.diameter_list.length || tires.sameDimensions}
                                    >
                                        {t('pages.form_steps.tires.label.diameter')}
                                    </Select>
                                </div>
                            </div>
                            }
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-3">
                            {(tires.axel.front.selected && tires.axel.rear.selected) &&
                            <InputCheckbox
                                id={`tire-axel-same-dimensions`}
                                name={`tire-axel-same-dimensions`}
                                value={tires.sameDimensions}
                                checked={tires.sameDimensions}
                                onChange={this.handleChangeSameDimensions}
                                label={t('pages.form_steps.tires.label.same_dimension')}
                            />
                            }
                        </div>
                    </div>

                    <TiresLabour
                        labours={labours}
                        handleChangeLabourSelect={this.handleChangeLabourSelect}
                        handleChangeLabourSelectLabour={this.handleChangeLabourSelectLabour}
                    />

                    <div className="tires-step-submit">
                        <div className="row">
                            <div className="col-xs-push-3 col-xs-6 text-center">
                                <Button size="sm" type="primary" onClick={() => goToStep(TIRES_STEP_LIST)}
                                        disabled={!validateSteps[TIRES_STEP_AXEL] || tires.loading} loading={tires.loading}>{t('form.button.next')}</Button>
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    }
}

export default translate('translations')(TiresAxel);
