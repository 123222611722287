import store from 'store/store'
import pages from 'app/consts/routes'
import history from 'routes/history'
import React from 'react'
import { fieldExists } from 'utils/fieldExists'

export const CarStepMiddleware = Component => {
    return class extends React.Component {
        constructor(props) {
            super(props)

            const car = store.getState().car
            this.shouldRender = true

            if (
                !(
                    !!car &&
                    fieldExists(car, 'make_id') &&
                    fieldExists(car, 'range_id') &&
                    fieldExists(car, 'type_id') &&
                    fieldExists(car, 'first_registration')
                )
            ) {
                this.shouldRender = false
                history.replace(pages.form_steps.car)
            }
        }

        render() {
            if (this.shouldRender) {
                return <Component {...this.props} />
            }

            return <div />
        }
    }
}

export default CarStepMiddleware
