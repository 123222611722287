import React, {Component} from 'react'
import {translate} from 'react-i18next'
import LinkIcon from "../../../../components/shared/LinkIcon/LinkIcon";
import RequestStatus from "../RequestStatus";
import {dateFormat, timeFormat} from "../../../../utils/common";
import classNames from 'classnames';

class AcceptedDetail extends Component {

    render() {
        const {t, request, onEdit, onCancel, generatingFile, onGenerateInvoice} = this.props;

        const Invoice = () => {
            return (
                <div className="request-detail-box height-130">
                    <div className="request-detail-box__header">{t('pages.request_details.invoice.title')}</div>

                    <div className="request-detail-box__line">
                        <div className="row">
                            <div className="col-lg-4">
                                <span className="label">{t('pages.request_details.invoice.uploaded')}:</span>
                            </div>
                            <div className="col-lg-6">
                                {dateFormat(request.invoice.created_at, '/')} {' '}
                                {timeFormat(request.invoice.created_at)}
                            </div>
                        </div>
                    </div>
                    <div className="request-detail-box__line">
                        <LinkIcon icon={"icon-download"} loading={generatingFile === 'invoice'}
                                  disabled={generatingFile === 'invoice'} left={true}
                                  text={t('pages.request_details.invoice.download_invoice')}
                                  onClick={onGenerateInvoice}/>

                        <small>{t('pages.request_details.invoice.pending')}</small>
                    </div>
                </div>
            )
        }

        return (
            <div className="row">
                <div className={classNames('col-lg-6', {'col-lg-12': !request.invoice})}>
                    <div className="request-detail-box height-130">

                        <div className="row">

                            <div className={classNames('col-lg-3', {'col-lg-6': request.invoice})}>
                                <div className="request-detail-box__line">
                                    <span className="label">{t('pages.request_details.request_id')}</span>
                                </div>
                                <div className="request-detail-box__line">
                                    <span className="label">{t('pages.request_details.date')}</span>
                                </div>
                                <div className="request-detail-box__line">
                                    <span className="label">{t('pages.request_details.status')}</span>
                                </div>
                            </div>

                            <div className={classNames('col-lg-5', {'col-lg-6': request.invoice})}>
                                <div className="request-detail-box__line">
                                    {request.transaction.auth_number}
                                </div>
                                <div className="request-detail-box__line">
                                    {dateFormat(request.date, '/')} {' '}
                                    {timeFormat(request.date)}
                                </div>
                                <div className="request-detail-box__line">
                                    <RequestStatus status={request.transaction.status}/>
                                </div>
                            </div>

                            {!request.invoice &&
                            <div className="col-lg-4">
                                <div className="request-detail-box__line">
                                    <div className="action">
                                        <LinkIcon icon={"icon-edit"} left={true} text={t('pages.request_details.edit')}
                                                  onClick={onEdit}/>
                                    </div>
                                </div>
                                <div className="request-detail-box__line">{'\u00A0'}</div>
                                <div className="request-detail-box__line">
                                    <div className="action">
                                        <LinkIcon icon={"icon-close"} left={true}
                                                  text={t('pages.request_details.cancel')}
                                                  type="danger" onClick={onCancel}/>
                                    </div>
                                </div>
                            </div>
                            }

                        </div>
                    </div>
                </div>
                {request.invoice && <div className="col-lg-6">
                    <Invoice/>
                </div>}
            </div>
        )
    }

}

export default translate('translations')(AcceptedDetail)
