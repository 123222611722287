export default {
    logged: false,
    id: null,
    name: null,
    logo: null,
    address: null,
    web: null,
    zip: null,
    emails: [],
    phones: [],
    allow_official_service: false,
    terms_and_conditions: {
        url: [],
    },
    privacy_policy: [],
    concept: null,
    leasing: [],
}
