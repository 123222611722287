import React, {Component} from 'react'
import {translate} from 'react-i18next'
import Popup from "components/shared/Popup/Popup";
import Button from "components/shared/Button/Button";

class Info extends Component {
    render() {
        const {t, title, visible, onClose, content} = this.props;

        return (
            <React.Fragment>
                <Popup
                    title={title}
                    visible={visible}
                    onClose={onClose}
                >
                    <div className="text-gray-dark mb-10">
                        {content}
                    </div>

                    <div className="text-center">
                        <Button size="sm" type="primary" onClick={onClose}>{t('pages.form_steps.official_service.info.ok')}</Button>
                    </div>
                </Popup>
            </React.Fragment>
        )
    }
}

export default translate('translations')(Info);
