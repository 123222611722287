import React, { Component } from 'react';
import './index.scss';

class InputRadio extends Component {
    constructor(props) {
        super(props);

        this.state = {
            checked: props.checked || false
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            checked: nextProps.checked
        });
    }

    handleChange = (e) => {
        this.setState({ checked: e.target.checked });
    };

    render() {
        const { checked } = this.state;
        const {
            name,
            id,
            label,
            onChange,
            value
        } = this.props;

        return (
            <div className="custom-checkbox">
                <div className="custom-checkbox-label">
                    <input type="radio" id={id}
                           onChange={e => {
                               this.handleChange(e);
                               if (onChange) {
                                   onChange(e);
                               }
                           }}
                           className="default-language-change"
                           name={name}
                           checked={checked}
                           value={value}/>
                    <div className="custom-checkbox--radio">
                        <span/>
                    </div>
                </div>
                <label className="custom-control-label" htmlFor={id}>{label}</label>
            </div>
        );
    }
}

export default InputRadio;
