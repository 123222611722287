import React, {Component} from 'react'
import {translate} from 'react-i18next'
import Confirm from "../../../../components/shared/Confirm/Confirm";
import {toast} from "react-toastify";
import Toast from "../../../../components/shared/Toast/Toast";
import {parseResponse} from "../../../../utils/common";
import LabourDetails from "./LabourDetails";

class OfficialServiceDetails extends Component {

    state = {
        removeConfirm: false,
        removing: false,
    }

    confirmRemove = () => {
        const {onRemove, labours} = this.props;

        this.setState({removing: true})

        onRemove(labours)
            .then(() => this.setState({removeConfirm: true}))
            .catch((error) => toast.error(<Toast text={parseResponse(error.response)} type="error"/>))
            .finally(() => this.setState({removing: false}))
    }

    render() {
        const {removeConfirm, removing} = this.state;
        const {t, labours, title, remove, edit, onUpdate} = this.props;

        const Remove = () => (
            <span className="remove" onClick={() => this.setState({removeConfirm: true})}><i className="icon-delete"/> {t('global.remove')}</span>
        )

        const Edit = () => (
            <span className="edit" onClick={() => {}}><i className="icon-edit"/> {t('global.edit')}</span>
        )

        return (
            <React.Fragment>

                <div className="official-services">
                    <div className="official-services-header">
                        <div className="official-services-header__title">{title}</div>
                        <div className="official-services-header__icons">
                            {remove !== false && <Remove/>}
                        </div>
                    </div>

                    {labours.map((labour) =>
                        <LabourDetails
                            key={labour.itemMpId}
                            labour={labour}
                            remove={false}
                            onUpdate={onUpdate}
                            onRemove={this.onLabourRemove}/>
                    )}
                </div>

                {removeConfirm &&
                    <Confirm
                        title={t('pages.form_steps.overview.labour_remove_title')}
                        acceptText={t('global.remove')}
                        visible={!!removeConfirm}
                        accept={this.confirmRemove}
                        loading={removing}
                        cancel={() => this.setState({removeConfirm: false})}
                    >
                        <div className={"mv-30"}>{title}</div>
                    </Confirm>
                }

            </React.Fragment>
        )
    }
}

export default translate('translations')(OfficialServiceDetails);
