import axios from "../../app/config/axios";
import {carUpdateObject} from "./car.actions";
import {setSessionStorageItem} from "../../utils/storage";
import {STORAGE_OFFER_TOKEN} from "../../app/consts/storage.consts";
import {setRequestSettings} from "./requestSettings.actions";
import {toBool} from "../../utils/common";
import {LABOUR_TYPE_OFFICIAL, LABOUR_TYPE_REGULAR} from "../consts/labours/labours.constants";
import {laboursPredefinedSet} from "./laboursPredefined.actions";
import {laboursSet} from "./labours.actions";
import {setRecommendedOil} from "./recommendedOil.actions";
import {tiresNewSet} from "./tiresNew.actions";
import {tireLaboursSet} from "./tireLabours.actions";
import {tireLaboursPredefinedSet} from "./tireLaboursPredefined.actions";
import {tiresProcessSet} from "./tiresProcess.actions";
import {TIRES_AXEL_FRONT, TIRES_AXEL_REAR} from "../consts/tires.constants";

const EDIT_REQUEST_URL = 'garage/requests/edit-request'

export const fetchEditRequest = offerToken => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {

            axios.post(EDIT_REQUEST_URL, {offer_token: offerToken})
                .then(response => {

                    dispatch(carUpdateObject(response.data.car))
                    setSessionStorageItem(STORAGE_OFFER_TOKEN, response.data.car.token)
                    dispatch(setRequestSettings(response.data.settings))

                    let laboursPredefined = response.data.laboursPredefined;

                    response.data.labours.filter((l) => l.type === LABOUR_TYPE_REGULAR).filter(l => l.parentId === null).forEach((labour) => {
                        if(!laboursPredefined.find(l => (l.itemMpId === labour.itemMpId && l.korId === labour.korId) || (l.alternative && (labour.itemMpId === l.alternative.itemMpId && labour.korId === l.alternative.korId)))){
                            laboursPredefined.push({...labour, group: '', subGroup: '', alternative: null, siblings: []})
                        }
                    })

                    dispatch(laboursPredefinedSet(laboursPredefined.map(labour => {
                        return {
                            ...labour,
                            prechecked: toBool(response.data.labours.find(l => l.itemMpId === labour.itemMpId && l.korId === labour.korId && l.type === LABOUR_TYPE_OFFICIAL)),
                            checked: toBool(response.data.labours.find(l => l.itemMpId === labour.itemMpId && l.korId === labour.korId)),
                            type: LABOUR_TYPE_REGULAR,
                            loading: false,
                            exist: true,
                        }
                    })))

                    dispatch(laboursSet(response.data.labours))
                    dispatch(setRecommendedOil(response.data.recommended_oil.oil, response.data.recommended_oil.viscosity))

                    dispatch(tireLaboursSet(response.data.tireLabours))
                    dispatch(tireLaboursPredefinedSet(response.data.tireLaboursPredefined))

                    if(response.data.tires){
                        dispatch(tiresNewSet(response.data.tires))
                        dispatch(tiresProcessSet({
                            [TIRES_AXEL_FRONT]: response.data.tires[TIRES_AXEL_FRONT],
                            [TIRES_AXEL_REAR]: response.data.tires[TIRES_AXEL_REAR],
                        }, true))
                    }

                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}
