export const REQUESTS_LIST = '[Requests List]';

export const FETCH_REQUESTS_BEGIN   = `${REQUESTS_LIST} FETCH_REQUESTS_BEGIN`;
export const FETCH_REQUESTS_SUCCESS = `${REQUESTS_LIST} FETCH_REQUESTS_SUCCESS`;
export const FETCH_REQUESTS_FAILURE = `${REQUESTS_LIST} FETCH_REQUESTS_FAILURE`;
export const FETCH_REQUESTS         = `${REQUESTS_LIST} FETCH_REQUESTS`;
export const SET_REQUESTS           = `${REQUESTS_LIST} SET_REQUESTS`;
export const SET_REQUESTS_LOADING_STATUS   = `${REQUESTS_LIST} SET_REQUESTS_LOADING_STATUS`;

export const fetchRequestsBegin = () => ({
    type: FETCH_REQUESTS_BEGIN
});

export const fetchRequestsSuccess = requestsList => ({
    type: FETCH_REQUESTS_SUCCESS,
    payload: { requestsList }
});

export const fetchRequestsFailure = error => ({
    type: FETCH_REQUESTS_FAILURE,
    payload: { error }
});

export const fetchRequests = (page = 1, status = 5) => ({
    type: FETCH_REQUESTS,
    page: page,
    status: status
});

export const setRequests = data => ({
    type: SET_REQUESTS,
    payload: { data }
});

export const setRequestsLoadingStatus = status => ({
    type: SET_REQUESTS_LOADING_STATUS,
    payload: { status }
})
