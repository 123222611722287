export default {
    licence_plate: null,
    car_number: null,
    car_number_type: null,
    make_id: null,
    range_id: null,
    type_id: null,
    first_registration: null,
    first_registration_list: [],
    gear_id: null,
    gear_variant: null,
    qual_md: null,
    qual_lt: null,
    makes: [],
    ranges: [],
    types: [],
    gears: [],
    years: [],
    qual_md_list: [],
    qual_lt_list: [],
    color_list: [],
    color: null,
    color_make_id: null,
    mileage: null,
    token: null,
    leasing_id: null,
    contract_number: null,
}
