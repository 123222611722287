import {setStepDataActiveOne, STEP, STEP_CHANGE, STEP_NEXT, STEP_PREV} from 'store/actions/step.actions';
import {apiRequest} from 'store/actions/api.actions';
import store from 'store/store'
import {STEP_ROUTES} from "../../consts/step.constants";
import history from "routes/history";

export const STEP_URL = 'garage/form/steps';

export const stepMiddleware = ({state, dispatch}) => next => {
    return action => {
        next(action);

        switch (action.type) {
            case STEP_CHANGE:
                dispatch(setStepDataActiveOne(action.payload));
                dispatch(
                    apiRequest({
                        body: action.payload,
                        method: 'POST',
                        url: STEP_URL,
                        signature: STEP
                    })
                );
                break;

            case STEP_NEXT:
                if(STEP_ROUTES.hasOwnProperty(store.getState().step.activeStep + 1)){
                    history.push(STEP_ROUTES[store.getState().step.activeStep + 1])
                }
                break;

            case STEP_PREV:
                if(STEP_ROUTES.hasOwnProperty(store.getState().step.activeStep - 1)){
                    history.push(STEP_ROUTES[store.getState().step.activeStep - 1])
                }
                else {
                    history.goBack();
                }
                break;

            default:
                break;
        }
    };
};
