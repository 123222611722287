import axios from '../../app/config/axios'
import {LABOURS_SET, LABOURS_UPDATE} from "../consts/labours/labours.constants";
import {setRecommendedOil} from "./recommendedOil.actions";

const LABOURS_UPDATE_URL = 'garage/form/labours-update'

export const laboursSet = labours => ({
    type: LABOURS_SET,
    payload: {
        labours,
    },
})

export const laboursUpdate = labour => ({
    type: LABOURS_UPDATE,
    payload: {
        labour,
    },
})

export const laboursCalculate = (labours, officialService = null, labourUpdate = true) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {

            axios
                .post(LABOURS_UPDATE_URL, {
                    labours: labours,
                    official_service: officialService,
                    labour_update: labourUpdate,
                })
                .then(response => {
                    dispatch(laboursSet(response.data.labours))
                    dispatch(setRecommendedOil(response.data.recommended_oil.oil, response.data.recommended_oil.viscosity))

                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}
