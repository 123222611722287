import React, {Component} from 'react';
import './Header.scss';
import {translate} from "react-i18next";
import history from "routes/history";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import pages from "app/consts/routes";
import {intersect} from "utils/common";
import {userLogout} from "store/actions/auth.actions";
import logo from "../../../assets/logo-blue.png";
import TermsAndConditions from "../../TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "../../PrivacyPolicy/PrivacyPolicy";

class Header extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user: props.user,
            garage: props.garage,
            showUserDetail: false,
            showTermsAndConditions: false,
            showPrivacyPolicy: false,
            showNoty: false,
            noBorder: false,
            visible: true,
        };

        this.navigationFloat = React.createRef();
    }

    componentDidMount() {
        window.addEventListener('scroll', (e) => {
            const window = e.currentTarget;

            if (window.scrollY < 50) {
                this.setState({visible: true})
                return;
            }

            this.setState({visible: this.prev > window.scrollY})
            this.prev = window.scrollY;
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        intersect(nextProps, this.state).forEach(prop => {
            if (JSON.stringify(nextProps[prop]) !== JSON.stringify(this.state[prop])) {
                this.setState({
                    [prop]: nextProps[prop]
                })
            }
        });
    }

    imagePath = img => {
        return '/static/images/' + img
    }

    renderLogo = () => {
        return (
            <div className="col-xs-9 pl-15 navigation-header__title">
                <div className="logo">
                    <Link to={pages.form_steps.car}><img src={logo} alt="Logo"/></Link>
                    {/*<img src={this.imagePath('amag-logo.png')} alt="Amag leasing logo" className="amag-logo"/>*/}
                </div>
            </div>
        );
    };

    toggleNoty = () => {
        this.setState({
            showNoty: !this.state.showNoty
        });
    };

    closeNoty = (e) => {
        this.setState({
            showNoty: false
        });
    };

    toggleUserDetails = () => {
        this.setState({
            showUserDetail: !this.state.showUserDetail
        });
    };

    closeUserDetails = (e) => {
        if (e.target === this.navigationFloat) {
            this.setState({
                showUserDetail: false
            });
        }
    };

    toggleTermsAndConditions = () => {
        const {showTermsAndConditions} = this.state;

        this.setState({
            showTermsAndConditions: !showTermsAndConditions
        });
    };

    togglePrivacyPolicy = () => {
        const {showPrivacyPolicy} = this.state;

        this.setState({
            showPrivacyPolicy: !showPrivacyPolicy
        });
    };

    onBack = () => {
        const {onBack} = this.props;

        if (onBack) {
            onBack();
        } else {
            if (!history.location.state) {
                history.push(pages.home)
            } else {
                history.goBack();
            }
        }
    };

    noBorderRender = () => {
        return (
            <React.Fragment>
                <header>
                    <div className="navigation navigation-login">
                        <div className="container">
                            <div className="navigation-header row">
                                <div className="col-xs-9 pl-15 navigation-header__title">
                                    <div className="logo"><img src={logo} alt="Logo"/></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </React.Fragment>
        )
    }

    navigationRender = () => {
        const {user, garage} = this.state;
        const {t, dispatch} = this.props;

        return (
            <React.Fragment>
                <div className="navigation-container" onClick={this.closeUserDetails}
                     ref={ref => this.navigationFloat = ref}>
                    <div className="navigation-container__content">
                        <div className="row">
                            <div className="col-xs-12">
                                <div className="navigation-container__content__item pick-garage-name">
                                    {garage.name}
                                </div>

                                {user.garages.length > 1 &&
                                <Link to={pages.pickGarage}
                                      className="navigation-container__content__item pick-garage-link">{t('header.switch_garage')}</Link>
                                }

                                <Link id="requests-navigation" className="navigation-container__content__item"
                                      to={pages.requests}>
                                    <i className="icon-list"/>{t('header.requests')}
                                </Link>

                                <Link id="profile-navigation" className="navigation-container__content__item"
                                      to={pages.user_profile}><i
                                    className="icon-user"/>{t('header.profile_settings')}</Link>

                                {garage.terms_and_conditions.accepted === true &&
                                <span id="terms_and_conditions"
                                      className="link-style navigation-container__content__item"
                                      onClick={this.toggleTermsAndConditions}>
                                        <i className="icon-eye"/>
                                    {t('header.terms_and_conditions')}
                                </span>
                                }

                                <span id="terms_and_conditions"
                                      className="link-style navigation-container__content__item"
                                      onClick={this.togglePrivacyPolicy}>
                                         <i className="icon-eye"/>
                                    {t('header.privacy_policy')}</span>

                                <div className="navigation-container__content__item link-style">
                                    <i className="icon-logout"/>
                                    <span id="logout-navigation"
                                          onClick={() => dispatch(userLogout())}>{t('header.logout')}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    render() {
        const {garage, showUserDetail, showTermsAndConditions, showPrivacyPolicy, visible} = this.state;
        const {showRightControls, noBorder} = this.props;

        if (noBorder) return this.noBorderRender()

        return (
            <React.Fragment>
                <header>
                    <div className={`navigation ${!visible ? 'invisible' : ''}`}>
                        <div className="container">

                            <div className="navigation-header row">
                                {showUserDetail &&
                                <div className="navigation-overlay" onClick={this.toggleUserDetails}/>}

                                {this.renderLogo()}

                                {(garage.logged === true && showRightControls !== false) &&
                                <div
                                    className="col-xs-3 text-right navigation-header__icon navigation-header__icon--settings">
                                        <span id="settings-navigation"
                                              className={`${showUserDetail ? 'icon-close' : 'icon-menu'}`}
                                              onClick={this.toggleUserDetails}/>
                                    {/*<span id="settings-navigation"*/}
                                    {/*      className={`icon-notification ${showNoty ? 'active' : ''}`}*/}
                                    {/*      onClick={this.toggleNoty}/>*/}

                                    {showUserDetail && this.navigationRender()}

                                </div>
                                }


                            </div>
                        </div>
                    </div>
                </header>


                {showTermsAndConditions &&
                <TermsAndConditions
                    onClose={this.toggleTermsAndConditions}
                />
                }

                {showPrivacyPolicy &&
                <PrivacyPolicy
                    onClose={this.togglePrivacyPolicy}
                />
                }

            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        garage: state.garage,
    };
};

export default connect(mapStateToProps)(translate('translations')(Header));
