export const TIRES_NEW = '[Tires New]'

export const TIRES_NEW_SET = `${TIRES_NEW} SET`;
export const TIRES_NEW_UPDATE = `${TIRES_NEW} UPDATE`;
export const TIRES_NEW_RESET = `${TIRES_NEW} RESET`;

export const tiresNewUpdate = (path, value) => ({
    type: TIRES_NEW_UPDATE,
    payload: {
        path, value
    }
});

export const tiresNewSet = (tires) => ({
    type: TIRES_NEW_SET,
    payload: {
        tires
    }
});

export const tiresNewReset = () => ({
    type: TIRES_NEW_RESET,
});
