import {createSelector} from 'reselect';

export const selectTireLaboursPredefined = state => state.tireLaboursPredefined

export const selectTireLaboursIncluded = createSelector(
    [selectTireLaboursPredefined],
    (tireLaboursPredefined) => {
        return tireLaboursPredefined.filter(l => l.in_new_tires || l.in_tire_process).filter(l => !(l.in_new_tires && l.in_tire_process && !l.checked))
    }
);

export const selectTireLaboursInTireProcess = createSelector(
    [selectTireLaboursPredefined],
    (tireLaboursPredefined) => {
        return tireLaboursPredefined.filter(l => l.in_tire_process === true)
    }
);

export const selectTireLaboursList = createSelector(
    [selectTireLaboursPredefined],
    (tireLaboursPredefined) => {
        return tireLaboursPredefined.filter(l => l.in_tire_process === false)
    }
);

export const selectTireLaboursSelected = createSelector(
    [selectTireLaboursPredefined],
    (tireLaboursPredefined) => {
        return tireLaboursPredefined.filter(l => l.checked).map(l => l.labours.find(labour => labour.id === l.selected));
    }
);
