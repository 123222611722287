import Echo from 'laravel-echo'
import {STORAGE_USER_TOKEN} from '../app/consts/storage.consts'

export const startEcho = (user) => {
    window.io = require('socket.io-client')
    window.Echo = new Echo({
        broadcaster: 'socket.io',
        host: process.env.REACT_APP_LARAVEL_ECHO_URL,
        auth: {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem(STORAGE_USER_TOKEN),
            },
        },
    })
}

export const clearEcho = () => {
    Object.keys(window.Echo.connector.channels).forEach(channel => {
        window.Echo.leave(channel)
    })

    window.Echo = null
}

export default {
    startEcho,
    clearEcho,
}
