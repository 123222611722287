import car from 'store/models/car'
import {
    CAR_FETCH_DATA,
    CAR_LICENCE_PLATE_CHANGE_RESET,
    CAR_NUMBER_CHANGE_RESET,
    CAR_RESET_FIELDS_BELLOW,
    CAR_UPDATE_FIELD,
    CAR_UPDATE_OBJECT,
} from 'store/actions/car.actions'
import { getFieldsToReset } from 'utils/car'
import { CAR_FORM_FIELDS } from 'store/consts/car.constants'
import { CAR_RESET } from '../actions/car.actions'

export default function(state = car, action) {
    const stateCopy = { ...state }

    switch (action.type) {
        case CAR_FETCH_DATA:
            return {
                ...state,
            }

        case CAR_RESET_FIELDS_BELLOW:
            if (CAR_FORM_FIELDS[action.payload.name]) {
                getFieldsToReset(CAR_FORM_FIELDS[action.payload.name], car, (item, value) => {
                    stateCopy[item] = value
                })
            }

            return stateCopy

        case CAR_UPDATE_FIELD:
            return {
                ...state,
                [action.payload.name]: action.payload.value,
            }

        case CAR_UPDATE_OBJECT:
            return {
                ...state,
                ...action.payload.car,
            }

        case CAR_NUMBER_CHANGE_RESET:
            return {
                ...car,
                car_number: state.car_number,
                car_number_type: state.car_number_type,
            }

        case CAR_LICENCE_PLATE_CHANGE_RESET:
            return {
                ...car,
                licence_plate: state.licence_plate,
                token: state.token,
                mileage: state.mileage,
                leasing_id: state.leasing_id,
            }

        case CAR_RESET:
            return {
                ...car,
            }

        default:
            return state
    }
}
