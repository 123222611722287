import store from 'store/store'
import {availableParts} from './labours'
import {roundCeil} from '../../utils/common'
import {DISCOUNT_BRAKES_TYPE, DISCOUNT_PARTS_TYPE} from '../consts/discounts.constants'

export const sumPriceTotal = (labours, round = true, includeDiscount = true) => {
    let price = 0

    labours.forEach(labour => {
        price += sumLabourPrice(labour)
    })

    if(includeDiscount){
        price -= partDiscount(labours)
        price -= brakeDiscount(labours)
        price += price * (store.getState().requestSettings.tax / 100)
    }

    if (round) {
        return roundCeil(price)
    }

    return price
}

export const sumPriceTotalVat = (sumPriceTotal, round = true) => {

    let vat = sumPriceTotal * (store.getState().requestSettings.tax / 100)

    if (round) {
        return roundCeil(vat)
    }

    return vat
}

export const reverseVat = (price) => {
    return price - (price / ((store.getState().requestSettings.tax + 100) / 100))
}

export const partDiscount = labours => {
    let discountParts = store.getState().requestSettings.discounts.find(discount => discount.type === DISCOUNT_PARTS_TYPE),
        price = 0

    if (discountParts) {
        Object.values(availableParts(labours)).forEach(part => {
            if (part.selected === true && discountParts.exclude.indexOf(part.id) === -1) {
                price += sumPartPrice(part)
            }
        })

        return price * (discountParts.discount / 100)
    }

    return price
}

export const brakeDiscount = labours => {
    let discountBrakes = store.getState().requestSettings.discounts.find(discount => discount.type === DISCOUNT_BRAKES_TYPE),
        price = 0

    if (discountBrakes) {
        Object.values(availableParts(labours)).forEach(part => {
            if (part.selected === true && discountBrakes.include.indexOf(part.id) !== -1) {
                price += sumPartPrice(part)
            }
        })

        return price * (discountBrakes.discount / 100)
    }

    return price
}

export const sumLabourPrice = labour => {
    let workPrice = 0
    let partPrice = 0
    let priceFixed = labour.priceFixed

    labour.works.forEach(work => {
        if (work.selected === true) {
            workPrice += sumWorkPrice(work)
            partPrice += sumPartsPrice(work.parts)
        }
    })

    return workPrice + partPrice + priceFixed
}

export const sumAvailableWorks = availableWorks => {
    let price = 0
    Object.values(availableWorks).forEach(works => {
        works.forEach(work => (price += sumWorkPrice(work)))
    })

    return price
}

export const sumWorkPrice = work => {
    let additionalPercentOnWorks = store.getState().requestSettings.additional_percent_on_works

    return work.duration * store.getState().requestSettings.hourly_price * ((additionalPercentOnWorks + 100) / 100)
}

export const sumPartsPrice = parts => {
    let price = 0

    Object.values(parts).forEach(part => {
        if (part.selected === true) price += sumPartPrice(part)
    })

    return price
}

export const sumPartPrice = part => {
    let price = 0
    part.versions.forEach(version => {
        if (version.selected) {
            price += sumVersionPrice(version)
        }
    })

    return price
}

export const sumFixedPrice = labours => {
    let price = 0

    Object.values(labours).forEach(labour => {
        price += labour.priceFixed;
    })

    return price
}

const sumVersionPrice = version => {
    let price = 0
    version.set.forEach(set => {
        price += sumSetPrice(set)
    })

    return price
}

export const sumSetPrice = set => {
    return set.price * set.amounts
}
