import i18n from 'i18next'
import language from '../../utils/language'
import pages from '../../app/consts/routes'

export const USER_ROLE_MANAGER = 1
export const USER_ROLE_MECHANIC = 2
export const USER_ROLE_MECHANIC_ADVANCED = 3
export const USER_ROLE_SUPERVISOR = 4

export const USER_ACTIVE = 1
export const USER_INACTIVE = 0

export const USER_COMMUNICATION_NEXMO = 'nexmo_multiple'
export const USER_COMMUNICATION_MAIL = 'mail'
export const USER_COMMUNICATION_PUSH_NOTIFICATION = 'push_notification'

export const USER_ACTIVITY = new Proxy(
    {
        [USER_ACTIVE]: () => i18n.getResource(language(), 'translations', 'user.activity.active'),
        [USER_INACTIVE]: () => i18n.getResource(language(), 'translations', 'user.activity.inactive'),
    },
    {
        get: (obj, name) => {
            return obj[name]()
        },
    },
)

export const USER_ROLES = new Proxy(
    {
        [USER_ROLE_MANAGER]: () => i18n.getResource(language(), 'translations', 'user.roles.manager'),
        [USER_ROLE_SUPERVISOR]: () => i18n.getResource(language(), 'translations', 'user.roles.supervisor'),
        [USER_ROLE_MECHANIC]: () => i18n.getResource(language(), 'translations', 'user.roles.mechanic'),
        [USER_ROLE_MECHANIC_ADVANCED]: () =>
            i18n.getResource(language(), 'translations', 'user.roles.mechanic_advanced'),
    },
    {
        get: (obj, name) => {
            return obj[name]()
        },
    },
)

export const AVATAR = {
    FILE_SIZE: 36700160,
    SUPPORTED_FORMATS: ['image/jpg', 'image/jpeg', 'image/png'],
    WIDTH: 100,
    HEIGHT: 100,
}

export const FIRST_ON_LOGIN = {
    [USER_ROLE_MANAGER]: pages.requests,
    [USER_ROLE_SUPERVISOR]: pages.requests,
    [USER_ROLE_MECHANIC]: pages.form_steps.car,
    [USER_ROLE_MECHANIC_ADVANCED]: pages.form_steps.car,
}

export const USER_COMMUNICATIONS = [
    USER_COMMUNICATION_NEXMO,
    USER_COMMUNICATION_MAIL,
    USER_COMMUNICATION_PUSH_NOTIFICATION,
]

export const USER_CAN_UPDATE_PRICES = [USER_ROLE_MANAGER, USER_ROLE_SUPERVISOR]
