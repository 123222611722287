import {REQUEST_UPDATE, REQUEST_STATUS_SET} from '../actions/request.actions'
import request from '../models/request'

export default function(state = request, action) {
    switch (action.type) {
        case REQUEST_STATUS_SET:
            return {
                ...state, status: action.payload.status, reject_reason: action.payload.reject_reason, auth_number: action.payload.auth_number
            }

        case REQUEST_UPDATE:
            return {
                ...state, ...action.payload.data,
            }

        default:
            return state
    }
}
